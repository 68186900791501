import React, { useEffect, useState } from "react"
import { getIndustries } from "api/industries"

import Select from "components/forms/Select"

const IndustrySelect = ({ ...props }) => {
  const [industries, setIndustries] = useState([])

  useEffect(() => {
    getIndustries().then(response => {
      setIndustries([...response.data.industries, { id: null, name: "None" }])
    })
  }, [])

  return <Select options={industries} {...props} />
}

export default IndustrySelect
