import Header from "components/Header"

const EmployerRoute = () => {
  return (
    <>
      <Header title="Employer Page Eventually" icon="employers" />
      <div className="employer content"></div>
    </>
  )
}

export default EmployerRoute
