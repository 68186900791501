import { useState } from "react"
import toast from "react-hot-toast"
import { updateEmployerCustomer } from "api/customers"

import Form from "./Form"

const EditEmployerCustomer = ({ customer, handleSuccess, handleClose }) => {
  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    return updateEmployerCustomer(customer.id, data)
      .then(() => {
        toast.success("Update successful")
        handleSuccess(data)
        handleClose()
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  return (
    <>
      <Form onSubmit={onSubmit} customer={customer} errors={errors} />
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default EditEmployerCustomer
