import apiCall from "utils/api"

export const getDataCustomers = params => {
  const options = { params }
  return apiCall("get", `data_customers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getDataCustomer = id => {
  return apiCall("get", `data_customers/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const searchDataCustomers = params => {
  const options = { params }
  return apiCall("get", `data_customers/search`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createDataCustomer = params => {
  const options = { data: params }
  return apiCall("post", `data_customers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateDataCustomer = (id, params) => {
  const options = { data: params }
  return apiCall("patch", `data_customers/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createDataCustomerCompany = (id, params) => {
  const options = { data: params }
  return apiCall(
    "post",
    `data_customers/${id}/data_customer_companies`,
    options
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateDataCustomerCompany = (id, params) => {
  const options = { data: params }
  return apiCall("patch", `data_customer_companies/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getDataCustomerOptions = () => {
  return apiCall("get", `data_customers/form_options`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
