import { useState, useRef, useCallback } from "react"
import { Link } from "react-router-dom"
import { getMetroLocationJobs } from "api/metro_locations/jobs"
import { AgGridDateTime } from "utils/cellRenderers"

import AgGrid from "components/ag_grid/AgGrid"

const LocationJobsRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "jobs.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => <div>{data.id}</div>,
    },
    {
      field: "jobs.title",
      headerName: "Title",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return <Link to={`/jobs/${data.id}`}>{data.title}</Link>
      },
      width: 350,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
        )
      },
      width: 120,
    },
    {
      field: "jobs.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 150,
    },
  ])

  const getterFunction = useCallback(
    params => getMetroLocationJobs(id, params),
    [id]
  )

  return (
    <>
      <div className="tab-header">
        <h3>Active Jobs</h3>
      </div>
      <AgGrid
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={getterFunction}
        sizeToFit={true}
      />
    </>
  )
}

export default LocationJobsRoute
