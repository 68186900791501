import { useEffect, useState } from "react"
import { getRatingOptions } from "api/ratings"

import Select from "components/forms/Select"

const EnumSelect = ({ ratingId, field, ...props }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    getRatingOptions(ratingId, field).then(response => {
      // response is enum key/value pairs; map to options with id/name
      // don't need the key, just the value
      const options = Object.entries(response.data).map(([_, v]) => {
        return { id: v, name: v }
      })
      setOptions(options)
    })
  }, [ratingId, field])

  return <Select options={options} {...props} />
}

export default EnumSelect
