import React from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"

import Sidebar from "components/Sidebar"
import "./Protected.scss"

function ProtectedRoute() {
  const { authenticated } = useSelector(state => state.auth)

  const location = useLocation()

  return authenticated ? (
    <div className="app">
      <Sidebar />
      <main>
        <Outlet />
      </main>
    </div>
  ) : (
    <Navigate
      to="/sign_in"
      state={location.pathname + location.hash + location.search}
    />
  ) // saves the current pathname and search
}

export default ProtectedRoute
