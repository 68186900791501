import apiCall from "utils/api"

export const getEmployers = params => {
  const options = { params }
  return apiCall("get", `employers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createEmployer = params => {
  const options = { data: { employer: params } }
  return apiCall("post", `employers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateEmployer = (id, params) => {
  const options = { data: { employer: params } }
  return apiCall("patch", `employers/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteEmployer = id => {
  return apiCall("delete", `employers/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const grantUserAccess = id => {
  return apiCall("post", `employers/${id}/grant_user_access`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
