import Icon from "components/Icon"
import "./Flags.scss"

const Flags = ({ flags }) => (
  <>
    {flags.length > 0 && (
      <section className="flags">
        <div className="section-header">
          <label>
            <Icon name="flag" /> Flag(s)
          </label>
          <div className="text-end">
            {flags.map(rf => (
              <span className="badge bg-danger" key={rf.name}>
                {rf.name}
              </span>
            ))}
          </div>
        </div>
      </section>
    )}
  </>
)

export default Flags
