import { useState, useRef, useEffect } from "react"
import { getJobApplications } from "api/job_applications"
import { AgGridDateTime, AgGridHyperlink } from "utils/cellRenderers"
import { Link, useParams, useNavigate } from "react-router-dom"
import { setQueryParams, getInitialParams, getQueryString } from "utils/query"

import Header from "components/Header"
import GridUser from "components/users/GridUser"
import Icon from "components/Icon"
import AgGrid from "components/ag_grid/AgGrid"
import SearchInput from "components/forms/SearchInput"
import StatusBadge from "components/job_applications/StatusBadge"
import ApplicationDetail from "components/job_applications/Detail"
import Resume from "components/shared/Resume"

const JobApplicationsRoute = () => {
  const gridRef = useRef()

  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [showDetail, setShowDetail] = useState(false)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const { id } = useParams()
  const navigate = useNavigate()

  const [columnDefs] = useState([
    {
      field: "job_applications.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => <div>{data.id}</div>,
    },
    {
      field: "users.first_name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "jobs.title",
      headerName: "Job",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/jobs/${data.job.id}`}>{data.job.title}</Link>
      ),
      width: 300,
    },
    {
      field: "jobs.status",
      headerName: "Job Status",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.job.status,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
      ),
    },
    {
      field: "job_applications.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
      filterParams: {
        values: [
          "New",
          "Engagement",
          "Declined",
          "Hire unsuccessful",
          "Hire successful",
        ],
      },
    },
    {
      field: "job_applications.stage",
      headerName: "Current Stage",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.stage || String.fromCharCode(8212),
    },
    {
      field: "job_applications.outreached",
      headerName: "Outreached",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) =>
        data.outreached ? (
          <Icon className="text-success" name="outreached" />
        ) : (
          String.fromCharCode(8212)
        ),
    },
    {
      field: "job_applications.influenced_hire",
      headerName: "Influenced Hire",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Yes", "No"],
      },
      hide: true,
      cellRenderer: ({ data }) =>
        data.influenced_hire === null
          ? String.fromCharCode(8212)
          : data.influenced_hire
          ? "Yes"
          : "No",
    },
    {
      field: "users.email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.user?.email,
    },
    {
      field: "users.linkedin_url",
      headerName: "User LinkedIn",
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        const linkedin_url =
          data.user.linkedin_url || data.user?.user_recon.linkedin_url
        return AgGridHyperlink(linkedin_url, true)
      },
      externalURL: true,
    },
    {
      field: "users.resume.url",
      headerName: "Resume",
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) => <Resume resume={data.resume} />,
      externalURL: true,
      cellStyle: { justifyContent: "center" },
      width: 120,
    },
    {
      field: "user_recons.company_name",
      headerName: "Company (Internal)",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) =>
        data.user?.user_recon.company_name || String.fromCharCode(8212),
    },
    {
      field: "user_recons.linkedin_scraping_completed_at",
      headerName: "LinkedIn Scraping Completed",
      filter: "agDateColumnFilter",
      hide: true,
      cellRenderer: ({ data }) =>
        AgGridDateTime(data.user?.user_recon.linkedin_scraping_completed_at),
    },
    {
      field: "job_applications.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "job_applications.updated_at",
      headerName: "Updated",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.updated_at),
    },
  ])

  const handleSearch = q => {
    setSearch(q)
  }

  const hideDetail = () => {
    const search = getQueryString()
    setShowDetail(false)
    navigate(`/job_applications${search}`)
  }

  const onCellClicked = e => {
    const search = getQueryString()
    navigate(`/job_applications/${e.data.id}${search}`)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header
        title="Job Applications"
        icon="application"
        className="has-search"
      >
        <SearchInput
          value={search}
          placeholder="Search job applications..."
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getJobApplications}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          onCellClicked={onCellClicked}
          updatedRecord={updatedRecord}
        />
      </div>
      <ApplicationDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setGridRecord={setUpdatedRecord}
      />
    </>
  )
}

export default JobApplicationsRoute
