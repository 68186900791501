import { useState, useRef, useEffect } from "react"
import { getRatingsValues } from "api/ratings"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AgGridDateTime } from "utils/cellRenderers"
import { formatCurrency } from "utils/format"
import { setQueryParams, getInitialParams } from "utils/query"

import StatusBadge from "components/ratings/StatusBadge"
import GridUser from "components/users/GridUser"
import RatingDetail from "components/ratings/Detail"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"

const RatingValuesRoute = () => {
  const initialParams = getInitialParams()

  const [columnDefs] = useState([
    {
      field: "ratings.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      width: 120,
    },
    {
      field: "users.first_name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
        )
      },
    },
    {
      field: "base_compensation_usd_cents",
      headerName: "Base Compensation (USD)",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => formatCurrency(data.base_compensation_usd),
    },
    {
      field: "base_compensation_cents",
      headerName: "Base Compensation",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        formatCurrency(data.base_compensation, data.base_compensation_currency),
      hide: true,
    },
    {
      field: "total_ote_earnings_cents",
      headerName: "Total OTE Earnings",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        formatCurrency(
          data.total_ote_earnings,
          data.total_ote_earnings_currency
        ),
      hide: true,
    },
    {
      field: "total_ote_earnings_usd_cents",
      headerName: "Total OTE Earnings (USD)",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => formatCurrency(data.total_ote_earnings_usd),
    },
    {
      field: "highest_potential_earnings_cents",
      headerName: "Highest Potential Earnings",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        formatCurrency(
          data.highest_potential_earnings,
          data.highest_potential_earnings_currency
        ),
      hide: true,
    },
    {
      field: "highest_potential_earnings_usd_cents",
      headerName: "Highest Potential Earnings (USD)",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        formatCurrency(data.highest_potential_earnings_usd),
    },
    {
      field: "hitting_quota_percentage",
      headerName: "Percent Hitting Quota",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => (
        <div>{data.hitting_quota_percentage + "%"}</div>
      ),
    },
    {
      field: "average_deal_size_cents",
      headerName: "Average Deal Size",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        formatCurrency(data.average_deal_size, data.average_deal_size_currency),
      hide: true,
    },
    {
      field: "average_deal_size_usd_cents",
      headerName: "Average Deal Size (USD)",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => formatCurrency(data.average_deal_size_usd),
    },
    {
      field: "sales_cycle_length_days",
      headerName: "Sales Cycle Length",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) =>
        data.sales_cycle_length_days || String.fromCharCode(8212),
    },
    {
      field: "metric_ratings.value",
      headerName: "Base Compensation",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Base Compensation"
        )?.value
        return value
      },
    },
    {
      field: "metric_ratings.value",
      headerName: "Incentive Compensation Structure",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Incentive Compensation Structure"
        )?.value
        return value
      },
    },
    {
      field: "metric_ratings.value",
      headerName: "Professional Development and Training",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Professional Development and Training"
        )?.value
        return value
      },
    },
    {
      field: "metric_ratings.value",
      headerName: "Culture and Leadership",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Culture and Leadership"
        )?.value
        return value
      },
    },
    {
      field: "metric_ratings.value",
      headerName: "Product - Market Fit",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Product - Market Fit"
        )?.value
        return value
      },
    },
    {
      field: "metric_ratings.value",
      headerName: "Inbound Lead / Opportunity Flow",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Inbound Lead / Opportunity Flow"
        )?.value
        return value
      },
    },
    {
      field: "metric_ratings.value",
      headerName: "Diversity and Inclusion",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => {
        const value = data.metric_ratings.find(
          mr => mr.name === "Diversity and Inclusion"
        )?.value
        return value || String.fromCharCode(8212)
      },
    },
    {
      field: "office_status",
      headerName: "Office Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Remote", "In Office", "Hybrid / Flex"],
      },
    },
    {
      field: "user_role",
      headerName: "User Role",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "Sell to New Accounts or Prospects",
          "SDR / BDR / LDR or Equivalent Role",
          "Manage Existing Accounts",
          "Sell to New and Manage Existing Accounts",
          "Customer Success",
          "Sales Engineer",
          "Sales Management Role",
          "Channel Sales or Partnerships",
          "Sales Enablement or Support Role",
        ],
      },
    },
    {
      field: "seniority",
      headerName: "Seniority",
      hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "First Line (I manage ICs)",
          "Second Line (I manage managers)",
          "3rd Line / VP or higher",
        ],
      },
      cellRenderer: ({ data }) => data.seniority || String.fromCharCode(8212),
    },
    {
      field: "sales_division",
      headerName: "Sales Division",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "Enterprise",
          "Mid-market",
          "SMB",
          "Not Applicable",
          "Public or SLED",
          "Key Accounts / Majors",
          "Federal",
          "B2C / Consumers",
        ],
      },
    },
    {
      field: "countries.name",
      headerName: "Country",
      filter: "agTextColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => data.country?.name,
    },
    {
      field: "user_status",
      headerName: "User Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "Current Employee",
          "Former Employee, Departed within last 30 days",
          "Former Employee, Departed within last year",
          "Former Employee, Departed between 1 and 2 years ago",
          "Former Employee, Departed more than 2 years ago",
        ],
      },
    },
    {
      field: "ratings.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Not Reviewed", "Unverified", "Verified"],
      },
      cellRenderer: ({ data }) => {
        return <StatusBadge status={data.status} />
      },
    },
    {
      field: "ratings.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },

    {
      field: "ratings.effective_at",
      headerName: "Effective Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.effective_at),
      hide: true,
    },
  ])
  const [showDetail, setShowDetail] = useState(undefined)

  const { id } = useParams()

  const hideDetail = () => {
    setShowDetail(undefined)
    navigate("/ratings/values")
  }

  const navigate = useNavigate()
  const gridRef = useRef()

  const onCellClicked = e => {
    navigate(`/ratings/values/${e.data.id}`)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header title="Ratings Values" icon="ratings" />
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getRatingsValues}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
        />
      </div>
      <RatingDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        refresh={() => setShowDetail(id)}
      />
    </>
  )
}

export default RatingValuesRoute
