import { useState, useRef } from "react"
import { getReferrals, payReferral } from "api/referrals"
import toast from "react-hot-toast"
import { setQueryParams, getInitialParams } from "utils/query"

import { AgGridDateTime } from "utils/cellRenderers"
import Header from "components/Header"
import GridUser from "components/users/GridUser"
import AgGrid from "components/ag_grid/AgGrid"

const ReferralsRoute = ({ id }) => {
  const gridRef = useRef()

  const [updatedRecord, setUpdatedRecord] = useState(null)

  const initialParams = getInitialParams()

  const [columnDefs] = useState([
    {
      field: "referrals.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      width: 120,
    },
    {
      field: "referrals.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "users.first_name", // dictated by API
      headerName: "Referrer",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.referrer} />,
    },
    {
      field: "referees_referrals.first_name", // dictated by API
      headerName: "Referee",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.referee} />,
    },
    {
      field: "verified_at",
      headerName: "Verified",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.verified_at),
    },
    {
      field: "payment_amount_cents",
      headerName: "Amount",
      filter: "agNumberColumnFilter",
      cellClass: "ag-right-aligned-cell",
      cellRenderer: ({ data }) => data.payment_amount,
    },
    {
      field: "payment_requested_at",
      headerName: "Payment Requested",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.payment_requested_at),
    },
    {
      field: "paid_at",
      headerName: "Paid",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.paid_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      cellClass: "ag-center-aligned",
      headerClass: "ag-center-aligned",
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: ({ data }) => (
        <>
          {!data.payment_requested_at || data.paid_at ? (
            String.fromCharCode(8212)
          ) : (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleMarkAsPaid(data.id)}
            >
              Mark as Paid
            </button>
          )}
        </>
      ),
    },
  ])

  const handleMarkAsPaid = id => {
    payReferral(id)
      .then(response => {
        setUpdatedRecord(response.data)
        toast.success("Marked as paid")
      })
      .catch()
  }

  return (
    <>
      <Header title="Referrals" icon="referral" />
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={null}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getReferrals}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          sizeToFit={true}
          updatedRecord={updatedRecord}
        />
      </div>
    </>
  )
}

export default ReferralsRoute
