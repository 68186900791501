import apiCall from "utils/api"

export const getCompanyJobs = (id, params) => {
  const options = { params }
  return apiCall("get", `companies/${id}/jobs`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
