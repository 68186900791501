import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { getCompaniesBasic } from "api/companies"
import { customStyles } from "components/forms/reactSelectStyles"

const CompaniesSelect = ({
  className,
  name,
  label,
  error,
  value,
  wholeCompany,
  merge,
  published,
  ...rest
}) => {
  const [selected, setSelected] = useState({})

  useEffect(() => {
    setSelected(value)
  }, [value])

  const loadOptions = inputValue => {
    const params = { q: inputValue }
    if (published) {
      params.filterModel = {
        "companies.published_at": {
          dateFrom: null,
          dateTo: null,
          filterType: "date",
          type: "dateTrue",
        },
      }
    }
    return getCompaniesBasic(params).then(response => {
      return response.data.rows
    })
  }

  return (
    <>
      <Controller
        name={name}
        {...rest}
        render={({ field: { onChange } }) => {
          return (
            <AsyncSelect
              className={`${className || ""} ${error ? "is-invalid" : ""}`}
              isClearable={true}
              styles={customStyles}
              aria-label={label}
              defaultOptions
              getOptionValue={option => option.id}
              getOptionLabel={option =>
                merge && option.name
                  ? `${option.name} (${option.ratings_count} Ratings)`
                  : merge
                  ? ""
                  : option.name
              }
              loadOptions={loadOptions}
              defaultValue={selected}
              value={selected}
              onChange={value => {
                onChange(wholeCompany ? value : value?.id || null)
                setSelected(value)
              }}
            />
          )
        }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}

export default CompaniesSelect
