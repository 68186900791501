import { useState, useRef, useCallback } from "react"
import { Link } from "react-router-dom"
import { getUserJobApplications } from "api/users/job_applications"
import { AgGridDateTime } from "utils/cellRenderers"

import ApplicationDetail from "components/job_applications/Detail"
import AgGrid from "components/ag_grid/AgGrid"
import Icon from "components/Icon"
import StatusBadge from "components/job_applications/StatusBadge"

const JobApplicationsRoute = ({ id }) => {
  const gridRef = useRef()

  const [showDetail, setShowDetail] = useState(false)

  const [columnDefs] = useState([
    {
      field: "job_applications.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => <div>{data.id}</div>,
    },
    {
      field: "jobs.title",
      headerName: "Title",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return <Link to={`/jobs/${data.job.id}`}>{data.job.title}</Link>
      },
      width: 350,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
        )
      },
      width: 120,
    },
    {
      field: "job_applications.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
      filterParams: {
        values: [
          "New",
          "Engagement",
          "Declined",
          "Hire Successful",
          "Hire Unsuccessful",
        ],
      },
      width: 100,
    },
    {
      field: "job_applications.outreached",
      headerName: "Outreached",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) =>
        data.outreached ? (
          <Icon className="text-success" name="outreached" />
        ) : (
          String.fromCharCode(8212)
        ),
    },
    {
      field: "job_applications.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 150,
    },
  ])

  const getterFunction = useCallback(
    params => getUserJobApplications(id, params),
    [id]
  )

  const onCellClicked = e => {
    setShowDetail(e.data.id)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Job Applications</h3>
      </div>
      <AgGrid
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={getterFunction}
        sizeToFit={true}
        onCellClicked={onCellClicked}
      />
      <ApplicationDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={() => setShowDetail(false)}
      />
    </>
  )
}

export default JobApplicationsRoute
