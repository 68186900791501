import apiCall from "utils/api"

export const getDataUsers = params => {
  const options = { params }
  return apiCall("get", `data_users`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const inviteDataUser = params => {
  const options = { data: { data_user: params } }
  return apiCall("post", `data_users/invite`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateDataUser = (id, params) => {
  const options = { data: { data_user: params } }
  return apiCall("patch", `data_users/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteDataUser = id => {
  return apiCall("delete", `data_users/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
