import { useState, useEffect } from "react"
import { getQuestionApprovals } from "api/approvals"
import { useNavigate, useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { formatDateTime } from "utils/format"

import StatusBadge from "components/reviews/StatusBadge"
import QuestionDetail from "components/questions/Detail"
import Header from "components/Header"

import "./Table.scss"

const QuestionApprovalsRoute = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(true)
  const [showDetail, setShowDetail] = useState(undefined)
  const [approvedQuestion, setApprovedQuestion] = useState(null)

  const hideDetail = () => {
    setShowDetail(undefined)
    navigate("/approvals/questions")
  }

  const handleGridUpdate = data => {
    setApprovedQuestion(data)
    hideDetail()
  }

  useEffect(() => {
    getQuestionApprovals()
      .then(response => {
        setQuestions(response.data.approvables)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [approvedQuestion])

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  if (loading) {
    return <div />
  }

  return (
    <>
      <Header title="My Pending Question Approvals" icon="question" />
      <div className="approvals-table">
        {questions.length > 0 ? (
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {questions.map(review => {
                const { approvable } = review
                const { id, status, created_at } = approvable

                return (
                  <tr key={id}>
                    <td>
                      <Link to={`/approvals/questions/${id}`}>{id}</Link>
                    </td>
                    <td>
                      <StatusBadge status={status} />
                    </td>
                    <td>{formatDateTime(created_at)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <em>You have no pending question approvals.</em>
        )}
      </div>
      <QuestionDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        handleGridUpdate={handleGridUpdate}
      />
    </>
  )
}

export default QuestionApprovalsRoute
