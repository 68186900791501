import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import QuarterSelect from "./QuarterSelect"
import TypeSelect from "./TypeSelect"
import Input from "components/forms/Input"

const FinancialMetricForm = ({ onSubmit, financial_metric, errors }) => {
  const { handleSubmit, register, reset, control } = useForm({
    financial_metric,
  })

  const defaultValues = useMemo(
    () => ({
      year: financial_metric?.year || null,
      fiscal_quarter: financial_metric?.fiscal_quarter || null,
      revenue: financial_metric?.revenue || null,
      yearly_growth: financial_metric?.yearly_growth || null,
      type: financial_metric?.type || null,
    }),
    [financial_metric]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="date" className="form-label">
          Year
        </label>
        <Input
          type="number"
          name="year"
          label="Year"
          error={getErrors("year")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="total" className="form-label">
          Fiscal Quarter
        </label>
        <QuarterSelect
          name="fiscal_quarter"
          control={control}
          className="quarter-select"
          value={defaultValues?.fiscal_quarter}
          error={errors?.fiscal_quarter?.join(", ")}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="total" className="form-label">
          Type
        </label>
        <TypeSelect
          name="type"
          control={control}
          value={defaultValues?.type}
          error={errors?.type?.join(", ")}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="sales" className="form-label">
          Revenue
        </label>
        <div className="input-group">
          <span class="input-group-text">$</span>
          <Input
            type="number"
            name="revenue"
            label="Revenue"
            error={getErrors("revenue")[0]}
            register={register}
          />
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="sales" className="form-label">
          Yearly Growth Percentage
        </label>
        <div className="input-group">
          <Input
            type="number"
            name="yearly_growth"
            label="Yearly Growth Percentage"
            register={register}
            error={getErrors("yearly_growth")[0]}
            step="0.01"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
      <button className="btn btn-primary w-100">Save</button>
    </form>
  )
}

export default FinancialMetricForm
