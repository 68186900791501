import Badge from "react-bootstrap/Badge"

import "./StatusBadge.scss"

function StatusBadge({ status }) {
  return (
    <Badge
      pill={false}
      bg={status ? "success" : "danger"}
      className="job-status-badge"
    >
      {status ? "Active" : "Inactive"}
    </Badge>
  )
}

export default StatusBadge
