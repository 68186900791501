import Icon from "components/Icon"

import "./Header.scss"

const Header = ({ title, icon, children, className }) => (
  <header className={className}>
    <h2>
      <Icon name={icon} />
      {title}
    </h2>
    {children}
  </header>
)

export default Header
