import apiCall from "utils/api"

export const getCompanyTestimonials = id => {
  return apiCall("get", `companies/${id}/testimonials`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createTestimonial = (companyId, params) => {
  const options = { data: { testimonial: params } }
  return apiCall("post", `companies/${companyId}/testimonials`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateTestimonial = (id, params) => {
  const options = { data: { testimonial: params } }
  return apiCall("patch", `testimonials/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteTestimonial = id => {
  return apiCall("delete", `testimonials/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
