import "./TextArea.scss"

const TextArea = ({
  className,
  handleChange,
  name,
  label,
  error,
  maxLength,
  register,
  watch = () => {},
  rows = 4,
  ...rest
}) => {
  const handleKeyPress = e => {
    e.target.style.height = "inherit"
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  const bodyLength = watch(name)?.length || 0

  return (
    <>
      <textarea
        name={name}
        id={name}
        className={`form-control ${className || ""} ${
          error ? "is-invalid" : ""
        }`}
        placeholder={label}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        aria-label={label}
        rows={rows}
        {...register(name)}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
      {maxLength && (
        <span
          className={`character-count ${bodyLength > maxLength + 1 && "error"}`}
        >
          {bodyLength}/{maxLength}
        </span>
      )}
    </>
  )
}

export default TextArea
