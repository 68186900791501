import { useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import Input from "components/forms/Input"

const DataCustomerCompanyForm = ({ onSubmit, company, errors }) => {
  const { handleSubmit, register, reset } = useForm()

  dayjs.extend(utc)

  const defaultValues = useMemo(
    () => ({
      name: company?.company?.name,
      added_at: company?.added_at
        ? dayjs(company?.added_at).utc().format("YYYY-MM-DD")
        : null,
      removed_at: company?.removed_at
        ? dayjs(company?.removed_at).utc().format("YYYY-MM-DD")
        : null,
    }),
    [company]
  )

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="company" className="form-label">
          Company
        </label>
        <input
          type="text"
          name="company.name"
          label="Company"
          value={company?.company?.name}
          className="form-control"
          disabled
        />
      </div>
      <div className="mb-4">
        <label htmlFor="added_at" className="form-label">
          Added At
        </label>
        <Input
          type="date"
          name="added_at"
          error={getErrors("added_at")[0]}
          register={register}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="removed_at" className="form-label">
          Removed At
        </label>
        <Input
          type="date"
          name="removed_at"
          error={getErrors("removed_at")[0]}
          register={register}
        />
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default DataCustomerCompanyForm
