import React from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { customStyles } from "./reactSelectStyles"

const rvSelect = ({
  isMulti,
  options,
  className,
  name,
  label,
  error,
  ...rest
}) => {
  const getValue = value => {
    // value is either an id or an array of ids
    if (Array.isArray(value)) {
      return options.filter(option => value?.includes(option.id)) // multi select
    }
    return options.find(option => option.id === value) // single select
  }

  return (
    <>
      <Controller
        name={name}
        {...rest}
        render={({ field: { value, onChange } }) => {
          return (
            <Select
              styles={customStyles}
              isMulti={isMulti}
              classNamePrefix="select"
              id={name}
              aria-label={label}
              options={options}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              value={getValue(value)}
              defaultValue={getValue(value)}
              onChange={options => {
                if (isMulti) {
                  return onChange(options?.map(option => option.id))
                }
                return onChange(options.id)
              }}
              {...rest}
            />
          )
        }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}

export default rvSelect
