import { useState, useRef, useCallback } from "react"
import { AgGridHyperlink, AgGridDateTime } from "utils/cellRenderers"
import { getJobJobApplications } from "api/jobs/job_applications"
import { setQueryParams, getInitialParams } from "utils/query"

import ApplicationDetail from "components/job_applications/Detail"
import AgGrid from "components/ag_grid/AgGrid"
import Icon from "components/Icon"
import GridUser from "components/users/GridUser"
import StatusBadge from "components/job_applications/StatusBadge"

const JobApplicationsRoute = ({ id }) => {
  const gridRef = useRef()

  const initialParams = getInitialParams()

  const [showDetail, setShowDetail] = useState(false)

  const [columnDefs] = useState([
    {
      field: "job_applications.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => <div>{data.id}</div>,
    },
    {
      field: "users.first_name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "job_applications.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
      filterParams: {
        values: [
          "New",
          "Engagement",
          "Declined",
          "Hire unsuccessful",
          "Hire successful",
        ],
      },
    },
    {
      field: "job_applications.stage",
      headerName: "Current Stage",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.stage || String.fromCharCode(8212),
    },
    {
      field: "users.email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.user?.email,
    },
    {
      field: "users.linkedin_url",
      headerName: "User LinkedIn",
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        const linkedin_url =
          data.user.linkedin_url || data.user?.user_recon.linkedin_url
        return AgGridHyperlink(linkedin_url, true)
      },
    },
    {
      field: "user_recons.company_name",
      headerName: "Company (Internal)",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) =>
        data.user?.user_recon.company_name || String.fromCharCode(8212),
    },
    {
      field: "job_applications.outreached",
      headerName: "Outreached",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      cellRenderer: ({ data }) =>
        data.outreached ? (
          <Icon className="text-success" name="outreached" />
        ) : (
          String.fromCharCode(8212)
        ),
    },
    {
      field: "job_applications.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])

  const getterFunction = useCallback(
    params => getJobJobApplications(id, params),
    [id]
  )

  const onCellClicked = e => {
    setShowDetail(e.data.id)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Applications</h3>
      </div>
      <AgGrid
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={getterFunction}
        initialParams={initialParams}
        setQueryParams={setQueryParams}
        onCellClicked={onCellClicked}
      />
      <ApplicationDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={() => setShowDetail(false)}
      />
    </>
  )
}

export default JobApplicationsRoute
