import { useState, useEffect, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { getTag } from "api/tags"
import { getCompany, updateCompany } from "api/companies"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import Header from "components/Header"
import Button from "react-bootstrap/Button"
import NewCompanyAssignmentForm from "components/tags/NewCompanyAssignment"

import "./Tag.scss"

const TagRoute = () => {
  const { id } = useParams()

  const [tag, setTag] = useState({})

  const { handleSubmit, reset, control } = useForm()

  dayjs.extend(utc)

  const defaultValues = useMemo(
    () => ({
      company_id: null,
    }),
    []
  )

  const fetchAssignment = id => {
    getTag(id)
      .then(response => {
        setTag(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onSubmit = async data => {
    getCompany(data.company_id).then(res => {
      const updatedCompany = { ...res.data }
      updatedCompany.tags.push({ id })
      updateCompany(updatedCompany.id, {
        tag_ids: updatedCompany.tags.map(tag => tag.id),
      }).then(res => {
        const updatedAssignment = { ...tag }
        updatedAssignment.companies.push({
          id: res.data.id,
          name: res.data.name,
        })
        setTag(updatedAssignment)
        toast.success("Company added successfully")
      })
    })
  }

  const removeCompany = companyId => {
    if (window.confirm("Are you sure you want to remove this company?")) {
      getCompany(companyId).then(res => {
        const updatedCompany = { ...res.data }
        const newTags = updatedCompany.tags.filter(tag => tag.id !== id)
        updateCompany.tags = newTags
        updateCompany(updatedCompany.id, {
          tag_ids: updatedCompany.tags.map(tag => tag.id),
        }).then(res => {
          const updatedAssignment = { ...tag }
          const newAssignmentCompanies = updatedAssignment.companies.filter(
            company => company.id !== res.data.id
          )
          updatedAssignment.companies = newAssignmentCompanies
          setTag(updatedAssignment)
          toast.success("Company removed")
        })
      })
    }
  }

  useEffect(() => {
    fetchAssignment(id)
  }, [id])

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <>
      <Header title={tag.name} icon="tags" />
      <div className="content">
        <h4>Companies</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="table assignments-table table-hover">
            <thead>
              <tr className="table-header">
                <th>Name</th>
                <th className="text-end">Ratings</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <NewCompanyAssignmentForm control={control} />
              {tag?.companies?.map(company => {
                const { id, name, ratings_count } = company

                return (
                  <tr key={company.id}>
                    <td className="w-50">
                      <Link to={`/companies/${id}`}>{name}</Link>
                    </td>
                    <td className="text-end w-25">{ratings_count}</td>
                    <td className="assignment-actions">
                      <Button
                        size="sm"
                        className="add"
                        variant="danger"
                        onClick={() => removeCompany(id)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </form>
      </div>
    </>
  )
}

export default TagRoute
