import { useState } from "react"
import { toast } from "react-hot-toast"
import { updateAdmin } from "api/admins"

import AccessSelect from "./AccessSelect"

import "./EditForm.scss"

const EditAdminForm = ({ handleSuccess, admin }) => {
  const [errors, setErrors] = useState(null)
  const [access, setAccess] = useState(admin.access_level)

  const onSubmit = () => {
    return updateAdmin(admin.id, { access_level: access })
      .then(response => {
        toast.success("Access level successfully changed.")
        handleSuccess(response.data)
      })
      .catch(response => {
        setErrors(response?.data?.errors)
      })
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit({ access_level: access })
      }}
    >
      {errors && <div className="error-notification">Something went wrong</div>}
      <label htmlFor="access_level" className="form-label">
        Access Level
      </label>
      <div className="mb-3">
        <AccessSelect defaultValue={admin.access_level} onChange={setAccess} />
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default EditAdminForm
