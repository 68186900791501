import apiCall from "utils/api"

export const getUserJobPreferences = userId => {
  return apiCall("get", `users/${userId}/job_preference`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
