import React, { useState } from "react"
import toast from "react-hot-toast"
import { updateCompanyBenefit } from "api/companies/company_benefits"

import Form from "./Form"

const EditBenefit = ({
  companyId,
  benefit,
  company_benefit,
  handleSuccess,
  handleClose,
}) => {
  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    return updateCompanyBenefit(companyId, data)
      .then(response => {
        toast.success("Update successful")
        handleSuccess(response.data)
        handleClose()
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        benefit={benefit}
        company_benefit={company_benefit}
        errors={errors}
      />
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default EditBenefit
