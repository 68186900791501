import React, { useEffect, useState } from "react"
import { getCategories } from "api/tech_stacks/categories"

import Select from "components/forms/Select"

const TechStackCategoriesSelect = ({ ...props }) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    getCategories().then(response => {
      setCategories(response.data.rows)
    })
  }, [])

  return <Select options={categories} {...props} />
}

export default TechStackCategoriesSelect
