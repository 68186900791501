import apiCall from "utils/api"

export const getUserJobApplications = (userId, params) => {
  const options = { params }
  return apiCall("get", `users/${userId}/job_applications`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
