import apiCall from "utils/api"

export const getDashboardMetrics = () => {
  return apiCall("get", `dashboard`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getDashboardChart = params => {
  const options = { params }
  return apiCall("get", `dashboard/chart`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getOperationsDashboard = () => {
  return apiCall("get", `dashboard/operations`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
