import Badge from "react-bootstrap/Badge"

import "./StatusBadge.scss"

function StatusBadge({ status }) {
  let variant
  switch (status?.toLowerCase()) {
    case "published":
      variant = "success"
      break
    case "new":
      variant = "secondary"
      break
    case "deleted":
      variant = "danger"
      break
    default:
      variant = "secondary"
  }

  return (
    <Badge bg={variant} className="company-status-badge">
      {status}
    </Badge>
  )
}

export default StatusBadge
