import apiCall from "utils/api"

export const getAdmins = params => {
  const options = { params }
  return apiCall("get", `admins`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const inviteAdmin = params => {
  const options = { data: { admin: params } }
  return apiCall("post", `admins/invitation`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateAdmin = (id, params) => {
  const options = { data: { admin: params } }
  return apiCall("patch", `admins/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteAdmin = id => {
  return apiCall("delete", `admins/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
