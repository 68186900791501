import apiCall from "utils/api"

export const getCompanyEmployers = (id, params) => {
  const options = { params }
  return apiCall("get", `/companies/${id}/employers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
