import { useState, useRef } from "react"
import { getCompanyQuestions } from "api/companies/questions"
import { AgGridDateTime } from "utils/cellRenderers"
import { useNavigate } from "react-router-dom"

import StatusBadge from "components/questions/StatusBadge"
import { Link } from "react-router-dom"
import AgGrid from "components/ag_grid/AgGrid"

const CompanyQuestionsRoute = ({ id }) => {
  const gridRef = useRef()
  const navigate = useNavigate()

  const [columnDefs] = useState([
    {
      field: "questions.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      width: 120,
    },
    {
      field: "users.name",
      headerName: "Asked By",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return data.user ? (
          <Link to={`/users/${data.user.id}`}>{data.user.name}</Link>
        ) : data.user?.id ? (
          String.fromCharCode(8212)
        ) : (
          <img src="/chatgpt.svg" alt="ChatGPT" height="24" width="24" />
        )
      },
    },
    {
      field: "body",
      headerName: "Body",
      filter: "agTextColumnFilter",
    },
    {
      field: "published_answers_count",
      headerName: "Answers",
      cellStyle: { justifyContent: "flex-end" },
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.answers_count,
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Pending", "Published", "Ignored"],
      },
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
      width: 150,
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 150,
    },
  ])

  const onCellClicked = e => {
    navigate(`/questions/${e.data.id}`)
  }

  const retrieveCompanyQuestions = async params => {
    return getCompanyQuestions(id, params)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Questions</h3>
      </div>
      <AgGrid
        onCellClicked={onCellClicked}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={retrieveCompanyQuestions}
        sizeToFit={true}
      />
    </>
  )
}

export default CompanyQuestionsRoute
