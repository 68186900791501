import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getUser } from "api/users"
import { getUserAudits } from "api/users"
import { useSelector } from "react-redux"

import Header from "components/Header"
import StatusBadge from "components/users/StatusBadge"
import Tabs from "components/Tabs"
import ProfileRoute from "routes/users/Profile"
import RatingsRoute from "routes/users/Ratings"
import RankingsRoute from "routes/users/Rankings"
import ReviewsRoute from "routes/users/Reviews"
import JobApplicationsRoute from "routes/users/JobApplications"
import ReferralsRoute from "routes/users/Referrals"
import AuditLogRoute from "components/shared/AuditLog"
import NotificationPreferencesRoute from "./users/NotificationPreferences"

const UserRoute = () => {
  const { id } = useParams()

  const [user, setUser] = useState({})

  const { currentAdmin } = useSelector(state => state.auth)
  const { access_level } = currentAdmin
  const isFullAdmin = access_level === "full"

  const tabs = [
    {
      label: "Profile",
      hash: "#profile",
      component: <ProfileRoute user={user} setUser={setUser} />,
    },
    {
      label: "Ratings",
      hash: "#ratings",
      component: <RatingsRoute id={id} />,
    },
    {
      label: "Rankings",
      hash: "#rankings",
      component: <RankingsRoute id={id} />,
      limited: true,
    },
    {
      label: "Reviews",
      hash: "#reviews",
      component: <ReviewsRoute id={id} />,
      limited: true,
    },
    {
      label: "Job Applications",
      hash: "#job_applications",
      component: <JobApplicationsRoute id={id} />,
    },
    {
      label: "Referrals",
      hash: "#referrals",
      component: <ReferralsRoute id={id} />,
    },
    {
      label: "Notification Preferences",
      hash: "#notification-preferences",
      component: <NotificationPreferencesRoute id={id} />,
      limited: true,
    },
    {
      label: "Audit Log",
      hash: "#audits",
      component: (
        <AuditLogRoute id={id} route={"user"} getterFunction={getUserAudits} />
      ),
    },
  ]

  useEffect(() => {
    getUser(id)
      .then(response => {
        setUser(response.data)
      })
      .catch(() => {
        // setLoading(false)
      })
  }, [id])

  return (
    <>
      <Header title={user.name} icon="user">
        <div className="header-badges">
          <StatusBadge status={user.status} />
          {user.discarded_at && <StatusBadge status="Deleted" />}
        </div>
      </Header>
      <div className="user content">
        <Tabs tabs={tabs} defaultTab="profile" isFullAdmin={isFullAdmin} />
      </div>
    </>
  )
}

export default UserRoute
