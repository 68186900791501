import apiCall from "utils/api"

export const getTags = params => {
  const options = { params }
  return apiCall("get", `tags`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getTag = id => {
  return apiCall("get", `tags/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createTag = params => {
  const options = { data: params }
  return apiCall("post", `tags`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateTag = (id, params) => {
  const options = { data: params }
  return apiCall("put", `tags/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteTag = id => {
  return apiCall("delete", `tags/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
