import { useState } from "react"

import WorkExperience from "./WorkExperience"
import "./WorkExperiences.scss"

const WorkExperiences = ({ workExperiences, userId }) => {
  const [experiences, setExperiences] = useState(workExperiences)

  return (
    <>
      <div className="linkedin-experience">
        <h4>LinkedIn Experience </h4>
        <div className="editable-table">
          <table className="table">
            <thead>
              <tr>
                <th className="company">Company Name</th>
                <th className="company">Company</th>
                <th className="company">Title</th>
                <th className="date">Start Date</th>
                <th className="date">End Date</th>
                <th className="edit-experience">Edit</th>
              </tr>
            </thead>
            <tbody>
              {experiences.map(experience => (
                <WorkExperience
                  experience={experience}
                  userId={userId}
                  experiences={experiences}
                  setExperiences={setExperiences}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
    </>
  )
}

export default WorkExperiences
