import apiCall from "utils/api"

export const getUserNotificationPreferences = userId => {
  return apiCall("get", `users/${userId}/notification_preferences`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateUserNotificationPreference = (userId, params) => {
  return apiCall("put", `users/${userId}/notification_preferences`, params)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
