import { useState } from "react"
import toast from "react-hot-toast"
import { updateDataCustomer } from "api/data_customers"

import Form from "./Form"

const EditDataCustomer = ({ customer, handleSuccess, handleClose }) => {
  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    return updateDataCustomer(customer.id, data)
      .then(res => {
        toast.success("Customer updated successfully!")
        handleSuccess(res.data)
        handleClose()
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  return (
    <>
      <Form onSubmit={onSubmit} errors={errors} customer={customer} />
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default EditDataCustomer
