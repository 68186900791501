import { useState, useRef, useEffect } from "react"
import { getReviews } from "api/reviews"
import { AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams, getQueryString } from "utils/query"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"

import { Link } from "react-router-dom"
import StatusBadge from "components/reviews/StatusBadge"
import GridUser from "components/users/GridUser"
import AgGrid from "components/ag_grid/AgGrid"
import Header from "components/Header"
import ReviewDetail from "components/reviews/Detail"

const ReviewsRoute = () => {
  const initialParams = getInitialParams()

  const navigate = useNavigate()

  const { id } = useParams()

  const [columnDefs] = useState([
    {
      field: "reviews.id", // since companies also has an id col
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      width: 100,
    },
    {
      field: "users.name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data?.company?.name ? (
          <Link to={`/companies/${data?.company?.id}`}>
            {data?.company?.name}
          </Link>
        ) : (
          String.fromCharCode(8212)
        ),
    },
    {
      field: "reviews.body",
      headerName: "Body",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.body,
    },
    {
      field: "approved_response",
      headerName: "Approved Response",
      cellRenderer: ({ data }) => (data.approved_response ? "Yes" : "No"),
      cellStyle: { justifyContent: "center" },
      sortable: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      field: "pending_responses_count",
      headerName: "Pending Responses",
      cellStyle: { justifyContent: "flex-end" },
      filter: "agNumberColumnFilter",
    },
    {
      field: "reviews.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Approved", "Pending", "Removed"],
      },
      sortable: false,
      cellRenderer: ({ data }) => {
        return <StatusBadge status={data.status} />
      },
      width: 150,
    },
    {
      field: "reviews.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])
  const [showDetail, setShowDetail] = useState(false)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const gridRef = useRef()

  const hideDetail = () => {
    const search = getQueryString()
    setShowDetail(false)
    navigate(`/reviews${search}`)
  }

  const onCellClicked = event => {
    const { colDef } = event
    if (
      colDef.field !== "users.first_name" ||
      "companies.name" ||
      "ratings.id"
    ) {
      const search = getQueryString()
      navigate(`/reviews/${event.data.id}${search}`)
    }
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header title="Reviews" icon="review" />
      <div className="grid-wrapper">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getReviews}
          sizeToFit={true}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
          onCellClicked={onCellClicked}
        />
      </div>
      <ReviewDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setUpdatedRecord={setUpdatedRecord}
      />
    </>
  )
}

export default ReviewsRoute
