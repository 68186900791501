import React from "react"
import { Outlet } from "react-router-dom"
import logo from "assets/repvue.png"

import "./Auth.scss"

function AuthRoute() {
  return (
    <div className="auth">
      <img src={logo} alt="RepVue" />
      <Outlet />
    </div>
  )
}

export default AuthRoute
