import Badge from "react-bootstrap/Badge"

import "./StatusBadge.scss"

function StatusBadge({ status, pill = false }) {
  let variant
  switch (status?.toLowerCase()) {
    case "active":
      variant = "success"
      break
    case "draft":
      variant = "secondary"
      break
    case "pending approval":
      variant = "warning"
      break
    case "closed":
    case "rejected":
      variant = "danger"
      break
    default:
      variant = "secondary"
  }

  return (
    <Badge
      pill={pill}
      bg={variant}
      className={`${!pill && "job-status-badge"}`}
    >
      {status}
    </Badge>
  )
}

export default StatusBadge
