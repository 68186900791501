import { useState, useRef, useCallback } from "react"
import { getCompanyRatings } from "api/companies/ratings"
import { AgGridDateTime } from "utils/cellRenderers"
import { Link } from "react-router-dom"

import AgGrid from "components/ag_grid/AgGrid"
import RatingDetail from "components/ratings/Detail"
import StatusBadge from "components/ratings/StatusBadge"

const CompanyRatingsRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 120,
    },
    {
      field: "users.name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/users/${data.user.id}`}>{data.user.name}</Link>
      ),
    },
    {
      field: "ratings.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "ratings.effective_at",
      headerName: "Effective Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.effective_at),
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Not Reviewed", "Unverified", "Verified", "Deleted"],
      },
      sortable: false,
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
    },
  ])
  const [showDetail, setShowDetail] = useState(null)

  const hideDetail = () => {
    setShowDetail(undefined)
  }

  const retrieveCompanyRatings = useCallback(
    params => getCompanyRatings(id, params),
    [id]
  )

  const onCellClicked = e => {
    setShowDetail(e.data.id)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Ratings</h3>
      </div>
      <AgGrid
        onCellClicked={onCellClicked}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={retrieveCompanyRatings}
        search={""}
        sizeToFit={true}
      />
      <RatingDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        refresh={() => setShowDetail(id)}
      />
    </>
  )
}

export default CompanyRatingsRoute
