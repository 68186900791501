import Form from "./Form"
import Comment from "./Comment"
import Icon from "components/Icon"

import "./Comments.scss"

const Comments = ({ type, id, comments, addComment }) => {
  return (
    <div className="comments">
      <h5>
        <Icon name="comment" />
        Comments
      </h5>
      <div className="comments-content">
        <Form id={id} type={type} handleSuccess={addComment} />
        <div className="editable-table">
          {comments &&
            comments.map(comment => (
              <Comment key={comment.id} comment={comment} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default Comments
