import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getJob } from "api/jobs"
import { getJobAudits } from "api/jobs"
import { cleanTrim } from "utils/format"

import Header from "components/Header"
import StatusBadge from "components/jobs/StatusBadge"
import TierBadge from "components/jobs/TierBadge"
import Tabs from "components/Tabs"
import JobOverviewRoute from "routes/jobs/Overview"
import JobApplicationsRoute from "routes/jobs/JobApplications"
import AuditLogRoute from "components/shared/AuditLog"
import JobPromoteRoute from "routes/jobs/Promote"

const JobRoute = () => {
  const { id } = useParams()

  const [job, setJob] = useState({})
  const [loading, setLoading] = useState(true)

  const { job_applications_count, customer } = job

  const CountTab = ({ label, count }) => {
    return (
      <div className="d-flex align-items-center gap-2">
        {label}
        <div className="badge rounded-pill bg-primary me-2">{count}</div>
      </div>
    )
  }

  const tabs = [
    {
      label: "Overview",
      hash: "#overview",
      component: <JobOverviewRoute job={job} setJob={setJob} />,
    },
    {
      label: <CountTab label="Applications" count={job_applications_count} />,
      hash: "#applications",
      component: <JobApplicationsRoute id={id} />,
    },
    {
      label: "Audit Log",
      hash: "#audit-log",
      component: <AuditLogRoute id={id} getterFunction={getJobAudits} />,
    },
  ]

  useEffect(() => {
    getJob(id)
      .then(response => {
        setJob(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [id])

  if (job.status === "Active") {
    tabs.push({
      label: "Promote",
      hash: "#promote",
      component: <JobPromoteRoute id={id} />,
    })
  }

  const freeTier = customer?.access_type === "Free"

  return (
    <>
      <Header title={cleanTrim(job.title, 65, true)} icon="job">
        <div className="d-flex">
          {freeTier && <TierBadge />}
          <StatusBadge status={job.status} />
        </div>
      </Header>
      {loading ? (
        <div />
      ) : (
        <div className="job content">
          <Tabs tabs={tabs} defaultTab="overview" />
        </div>
      )}
    </>
  )
}

export default JobRoute
