import React, { useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col } from "react-bootstrap"

import Input from "components/forms/Input"
import CustomersSelect from "components/customers/Select"

import "./Form.scss"

const EmployerForm = ({ onSubmit, employer, errors }) => {
  const { handleSubmit, register, reset, control } = useForm()

  const defaultValues = useMemo(
    () => ({
      first_name: employer?.first_name,
      last_name: employer?.last_name,
      email: employer?.email,
      title: employer?.title,
      customer_id: employer?.customer?.id,
      access_type: employer?.access_type,
      photo: undefined,
    }),
    [employer]
  )

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <Col>
          <label htmlFor="first_name" className="form-label">
            First Name
          </label>
          <Input
            type="text"
            name="first_name"
            label="First Name"
            error={getErrors("first_name")[0]}
            register={register}
          />
        </Col>
        <Col>
          <label htmlFor="last_name" className="form-label">
            Last Name
          </label>
          <Input
            type="text"
            name="last_name"
            label="Last Name"
            error={getErrors("last_name")[0]}
            register={register}
          />
        </Col>
      </Row>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <Input
          type="text"
          name="email"
          label="Email"
          error={getErrors("email")[0]}
          register={register}
        />
      </div>
      {!employer && (
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <Input
            type="password"
            name="password"
            label="Password"
            error={getErrors("password")[0]}
            register={register}
          />
        </div>
      )}
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <Input
          type="text"
          name="title"
          label="Title"
          error={getErrors("title")[0]}
          register={register}
        />
      </div>
      <div className={`mb-3 ${errors?.customer && "invalid-select"}`}>
        <label htmlFor="customer" className="form-label">
          Customer
        </label>
        <div className={`form-field ${errors?.customer && "is-invalid"}`}>
          <CustomersSelect
            name="customer_id"
            control={control}
            value={employer?.customer}
            error={getErrors("customer_id")[0]}
          />
        </div>
      </div>
      {/* <div className="mb-3">
        <input
          id="active"
          name="active"
          className="form-check-input"
          type="checkbox"
          {...register("active")}
          defaultChecked={defaultValues.active}
        />
        <label htmlFor="open_to_new" className="form-check-label">
          Active
        </label>
      </div> */}
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default EmployerForm
