import { useState } from "react"
import { useForm } from "react-hook-form"
import { createComment } from "api/comments"

const CommentForm = ({ id, type, handleSuccess }) => {
  const [loading, setLoading] = useState(false)

  const { handleSubmit, register, reset } = useForm()

  const onSubmit = data => {
    setLoading(true)

    return createComment(type, id, data)
      .then(result => {
        reset()
        handleSuccess(result.data)
        setLoading(false)
      })
      .catch(response => {
        // setErrors(response?.data)
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <textarea
          className="form-control form-control-sm"
          rows="4"
          {...register("body")}
        />
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary btn-sm"
          disabled={loading}
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default CommentForm
