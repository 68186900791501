import { useState, useEffect, useRef } from "react"
import { getQuestions } from "api/questions"
import { useNavigate, useParams } from "react-router-dom"
import { AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams, getQueryString } from "utils/query"

import QuestionDetail from "components/questions/Detail"
import { Link } from "react-router-dom"
import Header from "components/Header"
import StatusBadge from "components/questions/StatusBadge"
import AgGrid from "components/ag_grid/AgGrid"
import Button from "react-bootstrap/Button"
import Modal from "components/Modal"
import AskForm from "components/questions/Form"

const QuestionsRoute = () => {
  const gridRef = useRef()
  const { id } = useParams()
  const navigate = useNavigate()

  const [showDetail, setShowDetail] = useState(undefined)
  const [updatedRecord, setUpdatedRecord] = useState(null)
  const [showQuestionModal, setShowQuestionModal] = useState(false)

  const initialParams = getInitialParams()

  const [columnDefs] = useState([
    {
      field: "questions.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      width: 120,
    },
    {
      field: "users.name",
      headerName: "Asked By",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        const askedByRepVue = data.type === "Asked by RepVue"

        return data.user?.name ? (
          <Link to={`/users/${data.user.id}`}>{data.user.name}</Link>
        ) : data.user?.id ? (
          String.fromCharCode(8212)
        ) : (
          <img
            src={askedByRepVue ? "/repvue.svg" : "/chatgpt.svg"}
            alt={askedByRepVue ? "RepVue" : "ChatGPT"}
            height="24"
            width="24"
          />
        )
      },
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
        )
      },
    },
    {
      field: "body",
      headerName: "Body",
      filter: "agTextColumnFilter",
    },
    {
      field: "published_answers_count",
      headerName: "Answers",
      cellStyle: { justifyContent: "flex-end" },
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.answers_count,
    },
    {
      field: "pending_answers_count",
      headerName: "Pending Answers",
      cellStyle: { justifyContent: "flex-end" },
      filter: "agNumberColumnFilter",
    },
    {
      field: "questions.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "questions.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Pending", "Published", "Ignored"],
      },
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
    },
  ])

  const hideDetail = () => {
    const search = getQueryString()
    setShowDetail(undefined)
    navigate(`/questions${search}`)
  }

  const onCellClicked = e => {
    const search = getQueryString()
    navigate(`/questions/${e.data.id}${search}`)
  }

  const closeModal = () => {
    setShowQuestionModal(false)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header title="Questions" icon="question">
        <Button variant="primary" onClick={() => setShowQuestionModal(true)}>
          Ask a Question
        </Button>
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getQuestions}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          sizeToFit={true}
          updatedRecord={updatedRecord}
        />
      </div>
      <QuestionDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        handleGridUpdate={setUpdatedRecord}
      />
      <Modal
        title="Ask a Question as RepVue"
        show={showQuestionModal}
        handleClose={closeModal}
      >
        <AskForm handleClose={closeModal} />
      </Modal>
    </>
  )
}

export default QuestionsRoute
