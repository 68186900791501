import { Link } from "react-router-dom"
import Badge from "react-bootstrap/Badge"

import "./GridUser.scss"

function GridUser({ user }) {
  if (!user) return String.fromCharCode(8212)

  const { id, name, discarded_at } = user

  return (
    <div className="grid-user">
      <Link to={`/users/${id}`}>{name}</Link>
      {discarded_at && <Badge bg="danger">Deleted</Badge>}
    </div>
  )
}

export default GridUser
