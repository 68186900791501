import { useState } from "react"
import { useSelector } from "react-redux"
import toast from "react-hot-toast"
import { capitalizeString } from "utils/string"
import { approveContent, unapproveContent } from "api/approvals"
import { Button } from "react-bootstrap"

const ApprovalActions = ({
  children,
  contentId,
  contentType,
  status,
  approvals,
  ready_for_publish,
  editing,
  handleSuccess,
}) => {
  const { currentAdmin } = useSelector(state => state.auth)
  const [approving, setApproving] = useState(false)
  const adminId = currentAdmin?.id

  const handleApprove = () => {
    setApproving(true)
    approveContent(contentId, contentType)
      .then(response => {
        handleSuccess(response.data)
        toast.success(`${capitalizeString(contentType)} approved`)
        setApproving(false)
      })
      .catch(() => {
        setApproving(false)
      })
  }

  const handleUnApprove = () => {
    unapproveContent(contentId, contentType)
      .then(response => {
        handleSuccess(response.data)
        toast.success(`${capitalizeString(contentType)} unapproved`)
      })
      .catch(() => {
        // setLoading(false)
      })
  }

  // UI adjustments to keep things tight
  const className = contentType === "answer" ? "btn-sm" : "w-100"

  const adminHasApproved = approvals?.some(
    approval => approval.admin.id === adminId
  )
  const showApproveButton =
    status !== "Published" &&
    !ready_for_publish &&
    !adminHasApproved &&
    !editing
  const showUnApproveButton =
    status !== "Published" && !ready_for_publish && adminHasApproved && !editing
  const requiredApprovals = 2 - approvals?.length
  const approvalBlurb =
    requiredApprovals === 2 ? "2 approvals" : "1 more approval"

  return (
    <>
      {!ready_for_publish && (
        <div className="alert alert-warning">
          This {contentType} requires <strong>{approvalBlurb}</strong> in order
          to publish.
        </div>
      )}
      <div className="detail-footer-actions">
        {showApproveButton && (
          <Button
            variant="success"
            className={className}
            disabled={approving}
            onClick={handleApprove}
          >
            Approve
          </Button>
        )}
        {showUnApproveButton && (
          <Button
            variant="warning"
            className={className}
            onClick={handleUnApprove}
          >
            Unapprove
          </Button>
        )}
        {children}
      </div>
    </>
  )
}

export default ApprovalActions
