import React from "react"
import { useForm } from "react-hook-form"

import TechStackSelect from "./Select"

const TechStackForm = ({ onSubmit, errors }) => {
  const { handleSubmit, control } = useForm()

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TechStackSelect
        name="tech_stack_id"
        control={control}
        value="tech_stack_id"
        error={getErrors("tech_stack")[0]}
      />
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default TechStackForm
