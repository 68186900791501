import { useState, useRef } from "react"
import { getIgnoredJobs } from "api/jobs"
import { useNavigate } from "react-router-dom"
import { AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"

import { Link } from "react-router-dom"
import Header from "components/Header"
import { Badge } from "react-bootstrap"
import SearchInput from "components/forms/SearchInput"
import AgGrid from "components/ag_grid/AgGrid"

const IgnoredJobsRoute = () => {
  const navigate = useNavigate()
  const gridRef = useRef()

  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [columnDefs] = useState([
    {
      field: "jobs.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => <div>{data.id}</div>,
    },
    {
      field: "jobs.title",
      headerName: "Title",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <span className="d-flex align-items-center justify-content-between">
            <span>{data.title}</span>
            {data.featured && (
              <Badge pill bg="info" text="dark">
                Featured
              </Badge>
            )}
          </span>
        )
      },
      width: 410,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
      ),
    },
    {
      field: "jobs.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/jobs/${e.data.id}`)
  }

  return (
    <>
      <Header title="Ignored Job Posts" icon="job" className="has-search">
        <SearchInput
          value={search}
          placeholder={"Search ignored jobs..."}
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getIgnoredJobs}
          search={search}
          sizeToFit={true}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
        />
      </div>
    </>
  )
}

export default IgnoredJobsRoute
