import { useState } from "react"

const DateRange = ({ handleRangeChange, startDate, endDate }) => {
  const [localStartDate, setLocalStartDate] = useState(startDate)
  const [localEndDate, setLocalEndDate] = useState(endDate)

  const updateRange = e => {
    e.preventDefault()
    handleRangeChange(localStartDate, localEndDate)
  }

  return (
    <form onSubmit={e => updateRange(e)} className="row date-range">
      <div className="col">
        <input
          className="form-control form-control-sm"
          type="date"
          name="start_date"
          value={localStartDate}
          onChange={e => setLocalStartDate(e.target.value)}
        />
      </div>
      <div className="col">
        <input
          className="form-control form-control-sm"
          type="date"
          name="end_date"
          value={localEndDate}
          onChange={e => setLocalEndDate(e.target.value)}
        />
      </div>
      <div className="col">
        <button type="submit" className="btn btn-primary btn-sm">
          Run
        </button>
      </div>
    </form>
  )
}

export default DateRange
