export const toastOptions = {
  duration: 3000,
  success: {
    style: {
      background: "#198754",
      color: "#ffffff",
    },
    iconTheme: {
      primary: "#ffffff",
      secondary: "#198754",
    },
  },
  error: {
    style: {
      background: "red",
      color: "#ffffff",
    },
  },
}
