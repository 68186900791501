import Modal from "components/Modal"
import Button from "react-bootstrap/Button"

const NewVersionNotification = ({ show, closeModal }) => {
  return (
    <Modal title="New Version Available" show={show} handleClose={closeModal}>
      <span>
        Would you like to refresh your browser to receive the latest version?
      </span>
      <div className="text-center mt-3">
        <Button className="w-100 mb-2" onClick={() => closeModal(true)}>
          Yes
        </Button>
        <Button
          className="w-25"
          variant="link"
          onClick={() => closeModal(false)}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default NewVersionNotification
