import apiCall from "utils/api"

export const getIndustries = () => {
  return apiCall("get", `industries`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
