import apiCall from "utils/api"
import pluralize from "pluralize"

export const approveContent = (id, type) => {
  const content = pluralize(type)

  return apiCall("post", `${content}/${id}/approve`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const unapproveContent = (id, type) => {
  const content = pluralize(type)

  return apiCall("post", `${content}/${id}/unapprove`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getReviewApprovals = () => {
  // get all reviews that need one approval and that I have not approved
  return apiCall(
    "get",
    `approvables?approvable_type=Review&pending_approvals=1&approved=false`
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getQuestionApprovals = () => {
  // get all questions that need one approval and that I have not approved
  return apiCall(
    "get",
    `approvables?approvable_type=Question&pending_approvals=1&approved=false`
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getAnswerApprovals = () => {
  // get all answers that need one approval and that I have not approved
  return apiCall(
    "get",
    `approvables?approvable_type=Answer&pending_approvals=1&approved=false`
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
