import apiCall from "utils/api"

export const getTechStackCompanies = id => {
  return apiCall("get", `tech_stacks/${id}/companies`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getTechStack = id => {
  return apiCall("get", `tech_stacks/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
