import { useEffect, useState, useMemo } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { updateCompany } from "api/companies"
import { Container, Row, Col } from "react-bootstrap"

import Testimonials from "components/companies/culture/testimonials/Index"
import Photos from "components/companies/culture/photos/Index"
import Videos from "components/companies/culture/videos/Index"
import LeadershipRoute from "components/companies/culture/Leadership"

import "./Culture.scss"

const CompanyCultureRoute = ({ company, setCompany }) => {
  const [editing, setEditing] = useState(false)

  const { register, handleSubmit, reset } = useForm()

  const defaultValues = useMemo(
    () => ({
      culture_summary: company.culture_summary,
    }),
    [company]
  )

  const { id, culture_summary } = company

  // since the company data is coming in async
  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const handleUpdate = data => {
    updateCompany(id, data)
      .then(response => {
        setCompany(response.data)
        toast.success("Update successful")
        setEditing(false)
      })
      .catch(response => {})
  }

  const cancelEditing = () => {
    setEditing(false)
    reset({ ...defaultValues })
  }

  return (
    <div className="company-culture">
      <div className="tab-header">
        <h3>Culture</h3>
      </div>
      <section>
        <form onSubmit={handleUpdate}>
          <div className={`editable-table ${editing ? "editing" : ""}`}>
            <div className="section-header">
              <h5>Summary</h5>
              {!editing ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setEditing(true)}
                >
                  Edit
                </button>
              ) : (
                <div>
                  <button
                    className="btn btn-light btn-sm me-2"
                    onClick={cancelEditing}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={handleSubmit(handleUpdate)}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <Container fluid>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <div className="form-value text-field">
                    {culture_summary || (
                      <div className="text-center">
                        {String.fromCharCode(8212)}
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <textarea
                      className="form-control form-control-sm"
                      rows="6"
                      {...register("culture_summary")}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </form>
      </section>
      <section>{id && <Photos id={id} />}</section>
      <section>{id && <Videos id={id} />}</section>
      <section>{id && <Testimonials id={id} />}</section>
      <section>
        <LeadershipRoute id={id} />
      </section>
    </div>
  )
}

export default CompanyCultureRoute
