import apiCall from "utils/api"
import { setCurrentAdmin, signOutAdmin } from "reducers/auth"

export const signIn = params => async dispatch => {
  const options = { data: params }
  return apiCall("post", `admins/sign_in`, options)
    .then(response => {
      const admin = response.data
      dispatch(setCurrentAdmin(admin))
      return response
    })
    .catch(error => {
      console.error("sign in", error)
      throw error
    })
}

export const signOut = () => async dispatch => {
  return apiCall("delete", `admins/sign_out`)
    .then(response => {
      dispatch(signOutAdmin())
      return response
    })
    .catch(error => {
      dispatch(signOutAdmin())
      console.error(error.message)
      throw error
    })
}

export const resetPassword = params => async () => {
  const options = { data: params }
  return apiCall("post", `admins/password`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}

export const changePassword = params => async () => {
  const options = { data: params }
  return apiCall("put", `admins/password`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}

export const getCurrentAdmin = () => async dispatch => {
  return apiCall("get", `admins/me`)
    .then(response => {
      const admin = response.data
      dispatch(setCurrentAdmin(admin))
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}

export const registerAdmin = params => async dispatch => {
  const options = { data: params }
  return apiCall("put", `admins/invitation`, options)
    .then(response => {
      dispatch(getCurrentAdmin())
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}

// export const updateAdmin = params => {
//   const options = { data: params }
//   return apiCall("patch", `admin`, options)
//     .then(response => {
//       return response
//     }).catch(error => {
//       console.error(error.message)
//       throw error
//     })
// }
