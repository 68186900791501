import apiCall from "utils/api"

export const getCandidateProfiles = params => {
  const options = { params }
  return apiCall("get", `candidate/profiles`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCandidateProfile = id => {
  return apiCall("get", `candidate/profiles/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
