import ProgressBar from "react-bootstrap/ProgressBar"
import "./RatingsGauge.scss"

const RatingsGauge = ({ speed }) => {
  const now = parseInt(Math.min(speed / 125, 1) * 100)
  const letsGo = speed >= 100

  const COLD = "#027497"
  const HOT = "#dc143c"
  const INCREMENTS = 100

  const hex = x => {
    const hexValue = x.toString(16)
    return hexValue.length === 1 ? "0" + hexValue : hexValue
  }

  const parseColor = color => {
    return {
      r: parseInt(color.slice(1, 3), 16),
      g: parseInt(color.slice(3, 5), 16),
      b: parseInt(color.slice(5, 7), 16),
    }
  }

  const getColor = value => {
    const color1Obj = parseColor(COLD)
    const color2Obj = parseColor(HOT)

    const r = Math.round(
      color1Obj.r + (color2Obj.r - color1Obj.r) * (value / INCREMENTS)
    )
    const g = Math.round(
      color1Obj.g + (color2Obj.g - color1Obj.g) * (value / INCREMENTS)
    )
    const b = Math.round(
      color1Obj.b + (color2Obj.b - color1Obj.b) * (value / INCREMENTS)
    )

    return `#${hex(r)}${hex(g)}${hex(b)}`
  }

  return (
    <div className="card ratings-gauge mb-3">
      <style type="text/css">
        {`
            .bg-custom {
              background-color: ${getColor(now)};
            }
          `}
      </style>
      <div className="card-body">
        <div className="ratings-gauge-header">
          <h5>Ratings per Hour</h5>
          {letsGo && (
            <div className="ratings-gauge-hype bg-custom">
              <span>Let's Go!!</span>
            </div>
          )}
        </div>
        <ProgressBar now={now} label={speed} variant="custom" />
      </div>
    </div>
  )
}

export default RatingsGauge
