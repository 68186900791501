import apiCall from "utils/api"

export const getEmployerCustomers = params => {
  const options = { params }
  return apiCall("get", `customers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getEmployerCustomer = id => {
  return apiCall("get", `customers/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const searchEmployerCustomers = params => {
  const options = { params }
  return apiCall("get", `customers/search`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createEmployerCustomer = params => {
  const options = { data: { customer: params } }
  return apiCall("post", `customers`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateEmployerCustomer = (id, params) => {
  const options = { data: { customer: params } }
  return apiCall("put", `customers/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getEmployerCustomerOptions = () => {
  return apiCall("get", `customers/form_options`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const approveEmployerCustomer = id => {
  return apiCall("patch", `customers/${id}/approve`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const denyEmployerCustomer = id => {
  return apiCall("patch", `customers/${id}/deny`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
