import { useState, useEffect } from "react"
import { deleteTestimonial } from "api/companies/testimonials"
import { getCompanyTestimonials } from "api/companies/testimonials"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"
import Imgix from "react-imgix"

import Button from "react-bootstrap/Button"
import Icon from "components/Icon"
import NewTestimonial from "./New"
import EditTestimonial from "./Edit"
import EmptyTestimonials from "./Empty"
import Modal from "components/Modal"

const Testimonials = ({ id }) => {
  const [testimonials, setTestimonials] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [editing, setEditing] = useState({})
  const [loading, setLoading] = useState(true)

  const loadTestimonials = id => {
    getCompanyTestimonials(id)
      .then(response => {
        setTestimonials(response.data.testimonials)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleEdit = id => {
    const testimonial = testimonials.find(testimonial => testimonial.id === id)
    setEditing(testimonial)
    setShowEdit(true)
  }

  const handleDelete = id => {
    const testimonial = testimonials.find(testimonial => testimonial.id === id)
    if (
      window.confirm(`Are you sure you want to delete ${testimonial.name}?`)
    ) {
      deleteTestimonial(id).then(() => {
        setTestimonials(
          testimonials.filter(testimonial => testimonial.id !== id)
        )
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowEdit(false)
    setShowNew(false)
  }

  // use this to update the testimonial in state as opposed to refreshing all or using redux
  const replaceTestimonial = testimonial => {
    const newTestimonials = [...testimonials]
    const index = newTestimonials.findIndex(t => t.id === testimonial.id)
    newTestimonials[index] = testimonial
    setTestimonials(newTestimonials)
  }

  useEffect(() => loadTestimonials(id), [id])

  if (loading) return <div />

  const Table = () => (
    <table className="table table-hover editable-table">
      <thead>
        <tr>
          <th className="text-center">Photo</th>
          <th>Name</th>
          <th>Title</th>
          <th>Body</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {testimonials &&
          testimonials.map(testimonial => {
            const { id, photo, name, title, body, updated_at } = testimonial

            return (
              <tr key={id}>
                <td className="testimonial-photo">
                  {photo ? (
                    <Imgix
                      src={`${process.env.REACT_APP_IMAGE_URL}/${photo}`}
                      width={50}
                      height={50}
                      alt={title}
                    />
                  ) : (
                    <Icon name="user" />
                  )}
                </td>
                <td>{name}</td>
                <td>{title}</td>
                <td className="testimonial-body">{body}</td>
                <td>{formatDateTime(updated_at)}</td>
                <td className="actions">
                  <button className="btn me-1" onClick={() => handleEdit(id)}>
                    <Icon name="edit" />
                  </button>
                  <button className="btn" onClick={() => handleDelete(id)}>
                    <Icon name="delete" />
                  </button>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )

  return (
    <>
      <div className="section-header">
        <h5>
          Testimonials
          <span>{`${testimonials.length} of 5 used`}</span>
        </h5>
        {testimonials.length < 5 && (
          <Button variant="primary" size="sm" onClick={() => setShowNew(true)}>
            Add Testimonial
          </Button>
        )}
      </div>

      {!loading && testimonials.length > 0 ? <Table /> : <EmptyTestimonials />}

      <Modal
        title={showEdit ? "Edit Testimonial" : "New Testimonial"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewTestimonial
            companyId={id}
            handleSuccess={() => loadTestimonials(id)}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditTestimonial
            testimonial={editing}
            handleSuccess={replaceTestimonial}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default Testimonials
