import apiCall from "utils/api"

export const getReferrals = params => {
  const options = { params }
  return apiCall("get", `referrals`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const payReferral = id => {
  return apiCall("post", `referrals/${id}/pay`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getVerifiedRaters = (id, params) => {
  const options = { params }
  return apiCall("get", `companies/${id}/verified_raters`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
