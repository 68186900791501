import { useState } from "react"

import Form from "react-bootstrap/Form"

const AccessSelect = ({ onChange, defaultValue }) => {
  const [displayedValue, setDisplayedValue] = useState(defaultValue)

  return (
    <Form.Select
      aria-label="Admin Access Level Select"
      onChange={event => {
        setDisplayedValue(event.target.value)
        onChange(event.target.value)
      }}
      value={displayedValue}
    >
      <option value="limited">Limited</option>
      <option value="full">Full</option>
    </Form.Select>
  )
}

export default AccessSelect
