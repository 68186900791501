import apiCall from "utils/api"

export const getCompanyVideos = id => {
  return apiCall("get", `companies/${id}/videos`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCompanyVideo = (companyId, params) => {
  const options = { data: { video: params } }
  return apiCall("post", `companies/${companyId}/videos`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateCompanyVideo = (id, params) => {
  const options = { data: { video: params } }
  return apiCall("patch", `videos/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteCompanyVideo = id => {
  return apiCall("delete", `videos/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
