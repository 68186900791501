import { useState, useEffect, useRef } from "react"
import { getEmployerCustomers } from "api/customers"
import { AgGridDateTime } from "utils/cellRenderers"
import { formatDate } from "utils/format"
import { Link, useParams, useNavigate } from "react-router-dom"
import { setQueryParams, getInitialParams, getQueryString } from "utils/query"

import Button from "react-bootstrap/Button"
import Icon from "components/Icon"
import Modal from "components/Modal"
import NewCustomer from "components/customers/New"
import EditCustomer from "components/customers/Edit"
import StatusBadge from "components/customers/StatusBadge"
import Header from "components/Header"
import SearchInput from "components/forms/SearchInput"
import AgGrid from "components/ag_grid/AgGrid"
import CustomerDetail from "components/customers/Detail"

import "./EmployerCustomers.scss"

const CustomersRoute = () => {
  const gridRef = useRef()

  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [showDetail, setShowDetail] = useState(false)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const { id } = useParams()
  const navigate = useNavigate()

  const [columnDefs] = useState([
    {
      field: "customers.id",
      headerName: "ID",
      hide: true,
      filter: "agNumberColumnFilter",
      width: 120,
      cellRenderer: ({ data }) => <div>{data.id}</div>,
    },
    {
      field: "companies_customers.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
      ),
    },
    {
      field: "access_type",
      headerName: "Tier",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Full Platform", "Profile Only", "Free"],
      },
    },
    {
      field: "integrations.name",
      headerName: "Integration",
      // filter: "agSetColumnFilter",
      // filterParams: {
      //   values: ["greenhouse", "lever"],
      // },
      cellRenderer: ({ data }) =>
        data.company.integration ? (
          <div className="customer-integration">
            <span>{data.company?.integration?.name}</span>
            {data.company?.integration?.enabled ? (
              <div className="pulse">
                <div className="ring"></div>
                <div className="circle"></div>
              </div>
            ) : (
              <b>&bull;</b>
            )}
          </div>
        ) : (
          String.fromCharCode(8212)
        ),
    },
    {
      field: "customers.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Pending", "Active", "Denied", "Inactive"],
      },
      cellRenderer: ({ data }) => {
        return <StatusBadge status={data.status} />
      },
    },
    {
      field: "customers.created_at",
      headerName: "Created At",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 300,
    },
    {
      field: "signed_at",
      headerName: "Date Signed",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => formatDate(data.signed_at),
      width: 300,
    },
    {
      field: "actions",
      headerName: "Edit",
      filter: false,
      suppressMenu: true,
      headerClass: "ag-center-aligned",
      cellClass: "justify-content-center",
      cellRenderer: ({ data }) => (
        <button className="btn" onClick={() => handleEdit(data)}>
          <Icon name="edit" />
        </button>
      ),
    },
  ])
  const [showNew, setShowNew] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [newCustomers, setNewCustomers] = useState([])

  const onCellClicked = e => {
    const search = getQueryString()
    navigate(`/customers/employers/${e.data.id}${search}`)
  }

  const hideDetail = () => {
    const search = getQueryString()
    setShowDetail(false)
    navigate(`/customers/employers${search}`)
  }

  const handleEdit = customer => {
    setShowEdit(customer)
  }

  const handleSuccess = customer => {
    setNewCustomers([...newCustomers, customer])
    closeModal()
  }

  const handleSearch = q => {
    setSearch(q)
  }

  const closeModal = () => {
    setShowNew(false)
    setShowEdit(null)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header title="Employer Customers" icon="customer" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search employer customers..."
          handleSearch={handleSearch}
        />
        <Button variant="primary" onClick={() => setShowNew(true)}>
          New Employer Customer
        </Button>
      </Header>
      <div className="grid-wrapper editable-table">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getEmployerCustomers}
          watchedValues={newCustomers}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          sizeToFit={true}
          updatedRecord={updatedRecord}
          search={search}
        />
      </div>
      <CustomerDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setGridRecord={setUpdatedRecord}
      />
      <Modal
        title={showNew ? "Create Employer Customer" : "Edit Employer Customer"}
        show={showNew || showEdit}
        handleClose={closeModal}
      >
        {showNew && (
          <NewCustomer
            handleSuccess={handleSuccess}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditCustomer
            handleSuccess={handleSuccess}
            customer={showEdit}
            handleClose={closeModal}
          />
        )}
      </Modal>
    </>
  )
}

export default CustomersRoute
