import { getFileIcon } from "utils/icons"

const Resume = ({ resume }) => {
  return resume?.url ? (
    <a href={resume?.url} target="_blank" rel="noreferrer">
      {getFileIcon(resume?.content_type)}
    </a>
  ) : (
    String.fromCharCode(8212)
  )
}

export default Resume
