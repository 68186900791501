import apiCall from "utils/api"

export const getReviews = params => {
  const options = { params }
  return apiCall("get", `reviews`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getReview = id => {
  return apiCall("get", `reviews/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const publishReview = id => {
  return apiCall("patch", `reviews/${id}/publish`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const removeReview = (id, params) => {
  const options = { data: params }
  return apiCall("patch", `reviews/${id}/remove`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
