import { Link } from "react-router-dom"

import Icon from "components/Icon"

import "./OperationsCard.scss"

const OperationsCard = ({ title, link, count }) => (
  <Link className="metric" to={link}>
    <div className="description">
      <div className="name">{title}</div>
    </div>
    <div className="stat">
      <div className={`${count > 0 ? "todo" : ""}`}>
        {Number(count).toLocaleString()}
      </div>
      <Icon name="chevronDown" />
    </div>
  </Link>
)

export default OperationsCard
