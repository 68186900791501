import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { getJobOutreachSummary, sendJobOutreachSummary } from "api/jobs/promote"
import { formatCurrency } from "utils/format"

import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import MetroLocationsSelect from "components/metro_locations/Select"

import "./Promote.scss"

const JobPromoteRoute = ({ id }) => {
  const [outreachSummary, setOutreachSummary] = useState({})
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const [additionalLocations, setAdditionalLocations] = useState([])

  const { handleSubmit, control } = useForm()

  useEffect(() => {
    getJobOutreachSummary(id)
      .then(response => {
        setOutreachSummary(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [id])

  const handleSendOutreach = () => {
    setSubmitting(true)
    sendJobOutreachSummary(id)
      .then(() => {
        toast.success("Talent outreach sent!")
        setSubmitting(false)
      })
      .catch(() => setSubmitting(false))
  }

  const handleUpdate = data => {
    setAdditionalLocations(data.metro_locations)
    getJobOutreachSummary(
      id,
      data.metro_locations.map(location => location.id)
    )
      .then(response => {
        setOutreachSummary(response.data)
      })
      .catch(error => {
        setErrors(error)
      })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  const {
    fully_remote,
    industry,
    job_role,
    max_repvue_score,
    ranges,
    users,
    users_count,
  } = outreachSummary

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        Loading Audience...
      </div>
    )
  }

  const buttonDisabled = users_count === 0 || submitting

  return (
    <div className="job-promote">
      <div className="tab-header">
        <h3>Promote</h3>
        <button
          onClick={handleSendOutreach}
          className="btn btn-primary"
          disabled={buttonDisabled}
        >
          Send Outreach Notification
        </button>
      </div>
      <div className="tab-content">
        <h5 className="mt-3 mb-2">Outreach Summary</h5>
        <Row className="mb-2">
          <Col>
            <div className="promote-card card">
              <div className="stat">
                <div className="stat-header">Job Role</div>
                <div className="value">{job_role}</div>
              </div>
              <div className="stat">
                <div className="stat-header">Industry</div>
                <div className="value">{industry}</div>
              </div>
              <div className="stat">
                <div className="stat-header">Fully Remote</div>
                <div className="value">{String(fully_remote)}</div>
              </div>
              <div className="stat">
                <div className="stat-header">Max RepVue Score</div>
                <div className="value">
                  {max_repvue_score?.toFixed(2) || String.fromCharCode(8212)}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="promote-card card">
              <div className="stat">
                <div className="stat-header">Base Compensation</div>
                <div className="value">
                  {ranges?.base_compensation_usd ? (
                    <>
                      {formatCurrency(ranges?.base_compensation_usd[0])} -{" "}
                      {formatCurrency(ranges?.base_compensation_usd[1])}
                    </>
                  ) : (
                    String.fromCharCode(8212)
                  )}
                </div>
              </div>
              <div className="stat">
                <div className="stat-header">OTE</div>
                <div className="value">
                  {ranges?.total_ote_earnings_usd ? (
                    <>
                      {formatCurrency(ranges?.total_ote_earnings_usd[0])} -{" "}
                      {formatCurrency(ranges?.total_ote_earnings_usd[1])}
                    </>
                  ) : (
                    String.fromCharCode(8212)
                  )}
                </div>
              </div>
              <div className="stat">
                <div className="stat-header">Average Deal Size</div>
                <div className="value">
                  {formatCurrency(ranges?.average_deal_size_usd[0])} -{" "}
                  {formatCurrency(ranges?.average_deal_size_usd[1])}
                </div>
              </div>
              <div className="stat">
                <div className="stat-header">Quota Attainment</div>
                <div className="value">
                  {ranges?.hitting_quota_percentage ? (
                    <>
                      {ranges?.hitting_quota_percentage[0]} -{" "}
                      {ranges?.hitting_quota_percentage[1]}
                    </>
                  ) : (
                    String.fromCharCode(8212)
                  )}
                </div>
              </div>
            </div>
          </Col>
          {!fully_remote && (
            <Col>
              <div className="promote-card card">
                <h6>Add additional locations to this outreach notification.</h6>
                <form
                  onSubmit={handleSubmit(handleUpdate)}
                  className="row row-cols-lg g-3 align-items-center"
                >
                  <div className="col-12">
                    <MetroLocationsSelect
                      name="metro_locations"
                      value={additionalLocations}
                      control={control}
                      multi={true}
                      error={getErrors("metro_locations")[0]}
                    />
                  </div>
                  <div className="col-12 text-end">
                    <button className="btn btn-primary">Update</button>
                  </div>
                </form>
              </div>
            </Col>
          )}
        </Row>

        {/* <code>{JSON.stringify(outreachSummary, null, 2)}</code> */}

        {users && users.length > 0 ? (
          <>
            <h5 className="mb-2">
              Users <span className="badge bg-primary">{users_count}</span>
            </h5>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>LinkedIn URL</th>
                  <th>Metro Location</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map(user => (
                    <tr key={user.id}>
                      <td>
                        <Link to={`/users/${user.id}`}>{user.name}</Link>
                      </td>
                      <td>{user.email}</td>
                      <td>
                        {user?.linkedin_url && (
                          <a
                            href={user?.linkedin_url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {user?.linkedin_url}
                          </a>
                        )}
                      </td>

                      <td>{user.metro_location}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <div className="alert alert-warning text-center">
            No users were returned for the above filters
            {/* <span className="fw-bold mx-1">OR</span>a talent outreach was sent
            for this job in the last month that included all eligible users. */}
          </div>
        )}
      </div>
    </div>
  )
}

export default JobPromoteRoute
