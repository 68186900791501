import { useState } from "react"
import toast from "react-hot-toast"
import { createDataCustomer } from "api/data_customers"

import Form from "./Form"

const NewDataCustomer = ({ handleSuccess, handleClose }) => {
  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    return createDataCustomer(data)
      .then(() => {
        toast.success("Customer created successfully!")
        handleSuccess(data)
        handleClose()
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  return (
    <>
      <Form onSubmit={onSubmit} errors={errors} />
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default NewDataCustomer
