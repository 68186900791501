import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { getVerifiedRaters } from "api/referrals"
import { customStyles } from "components/forms/reactSelectStyles"

const ReferenceSelect = ({
  companyId,
  className,
  name,
  label,
  error,
  value,
  multi,
  ...rest
}) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(value)
  }, [value])

  const loadOptions = inputValue => {
    return getVerifiedRaters(companyId, { q: inputValue }).then(response => {
      return response.data.rows
    })
  }

  return (
    <>
      <Controller
        name={name}
        {...rest}
        render={({ field: { onChange } }) => {
          return (
            <AsyncSelect
              isMulti={multi}
              styles={customStyles}
              className={`${error ? "is-invalid" : ""}`}
              aria-label={label}
              defaultOptions
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              loadOptions={loadOptions}
              defaultValue={selected}
              value={selected}
              onChange={value => {
                onChange(value.id)
                setSelected(value)
              }}
            />
          )
        }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}

export default ReferenceSelect
