import Imgix from "react-imgix"

import Icon from "components/Icon"

import "./MostRated.scss"

const MostRated = ({ companies }) => (
  <div className="card most-rated">
    <div className="card-body">
      <div className="most-rated-header">
        <h5>Most Rated Today</h5>
      </div>
      <div className="most-rated-companies">
        {companies?.map(company => {
          const { id, name, logo, ratings_count } = company

          return (
            <div className="company-row" key={id}>
              <div className="company">
                <div className="company-logo">
                  {logo ? (
                    <Imgix
                      src={`${process.env.REACT_APP_IMAGE_URL}/${logo}`}
                      alt={name}
                      width={30}
                      height={30}
                    />
                  ) : (
                    <Icon name="company" height={30} width={30} />
                  )}
                </div>
                <div className="company-name">{name}</div>
              </div>
              <div className="company-rating">{ratings_count}</div>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

export default MostRated
