import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { getAnswerApprovals } from "api/approvals"
import { Link } from "react-router-dom"
import { formatDateTime } from "utils/format"

import StatusBadge from "components/reviews/StatusBadge"
import Header from "components/Header"
import QuestionDetail from "components/questions/Detail"

import "./Table.scss"

const AnswerApprovalsRoute = () => {
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(true)
  const [showDetail, setShowDetail] = useState(undefined)
  const [approvedAnswer, setApprovedAnswer] = useState(null)

  const { id } = useParams()
  const navigate = useNavigate()

  const hideDetail = () => {
    setShowDetail(undefined)
    navigate("/approvals/answers")
  }

  const handleGridUpdate = data => {
    setApprovedAnswer(data)
    hideDetail()
  }

  useEffect(() => {
    getAnswerApprovals()
      .then(response => {
        setAnswers(response.data.approvables)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [approvedAnswer])

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  if (loading) {
    return <div />
  }

  return (
    <>
      <Header title="My Pending Answer Approvals" icon="answer" />
      <div className="approvals-table">
        {answers.length > 0 ? (
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Question ID</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {answers.map(review => {
                const { approvable } = review
                const { id, question_id, status, created_at } = approvable

                return (
                  <tr key={id}>
                    <td>
                      <Link to={`/approvals/answers/${question_id}`}>
                        {question_id}
                      </Link>
                    </td>
                    <td>
                      <StatusBadge status={status} />
                    </td>
                    <td>{formatDateTime(created_at)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <em>You have no pending answer approvals.</em>
        )}
      </div>
      <QuestionDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        handleGridUpdate={handleGridUpdate}
      />
    </>
  )
}

export default AnswerApprovalsRoute
