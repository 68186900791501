import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { getReview, publishReview, removeReview } from "api/reviews"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"
import { Button, Offcanvas, Tab, Tabs } from "react-bootstrap"

import DetailSlideout from "components/shared/DetailSlideout"
import GridUser from "components/users/GridUser"
import GridRating from "components/ratings/GridRating"
import Flags from "components/shared/Flags"
import Comments from "components/comments"
import Audits from "components/shared/Audits"
import StatusBadge from "./StatusBadge"
import SentimentBadge from "components/shared/SentimentBadge"
import Icon from "components/Icon"
import RemoveModal from "./RemoveModal"
import Response from "components/responses/Response"
import ApprovalActions from "components/approvals/Actions"
import ApprovalsList from "components/approvals/List"

import "./Detail.scss"

const ReviewDetail = ({ id, show, handleClose, setUpdatedRecord }) => {
  const [review, setReview] = useState({})
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState("details")
  const [showRemoveModal, setShowRemoveModal] = useState(null)

  const {
    body,
    created_at,
    company,
    rating,
    user,
    status,
    updated_at,
    audits,
    comments,
    content_categories,
    response,
    sentiment,
    flags,
    remove_reason,
    ready_for_publish,
    approvals,
  } = review

  const prependComment = comment => {
    setReview({ ...review, comments: [comment, ...comments] })
  }

  const handleApprove = () => {
    publishReview(id)
      .then(res => {
        setUpdatedRecord(res.data)
        handleClose()
        toast.success("Review published")
      })
      .catch(res => {
        toast.error("Something went wrong")
      })
  }

  const handleRemove = reason => {
    removeReview(id, reason)
      .then(res => {
        setReview(res.data)
        setShowRemoveModal(false)
        setUpdatedRecord(res.data)
        handleClose()
        toast.success("Review removed")
      })
      .catch(() => toast.error("Something went wrong"))
  }

  const handleResponseChange = response => {
    const newReview = { ...review }
    newReview.response = response
    newReview.pending_response_count = 0
    setReview(newReview)
    setUpdatedRecord(newReview)
  }

  const handleApprovalSuccess = data => {
    setReview(data)
    setUpdatedRecord(data)
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      getReview(id)
        .then(response => {
          setReview(response.data)
          setLoading(false)
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }, [id])

  const showPublishButton = status !== "Published" && ready_for_publish

  if (loading) {
    return <div />
  }

  return (
    <>
      <DetailSlideout
        show={show}
        onHide={handleClose}
        placement="end"
        onExited={() => {
          setActiveTab("details")
          setLoading(true)
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            Review
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Tabs
            id="review-tabs"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            className="mb-2"
            transition={false}
          >
            <Tab eventKey="details" title="Details">
              <div className="detail-body">
                <Flags flags={flags} />
                <section>
                  <div className="section-header">
                    <label>Status</label>
                    <StatusBadge status={status} />
                  </div>
                  {status === "Removed" && remove_reason && (
                    <div className="section-header">
                      <label>Remove Reason</label>
                      <span>{remove_reason}</span>
                    </div>
                  )}
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="company" /> Company
                    </label>
                  </div>
                  <table className="company-table">
                    <tbody>
                      <tr>
                        <td>Company</td>
                        <td>
                          <Link to={`/companies/${company.id}`}>
                            {company.name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Funding Type</td>
                        <td>
                          {company.funding_source || String.fromCharCode(8212)}
                        </td>
                      </tr>
                      {company.parent && (
                        <>
                          <tr>
                            <td>Parent</td>
                            <td>
                              <Link to={`/companies/${company?.parent?.id}`}>
                                {company?.parent?.name}
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td>Parent Funding Type</td>
                            <td>{company?.parent?.funding_source}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="user" /> User
                    </label>
                    <GridUser user={user} />
                  </div>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="ratings" /> Rating
                    </label>
                    <GridRating rating={rating} />
                  </div>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="categories" /> Categories
                    </label>
                    <div className="categories">
                      {content_categories?.length > 0 &&
                        content_categories.map(category => (
                          <span
                            key={category.id}
                            className="badge bg-primary me-1"
                          >
                            {category.category}
                          </span>
                        ))}
                    </div>
                  </div>
                </section>
                {sentiment && (
                  <section>
                    <div className="section-header">
                      <label>
                        <Icon name="sentiment" /> Sentiment
                      </label>
                      <h6 className="mb-0">
                        <SentimentBadge sentiment={sentiment} />
                      </h6>
                    </div>
                  </section>
                )}
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="info" /> About
                    </label>
                  </div>
                  <table className="company-table">
                    <tbody>
                      <tr>
                        <td>Created</td>
                        <td>{formatDateTime(created_at)}</td>
                      </tr>
                      <tr>
                        <td>Updated</td>
                        <td>{formatDateTime(updated_at)}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                {approvals?.length > 0 && (
                  <ApprovalsList approvals={approvals} />
                )}
                <section className="review-text">
                  <p dangerouslySetInnerHTML={{ __html: body }} />
                </section>

                {response && (
                  <section>
                    <label>Employer Response</label>
                    <Response
                      key={response.id}
                      response={response}
                      companyId={company.id}
                      handleResponseChange={handleResponseChange}
                    />
                  </section>
                )}
              </div>
              <div className="detail-footer">
                <ApprovalActions
                  contentId={id}
                  contentType="review"
                  status={status}
                  ready_for_publish={ready_for_publish}
                  approvals={approvals}
                  editing={false}
                  handleSuccess={handleApprovalSuccess}
                >
                  {showPublishButton && (
                    <Button
                      variant="success"
                      className="w-100"
                      onClick={handleApprove}
                    >
                      Publish
                    </Button>
                  )}
                  {status !== "Removed" && (
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={() => setShowRemoveModal(true)}
                    >
                      Remove
                    </Button>
                  )}
                </ApprovalActions>
              </div>
            </Tab>
            <Tab
              eventKey="comments"
              title={
                <>
                  Comments
                  {comments?.length > 0 && (
                    <span className="badge rounded-pill bg-secondary">
                      {comments.length}
                    </span>
                  )}
                </>
              }
            >
              <Comments
                id={id}
                type="reviews"
                comments={comments}
                addComment={prependComment}
              />
            </Tab>
            <Tab
              eventKey="audits"
              title={
                <>
                  Audits
                  {audits?.length > 0 && (
                    <span className="badge rounded-pill bg-secondary">
                      {audits.length}
                    </span>
                  )}
                </>
              }
            >
              <Audits audits={audits} />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </DetailSlideout>
      <RemoveModal
        show={showRemoveModal}
        handleClose={() => setShowRemoveModal(false)}
        data={review}
        handleSubmit={handleRemove}
        answer
      />
    </>
  )
}

export default ReviewDetail
