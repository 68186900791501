import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import { formatDateTime } from "utils/format"
import { deleteUser, undiscardUser, grantUnemployedAccess } from "api/users"
import dayjs from "dayjs"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"

import UserRecon from "components/users/UserRecon"
import JobPreferences from "components/users/JobPreferences"
import Icon from "components/Icon"
import Comments from "components/comments"
import WorkExperiences from "components/users/WorkExperiences"

import "./Profile.scss"

const UserProfileRoute = ({ user, setUser }) => {
  const {
    id,
    email,
    first_name,
    last_name,
    linkedin_url,
    country,
    metro_location,
    gender,
    ethnicity,
    education_level,
    phone,
    referrer,
    last_active_at,
    current_sign_in_at,
    sign_in_count,
    created_at,
    discarded_at,
    user_recon,
    comments,
    status,
    unemployed_access_until,
  } = user

  const { currentAdmin } = useSelector(state => state.auth)
  const { access_level } = currentAdmin
  const isFullAdmin = access_level !== "limited"

  const workExperiences = user_recon?.linkedin_work_experiences

  const handleDelete = e => {
    e.preventDefault()
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      deleteUser(id)
        .then(response => {
          setUser(response.data)
          toast.success("User deleted")
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }

  const handleUndiscard = e => {
    e.preventDefault()
    if (window.confirm(`Are you sure you want to restore this user?`)) {
      undiscardUser(id).then(res => {
        toast.success("Restore successful")
      })
    }
  }

  const handleUnemployedAccess = e => {
    e.preventDefault()
    if (
      window.confirm(
        `Are you sure you want to grant temporary access to this user?`
      )
    ) {
      grantUnemployedAccess(id).then(response => {
        setUser(response.data)
        toast.success("Access granted")
      })
    }
  }

  const handleUserReconUpdate = userRecon => {
    const newUser = { ...user }
    newUser.user_recon = userRecon
    setUser(newUser)
  }

  const prependComment = comment => {
    setUser({ ...user, comments: [comment, ...comments] })
  }

  return (
    <>
      <div className="tab-header">
        <h3>Profile</h3>
      </div>
      <div className="tab-container">
        <div className="user-profile">
          <Row>
            <Col md={6}>
              <div className="user-profile-header">
                <h5>
                  <Icon name="salesPro" /> External User
                </h5>
              </div>
              <div className="editable-table">
                <table className="table horizontal">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{id}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{email}</td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>{first_name || String.fromCharCode(8212)}</td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>{last_name || String.fromCharCode(8212)}</td>
                    </tr>
                    <tr>
                      <th>LinkedIn URL</th>
                      <td>
                        {linkedin_url ? (
                          <a
                            href={linkedin_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {linkedin_url}
                          </a>
                        ) : (
                          String.fromCharCode(8212)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{country || String.fromCharCode(8212)}</td>
                    </tr>
                    <tr>
                      <th>Location</th>
                      <td>
                        {metro_location?.name || String.fromCharCode(8212)}
                      </td>
                    </tr>
                    <tr>
                      <th>Gender</th>
                      <td>{gender || String.fromCharCode(8212)}</td>
                    </tr>
                    <tr>
                      <th>Ethnicity</th>
                      <td>{ethnicity || String.fromCharCode(8212)}</td>
                    </tr>
                    <tr>
                      <th>Education Level</th>
                      <td>{education_level || String.fromCharCode(8212)}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{phone || String.fromCharCode(8212)}</td>
                    </tr>
                    {referrer && (
                      <tr>
                        <th>Referred By</th>
                        <td>
                          <Link to={`/users/${referrer.id}`}>
                            {referrer.name}
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md={6}>
              {user_recon && (
                <UserRecon
                  userId={id}
                  userRecon={user_recon}
                  handleSuccess={handleUserReconUpdate}
                />
              )}
            </Col>
          </Row>
          <br />

          {workExperiences?.length > 0 && (
            <WorkExperiences workExperiences={workExperiences} userId={id} />
          )}

          {isFullAdmin && <JobPreferences id={id} />}
          <br />

          <div className="dates">
            <h4>Dates</h4>
            <div className="editable-table">
              <table className="table horizontal table-compressed">
                <tbody>
                  <tr>
                    <th>Created</th>
                    <td>{formatDateTime(created_at)}</td>
                  </tr>
                  <tr>
                    <th>Last Active</th>
                    <td>{formatDateTime(last_active_at)}</td>
                  </tr>
                  <tr>
                    <th>Last Sign In</th>
                    <td>{formatDateTime(current_sign_in_at)}</td>
                  </tr>
                  <tr>
                    <th>Sign In Count</th>
                    <td>{sign_in_count}</td>
                  </tr>
                  <tr>
                    <th>LinkedIn Scraping Completed</th>
                    <td>
                      {formatDateTime(
                        user_recon?.linkedin_scraping_completed_at
                      )}
                    </td>
                  </tr>
                  {discarded_at && (
                    <tr>
                      <th>Deleted</th>
                      <td>{formatDateTime(discarded_at)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <br />

          <div className="big-moves">
            <h4>Big Moves</h4>
            {status === "Unemployed" && (
              <div className="big-moves-content secondary">
                <div>
                  <h6>Grant unemployed access</h6>
                  {unemployed_access_until ? (
                    <p>
                      User has access until{" "}
                      <strong>{`${dayjs(unemployed_access_until).format(
                        "MMMM D, YYYY"
                      )}`}</strong>
                      .
                    </p>
                  ) : (
                    <p>User does not have temporary unemployed access set.</p>
                  )}
                  <p>
                    <small>
                      Clicking the button will grant access for 90 days.
                    </small>
                  </p>
                  <small>User will be automatically notified.</small>
                </div>
                <form onSubmit={handleUnemployedAccess}>
                  <button type="submit" className="btn btn-secondary">
                    {unemployed_access_until ? "Update access" : "Grant access"}
                  </button>
                </form>
              </div>
            )}
            {!discarded_at ? (
              <div className="big-moves-content danger">
                <div>
                  <h6>Delete this user</h6>
                  <p>User will be removed from the user site.</p>
                </div>
                <form onSubmit={handleDelete}>
                  <button type="submit" className="btn btn-danger">
                    Delete this user
                  </button>
                </form>
              </div>
            ) : (
              <div className="big-moves-content success">
                <div>
                  <h6>Restore this user</h6>
                  <p>User will be restored and will be able to log in.</p>
                </div>
                <form onSubmit={handleUndiscard}>
                  <button type="submit" className="btn btn-success">
                    Restore this user
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="company-sidebar">
          <Comments
            id={id}
            type="users"
            comments={comments}
            addComment={prependComment}
          />
        </div>
      </div>
    </>
  )
}

export default UserProfileRoute
