import React from "react"
import Icon from "components/Icon"

const EmptyTestimonials = () => (
  <div className="empty-section">
    <Icon name="user" />
    <p className="text-muted">No testimonials</p>
  </div>
)

export default EmptyTestimonials
