import Select from "components/forms/Select"

const RoleSelect = ({ field, ...props }) => {
  const options = [
    { id: "Admin", name: "Admin" },
    { id: "Analyst", name: "Analyst" },
    { id: "RepVue Internal", name: "RepVue Internal" },
  ]

  return <Select options={options} {...props} />
}

export default RoleSelect
