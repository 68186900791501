import apiCall from "utils/api"

export const getMetroLocations = params => {
  const options = { params }
  return apiCall("get", `metro_locations`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getMetroLocation = id => {
  return apiCall("get", `metro_locations/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createMetroLocation = params => {
  const options = { data: { metro_location: params } }
  return apiCall("post", `metro_locations`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateMetroLocation = (params, id) => {
  const options = { data: { metro_location: params } }
  return apiCall("patch", `metro_locations/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteMetroLocation = id => {
  return apiCall("delete", `metro_locations/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
