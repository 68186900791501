import { approveResponse, rejectResponse } from "api/responses"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"

import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import StatusBadge from "./StatusBadge"
import Icon from "components/Icon"

const Response = ({ companyId, response, handleResponseChange }) => {
  const handleApprove = id => {
    approveResponse(id).then(res => {
      handleResponseChange(res.data)
      toast.success("Approve successful")
    })
  }

  const handleReject = id => {
    if (window.confirm(`Are you sure you want to reject this response?`)) {
      rejectResponse(id).then(res => {
        handleResponseChange(res.data)
        toast.success("Reject successful")
      })
    }
  }

  const { id, body, status, created_at, employer } = response

  return (
    <>
      <div key={id} className="answer">
        <div className="answer-header">
          <div className="answer-author">
            <Icon name="employers" />
            <Link to={`/companies/${companyId}`} className="ms-2">
              {`${employer?.first_name} ${employer?.last_name}`}
            </Link>
            <span className="answer-date">{formatDateTime(created_at)}</span>
          </div>
          <StatusBadge status={status} />
        </div>
        <p>{body}</p>
        <div className="answer-footer">
          {status !== "Approved" && (
            <Button
              variant="success"
              size="sm"
              onClick={() => handleApprove(response.id)}
            >
              Approve
            </Button>
          )}
          {status !== "Rejected" && (
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleReject(response.id)}
            >
              Reject
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Response
