import React, { useState, useEffect } from "react"
import { getCompanyBenefits } from "api/companies/company_benefits"

import Modal from "components/Modal"
import Icon from "components/Icon"
import EditCompanyBenefit from "components/companies/company_benefits/Edit"
import "./Benefits.scss"

const CompanyBenefitsRoute = ({ id }) => {
  const [benefits, setBenefits] = useState([])
  const [company_benefits, setCompanyBenefits] = useState([])
  const [loading, setLoading] = useState(true)
  const [showEdit, setShowEdit] = useState(false)
  const [editingCompanyBenefit, setEditingCompanyBenefit] = useState({})
  const [editingBenefit, setEditingBenefit] = useState({})

  useEffect(() => {
    getCompanyBenefits(id)
      .then(response => {
        setBenefits(response.data.benefits)
        setCompanyBenefits(response.data.company_benefits)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [id])

  const handleEdit = (benefit_id, company_benefit_id) => {
    const benefit = benefits.find(benefit => benefit.id === benefit_id)
    setEditingBenefit(benefit)
    const company_benefit = company_benefits.find(
      company_benefit => company_benefit.id === company_benefit_id
    )
    setEditingCompanyBenefit(company_benefit)
    setShowEdit(true)
  }

  const closeModal = () => {
    setShowEdit(false)
  }

  const updateCompanyBenefit = company_benefit => {
    const newCompanyBenefits = [...company_benefits]
    const index = newCompanyBenefits.findIndex(
      cb => cb.id === company_benefit.id
    )
    if (index < 0) {
      // add new company_benefit
      newCompanyBenefits.push(company_benefit)
    } else {
      // update existing company_benefit
      newCompanyBenefits[index] = company_benefit
    }

    setCompanyBenefits(newCompanyBenefits)
  }

  if (loading) return <div />

  return (
    <div className="company-benefits">
      <div className="tab-header">
        <h3>Benefits</h3>
      </div>
      <table className="table table-hover editable-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th className="text-end">Value</th>
            <th>Unit</th>
            <th className="text-center">Active</th>
            <th>Comment</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
          {benefits &&
            benefits.map(benefit => {
              const { id, name, category, metric_type } = benefit
              const company_benefit = company_benefits.find(
                company_benefit => company_benefit.benefit_id === id
              )

              return (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{category}</td>
                  <td className="text-end">
                    <div className="form-value">
                      {company_benefit?.value || String.fromCharCode(8212)}
                    </div>
                    <div className="form-field">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                      />
                    </div>
                  </td>
                  <td>{metric_type || String.fromCharCode(8212)}</td>
                  <td className="active-boolean">
                    {company_benefit?.active ? (
                      <Icon name="check" />
                    ) : (
                      String.fromCharCode(8212)
                    )}
                  </td>
                  <td className="comment">
                    {company_benefit?.comment || String.fromCharCode(8212)}
                  </td>
                  <td className="actions">
                    <button
                      className="btn"
                      onClick={() => handleEdit(id, company_benefit?.id)}
                    >
                      <Icon name="edit" />
                    </button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <Modal title="Edit Benefit" show={showEdit} handleClose={closeModal}>
        {showEdit && (
          <EditCompanyBenefit
            companyId={id}
            benefit={editingBenefit}
            company_benefit={editingCompanyBenefit}
            handleSuccess={updateCompanyBenefit}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </div>
  )
}

export default CompanyBenefitsRoute
