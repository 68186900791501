import { htmlStripper } from "utils/format"

import Card from "react-bootstrap/Card"

import "./StepThree.scss"

const StepThree = ({ setStep, form, handleCreateJob }) => {
  const {
    title,
    featured,
    location_type,
    role,
    ote_min,
    ote_max,
    open_to_new,
    metro_locations,
    sales_division,
    years_of_experience,
    external_url,
  } = form[1]
  const { description } = form[2]

  const strippedDescription = description && htmlStripper(description)

  return (
    <>
      <h4>Review Job</h4>
      <div className="overview-wrapper">
        <Card className="p-3 mb-3">
          <div className="card-headline">
            <h5>Overview</h5>
            <button className="btn btn-link btn-sm" onClick={() => setStep(1)}>
              edit
            </button>
          </div>
          <table className="table table-compressed">
            <tbody>
              <tr>
                <th>Job Title</th>
                <td>{title}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>{role}</td>
              </tr>
              <tr>
                <th>Sales Division</th>
                <td>{sales_division || String.fromCharCode(8212)}</td>
              </tr>
              <tr>
                <th>Years of Experience</th>
                <td>{years_of_experience || String.fromCharCode(8212)}</td>
              </tr>
              <tr>
                <th>Location Type</th>
                <td>{location_type}</td>
              </tr>
              <tr>
                <th>Metro Location(s)</th>
                <td>
                  {metro_locations?.length > 0
                    ? metro_locations.map(location => (
                        <div
                          key={location.name}
                          className="badge rounded-pill bg-primary me-2"
                        >
                          {location.name}
                        </div>
                      ))
                    : String.fromCharCode(8212)}
                </td>
              </tr>
              <tr>
                <th>External URL</th>
                <td>{external_url || String.fromCharCode(8212)}</td>
              </tr>
              <tr>
                <th>OTE Min</th>
                <td>{ote_min || String.fromCharCode(8212)}</td>
              </tr>
              <tr>
                <th>OTE Max</th>
                <td>{ote_max || String.fromCharCode(8212)}</td>
              </tr>
              <tr>
                <th>Open to New</th>
                <td>{open_to_new ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Featured</th>
                <td>{featured ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
        </Card>
        <Card className="p-3 mb-3 form-card">
          <div className="card-headline">
            <h5>Description</h5>
            <button className="btn btn-link btn-sm" onClick={() => setStep(2)}>
              edit
            </button>
          </div>
          <div
            className="form-value text-field job-description"
            dangerouslySetInnerHTML={{
              __html: strippedDescription || "",
            }}
          ></div>
        </Card>
      </div>
      <button className="btn btn-primary" onClick={handleCreateJob}>
        Create Job
      </button>
    </>
  )
}

export default StepThree
