import apiCall from "utils/api"

export const getCompanyBenefits = id => {
  return apiCall("get", `companies/${id}/company_benefits`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateCompanyBenefit = (companyId, params) => {
  const options = { data: { company_benefit: params } }
  return apiCall("post", `companies/${companyId}/company_benefits`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
