import { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Button, Offcanvas, Tab, Tabs } from "react-bootstrap"
import {
  formatCurrency,
  formatDateTime,
  formatInputDateTime,
  formatLocal,
} from "utils/format"
import pluralize from "pluralize"
import {
  getRating,
  verifyRating,
  rejectRating,
  updateRating,
} from "api/ratings"
import toast from "react-hot-toast"

import DeleteModal from "./DeleteModal"
import GridCompany from "components/companies/GridCompany"
import EditableField from "components/ratings/EditableField"
import StatusBadge from "./StatusBadge"
import Input from "components/forms/Input"
import CompanySelect from "components/companies/Select"
import EnumSelect from "components/ratings/EnumSelect"
import Icon from "components/Icon"
import Flags from "components/shared/Flags"
import Comments from "components/comments"
import Audits from "components/shared/Audits"
import DetailSlideout from "components/shared/DetailSlideout"

import "./Detail.scss"

const RatingDetail = ({ id, show, handleClose, setUpdatedRating }) => {
  const [rating, setRating] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [activeTab, setActiveTab] = useState("details")
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})

  const { register, handleSubmit, control, reset } = useForm()

  const { currentAdmin } = useSelector(state => state.auth)
  const { access_level } = currentAdmin

  const defaultValues = useMemo(
    () => ({
      user_status: rating.user_status,
      user_role: rating.user_role,
      sales_division: rating.sales_division,
      base_compensation: rating.base_compensation,
      total_ote_earnings: rating.total_ote_earnings,
      highest_potential_earnings: rating.highest_potential_earnings,
      average_deal_size: rating.average_deal_size,
      effective_at: formatInputDateTime(rating.effective_at),
    }),
    [rating]
  )

  useEffect(() => {
    if (id) {
      setLoading(true)
      getRating(id)
        .then(response => {
          setRating(response.data)
          setLoading(false)
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }, [id])

  // since the company data is coming in async
  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const handleUpdate = data => {
    if (canEditEffectiveAt()) {
      const effectiveAt = formatLocal(data.effective_at)
      data.effective_at = effectiveAt
    } else {
      delete data.effective_at
    }

    updateRating(id, data)
      .then(response => {
        setRating(response.data)
        setUpdatedRating(response.data)
        toast.success("Rating updated!")
        setEditing(false)
        setErrors({})
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const toggleFlag = () => {
    const data = { flagged_for_question: !flagged_for_question }
    updateRating(id, data)
      .then(response => {
        setRating(response.data)
        setUpdatedRating(response.data)
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const submitVerify = () => {
    return verifyRating(id)
      .then(response => {
        setRating(response.data)
        setUpdatedRating(response.data)
      })
      .catch(() => {
        // setLoading(false)
      })
  }

  const submitReject = () => {
    return rejectRating(id)
      .then(response => {
        setRating(response.data)
        setUpdatedRating(response.data)
      })
      .catch(() => {
        // setLoading(false)
      })
  }

  const afterDiscard = rating => {
    setRating(rating)
    setUpdatedRating(rating)
  }

  const replaceUserRecon = userRecon => {
    const newRating = { ...rating }
    newRating.user.user_recon = userRecon
    setRating(newRating)
  }

  const prependComment = comment => {
    setRating({ ...rating, comments: [comment, ...comments] })
  }

  const cancelEditing = () => {
    setEditing(false)
    setErrors({})
  }

  const getErrors = name => {
    return errors[name] || []
  }

  const {
    company,
    business_unit,
    user,
    metric_ratings,
    country,
    office_status,
    sales_division,
    sales_team,
    seniority,
    tenure,
    user_role,
    user_status,
    status,
    base_compensation,
    base_compensation_currency,
    average_deal_size,
    average_deal_size_currency,
    highest_potential_earnings,
    highest_potential_earnings_currency,
    hitting_quota_percentage,
    total_ote_earnings,
    total_ote_earnings_currency,
    sales_cycle_length_days,
    account_coverage,
    direct_reports_team_size,
    key_performance_metrics,
    reps_supported,
    company_salary,
    equity,
    created_at,
    effective_at,
    discarded_at,
    discard_reason,
    flags,
    comments,
    audits,
    flagged_for_question,
    type,
  } = rating

  const isMedicalRating = type === "MedicalRating"

  if (loading) {
    return <div />
  }

  const unverifiedMod = status === "Unverified" ? 1 : 0
  const otherRatings = `${Math.max(user?.ratings_count - 1, 0)}`.concat(
    user?.unverified_ratings_count - unverifiedMod > 0
      ? ` (${user?.unverified_ratings_count - unverifiedMod} Unverified)`
      : ""
  )

  const canEditEffectiveAt = () =>
    status === "Verified" && user_status !== "Current Employee"

  const editingEffectiveAt = editing && canEditEffectiveAt()

  return (
    <>
      <DetailSlideout
        show={show}
        onHide={handleClose}
        onExited={() => {
          setEditing(false)
          setActiveTab("details")
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            {flagged_for_question && (
              <Icon name="questionFlag" className="question me-2" />
            )}
            Rating
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Tabs
            id="rating-tabs"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            className="mb-2"
            transition={false}
          >
            <Tab eventKey="details" title="Details">
              <div className="detail-body">
                <Flags flags={flags} />
                <section>
                  <div className="section-header">
                    <label>Status</label>
                    <StatusBadge status={status} />
                  </div>
                  {status === "Deleted" && (
                    <table>
                      <tbody>
                        <tr>
                          <td>Reason Deleted</td>
                          <td>{discard_reason}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="company" /> Company
                    </label>
                  </div>
                  <table className="company-table">
                    <tbody>
                      <tr>
                        <td>Company</td>
                        <td>
                          {!editing ? (
                            <div className="form-value">
                              <GridCompany company={company} />
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <CompanySelect
                                name="company_id"
                                control={control}
                                value={company}
                                error={getErrors("company")[0]}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      {business_unit && (
                        <tr>
                          <td>Business Unit</td>
                          <td>{business_unit.name}</td>
                        </tr>
                      )}
                      <tr></tr>
                      <tr>
                        <td>Ratings Count</td>
                        <td>{company?.ratings_count}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section>
                  <label>
                    <Icon name="user" /> User
                  </label>
                  <table className="user-table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <Link to={`/users/${user?.id}`}>{user?.name}</Link>
                            {user?.linkedin_url && (
                              <a
                                href={user?.linkedin_url}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Icon name="linkedin" />
                              </a>
                            )}
                            {user?.bounced_email && (
                              <span className="badge bg-danger ms-2">
                                Bounced Email
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Other Ratings</td>
                        <td>{otherRatings}</td>
                      </tr>
                      <EditableField
                        value={user?.user_recon?.linkedin_url}
                        userId={user?.id}
                        handleSuccess={replaceUserRecon}
                      />
                      <tr>
                        <td>Status</td>
                        <td>
                          {!editing ? (
                            <div className="form-value">{user_status}</div>
                          ) : (
                            <div className="form-field text-start">
                              <EnumSelect
                                ratingId={id}
                                field="user_status"
                                name="user_status"
                                control={control}
                                // error={getErrors("user_status")[0]}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Role</td>
                        <td>
                          {!editing ? (
                            <div className="form-value">{user_role}</div>
                          ) : (
                            <div className="form-field text-start">
                              <EnumSelect
                                ratingId={id}
                                field="user_role"
                                name="user_role"
                                control={control}
                                // error={getErrors("user_role")[0]}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {isMedicalRating ? "Product Category" : "Division"}
                        </td>
                        <td>
                          {!editing ? (
                            <div className="form-value">
                              {sales_division || String.fromCharCode(8212)}
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <EnumSelect
                                ratingId={id}
                                field="sales_division"
                                name="sales_division"
                                control={control}
                                // error={getErrors("sales_division")[0]}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Seniority</td>
                        <td>{seniority || String.fromCharCode(8212)}</td>
                      </tr>
                      <tr>
                        <td>Tenure</td>
                        <td>{tenure || String.fromCharCode(8212)}</td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>{country?.name || String.fromCharCode(8212)}</td>
                      </tr>
                      <tr>
                        <td>Sales Team</td>
                        <td>{sales_team || String.fromCharCode(8212)}</td>
                      </tr>
                      <tr>
                        <td>Office</td>
                        <td>{office_status}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <label>
                    <Icon name="ratings" /> Ratings
                  </label>
                  <table className="ratings-table">
                    <tbody>
                      {metric_ratings.map(metric_rating => (
                        <tr key={metric_rating.id}>
                          <td>{metric_rating.name}</td>
                          <td>{metric_rating.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>

                <section>
                  <label>
                    <Icon name="compensation" /> Compensation
                  </label>
                  <table className={`comp-table ${editing ? "editing" : ""}`}>
                    <thead>
                      <tr>
                        <th></th>
                        <th className="standard">
                          Company/Role
                          {company_salary &&
                            ` (${pluralize(
                              "rating",
                              company_salary?.ratings_count,
                              true
                            )})`}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Base</td>
                        <td className="standard">
                          {formatCurrency(
                            company_salary?.median_base_compensation,
                            base_compensation_currency
                          )}
                        </td>
                        <td>
                          {!editing ? (
                            <div className="form-value">
                              {formatCurrency(
                                base_compensation,
                                base_compensation_currency
                              )}
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <div className="input-group input-group-sm">
                                <span className="input-group-text">$</span>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="base_compensation"
                                  error={getErrors("base_compensation")[0]}
                                  register={register}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>OTE</td>
                        <td className="standard">
                          {formatCurrency(
                            company_salary?.median_ote,
                            total_ote_earnings_currency
                          )}
                        </td>
                        <td>
                          {!editing ? (
                            <div className="form-value">
                              {formatCurrency(
                                total_ote_earnings,
                                total_ote_earnings_currency
                              )}
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <div className="input-group input-group-sm">
                                <span className="input-group-text">$</span>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="total_ote_earnings"
                                  error={getErrors("total_ote_earnings")[0]}
                                  register={register}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Highest Potential</td>
                        <td className="standard">
                          {formatCurrency(
                            company_salary?.average_highest_potential_earnings,
                            highest_potential_earnings_currency
                          )}
                        </td>
                        <td>
                          {!editing ? (
                            <div className="form-value">
                              {formatCurrency(
                                highest_potential_earnings,
                                highest_potential_earnings_currency
                              )}
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <div className="input-group input-group-sm">
                                <span className="input-group-text">$</span>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="highest_potential_earnings"
                                  error={
                                    getErrors("highest_potential_earnings")[0]
                                  }
                                  register={register}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Average Deal Size</td>
                        <td className="standard">
                          {formatCurrency(
                            company_salary?.average_deal_size,
                            average_deal_size_currency
                          )}
                        </td>
                        <td>
                          {!editing ? (
                            <div className="form-value">
                              {formatCurrency(
                                average_deal_size,
                                average_deal_size_currency
                              )}
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <div className="input-group input-group-sm">
                                <span className="input-group-text">$</span>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="average_deal_size"
                                  error={getErrors("average_deal_size")[0]}
                                  register={register}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Hitting Quota</td>
                        <td className="standard">
                          {company_salary?.average_quota_attainment
                            ? `${company_salary?.average_quota_attainment}%`
                            : String.fromCharCode(8212)}
                        </td>
                        <td>
                          {hitting_quota_percentage
                            ? `${hitting_quota_percentage}%`
                            : String.fromCharCode(8212)}
                        </td>
                      </tr>
                      {sales_cycle_length_days && (
                        <tr>
                          <td>Sales Cycle Length</td>
                          <td colSpan="2">{sales_cycle_length_days} days</td>
                        </tr>
                      )}
                      {account_coverage && (
                        <tr>
                          <td>Account Coverage</td>
                          <td colSpan="2">{account_coverage} accounts</td>
                        </tr>
                      )}
                      {direct_reports_team_size && (
                        <tr>
                          <td>Direct Reports</td>
                          <td colSpan="2">{direct_reports_team_size}</td>
                        </tr>
                      )}
                      {key_performance_metrics && (
                        <tr>
                          <td>Key Performance Metric</td>
                          <td colSpan="2">{key_performance_metrics}</td>
                        </tr>
                      )}
                      {reps_supported && (
                        <tr>
                          <td>Reps Supported</td>
                          <td colSpan="2">{reps_supported} reps</td>
                        </tr>
                      )}
                      <tr>
                        <td>Equity</td>
                        <td colSpan="2">
                          {equity || String.fromCharCode(8212)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="info" /> About
                    </label>
                  </div>
                  <table className="company-table">
                    <tbody>
                      <tr>
                        <td>Create Date</td>
                        <td>{formatDateTime(created_at)}</td>
                      </tr>
                      <tr>
                        <td>Effective Date</td>
                        <td>
                          {!editingEffectiveAt ? (
                            <div className="form-value">
                              {formatDateTime(effective_at)}
                            </div>
                          ) : (
                            <div className="form-field text-start">
                              <Input
                                type="datetime-local"
                                name="effective_at"
                                error={getErrors("effective_at")[0]}
                                register={register}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      {discarded_at && (
                        <tr>
                          <td>Deleted Date</td>
                          <td>{formatDateTime(discarded_at)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </section>
              </div>
              {status !== "Deleted" && (
                <div className="detail-footer">
                  <div className="detail-footer-actions">
                    <>
                      {editing && (
                        <>
                          <Button
                            variant="success"
                            className="w-100"
                            onClick={handleSubmit(handleUpdate)}
                          >
                            Save
                          </Button>
                          <Button
                            variant="secondary"
                            className="w-100"
                            onClick={() => cancelEditing()}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {!editing && status !== "Verified" && (
                        <Button
                          variant="success"
                          className="w-100"
                          onClick={submitVerify}
                        >
                          Verify
                        </Button>
                      )}
                      {!editing && status !== "Unverified" && (
                        <Button
                          variant="warning"
                          className="w-100"
                          onClick={submitReject}
                        >
                          Unverify
                        </Button>
                      )}
                    </>
                    {!editing && access_level === "full" && (
                      <Button
                        variant="danger"
                        className="w-100"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        Delete
                      </Button>
                    )}
                    {!editing && (
                      <>
                        <Button
                          variant="primary"
                          className="w-100"
                          onClick={() => setEditing(true)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 question-flag"
                          onClick={() => toggleFlag()}
                        >
                          {flagged_for_question ? "Unflag" : "Flag"}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Tab>
            <Tab
              eventKey="comments"
              title={
                <>
                  Comments
                  {comments.length > 0 && (
                    <span className="badge rounded-pill bg-secondary">
                      {comments.length}
                    </span>
                  )}
                </>
              }
            >
              <Comments
                id={id}
                type="ratings"
                comments={comments}
                addComment={prependComment}
              />
            </Tab>
            <Tab
              eventKey="audits"
              title={
                <>
                  Audits
                  {audits.length > 0 && (
                    <span className="badge rounded-pill bg-secondary">
                      {audits.length}
                    </span>
                  )}
                </>
              }
            >
              <Audits audits={audits} />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </DetailSlideout>
      <DeleteModal
        rating={rating}
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        afterDiscard={afterDiscard}
      />
    </>
  )
}

export default RatingDetail
