import { useEffect, useState } from "react"
import { Offcanvas } from "react-bootstrap"
import { getCandidateProfile } from "api/candidate_profiles"
import pluralize from "pluralize"
import { formatCurrency, formatDateMonth } from "utils/format"

import Icon from "components/Icon"
import StatusBadge from "./StatusBadge"
import DetailSlideout from "components/shared/DetailSlideout"
import GridUser from "components/users/GridUser"
import GridCompany from "components/companies/GridCompany"

import "./Detail.scss"

const CandidateProfileDetail = ({ id, show, handleClose }) => {
  const [candidateProfile, setCandidateProfile] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (id) {
      setLoading(true)
      getCandidateProfile(id)
        .then(response => {
          setCandidateProfile(response.data)
          setLoading(false)
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }, [id])

  const {
    first_name,
    last_name,
    resume,
    summary,
    published,
    user,
    linkedin_url,
    search_status,
    roles,
    metro_locations,
    sales_experiences,
    years_closing_experience,
    years_selling_experience,
  } = candidateProfile

  if (loading) {
    return <div />
  }

  return (
    <>
      <DetailSlideout show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            {`${first_name} ${last_name}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <div className="detail-body">
            <section>
              <div className="section-header">
                <label>Status</label>
                <StatusBadge published={published} />
              </div>
            </section>
            <section>
              <label>
                <Icon name="user" /> User
              </label>
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      <GridUser user={user} />
                    </td>
                  </tr>
                  <tr>
                    <td>Linkedin URL</td>
                    <td>
                      {linkedin_url ? (
                        <a href={linkedin_url} rel="noreferrer" target="_blank">
                          {linkedin_url}
                        </a>
                      ) : (
                        String.fromCharCode(8212)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Experience</td>
                    <td>
                      {years_selling_experience
                        ? pluralize("year", years_selling_experience, true)
                        : String.fromCharCode(8212)}
                    </td>
                  </tr>
                  <tr>
                    <td>Closing Experience</td>
                    <td>
                      {years_closing_experience
                        ? pluralize("year", years_closing_experience, true)
                        : String.fromCharCode(8212)}
                    </td>
                  </tr>
                  <tr>
                    <td>Resume</td>
                    <td>
                      {resume ? (
                        <a href={resume?.url} target="_blank" rel="noreferrer">
                          {resume?.filename}
                        </a>
                      ) : (
                        String.fromCharCode(8212)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section>
              <div
                className="py-2"
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            </section>
            <section>
              <label>
                <Icon name="check" /> Preferences
              </label>
              <table>
                <tbody>
                  <tr>
                    <td>Search Status</td>
                    <td>{search_status || String.fromCharCode(8212)}</td>
                  </tr>
                  <tr>
                    <td>Roles</td>
                    <td>
                      {roles.map(role => role.jobs_alias).join(", ") ||
                        String.fromCharCode(8212)}
                    </td>
                  </tr>
                  <tr>
                    <td>Locations</td>
                    <td>
                      {metro_locations
                        .map(metro_location => metro_location.name)
                        .join(", ") || String.fromCharCode(8212)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section>
              <label>
                <Icon name="job" /> Work Experience
              </label>
              {sales_experiences.map(experience => {
                const {
                  id,
                  company,
                  company_name,
                  start_date,
                  end_date,
                  job_title,
                  role,
                  description,
                  selling_personas,
                  sales_cycle_length,
                  average_deal_size,
                } = experience

                const start = formatDateMonth(start_date)
                const end = end_date ? formatDateMonth(end_date) : "Present"

                return (
                  <div className="sales-experience" key={id}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Company</td>
                          <td>
                            {company ? (
                              <GridCompany company={company} />
                            ) : (
                              company_name
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Tenure</td>
                          <td>
                            {start} - {end}
                          </td>
                        </tr>
                        <tr>
                          <td>Job Title</td>
                          <td>{job_title}</td>
                        </tr>
                        <tr>
                          <td>Role</td>
                          <td>{role}</td>
                        </tr>
                        <tr>
                          <td>Buyer Persona(s)</td>
                          <td>
                            {selling_personas
                              .map(persona => persona.name)
                              .join(", ") || String.fromCharCode(8212)}
                          </td>
                        </tr>
                        <tr>
                          <td>Average Deal Size</td>
                          <td>
                            {formatCurrency(average_deal_size) ||
                              String.fromCharCode(8212)}
                          </td>
                        </tr>
                        <tr>
                          <td>Sales Cycle Length</td>
                          <td>{sales_cycle_length}</td>
                        </tr>
                        {description && (
                          <tr>
                            <td className="text-start" colSpan={2}>
                              <p className="experience-description">
                                {description}
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </section>
          </div>
        </Offcanvas.Body>
      </DetailSlideout>
    </>
  )
}

export default CandidateProfileDetail
