import React, { useState } from "react"
import { Navigate } from "react-router-dom"

import "./Tabs.scss"

const Tabs = ({ tabs, defaultTab, isFullAdmin }) => {
  const hash = window.location.hash.split("?")[0]

  const [activeTab, setActiveTab] = useState(hash || `#${defaultTab}`)

  const handleTabClick = hash => {
    setActiveTab(hash)
    window.location.hash = `${hash}`
  }

  if (!activeTab) {
    return <Navigate to={defaultTab} replace />
  }

  const activeComponent = tabs.find(tab => tab.hash === activeTab)

  return (
    <>
      <ul className="nav nav-tabs">
        {tabs.map(tab => {
          const { label, hash, limited } = tab
          if (!isFullAdmin && limited) return null
          return (
            <li key={hash} className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === hash && "active"}`}
                id={`${hash}-tab`}
                type="button"
                role="tab"
                onClick={() => handleTabClick(hash)}
              >
                {label}
              </button>
            </li>
          )
        })}
      </ul>

      {tabs.map(tab => {
        const { hash, component, limited } = tab

        if (!isFullAdmin && limited) return null
        return (
          <div
            key={hash}
            className={`tab-pane fade ${
              activeTab === hash ? "show active" : ""
            }`}
            id={hash}
            role="tabpanel"
            aria-labelledby={`${hash}-tab`}
          >
            {component === activeComponent.component && component}
          </div>
        )
      })}
    </>
  )
}

export default Tabs
