import Badge from "react-bootstrap/Badge"

function StatusBadge({ published }) {
  const variant = published ? "success" : "warning"

  return (
    <Badge bg={variant} className="candidate-profile-status-badge">
      {published ? "Published" : "Unpublished"}
    </Badge>
  )
}

export default StatusBadge
