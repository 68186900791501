import { useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col } from "react-bootstrap"
import dayjs from "dayjs"

import Input from "components/forms/Input"
import EnumSelect from "./EnumSelect"
import Select from "components/forms/Select"

import "./Form.scss"

const DataCustomerForm = ({ onSubmit, customer, errors }) => {
  const { handleSubmit, register, reset, control, watch } = useForm()

  const selectedType = watch("type")
  const selectedDeliverMethod = watch("deliver_method")

  const defaultValues = useMemo(
    () => ({
      name: customer?.name,
      signed_at: customer?.signed_at
        ? dayjs(customer?.signed_at).format("YYYY-MM-DD")
        : null,
      active: customer?.active,
      companies_limit: customer?.companies_limit,
      deliver_method: customer?.deliver_method,
      delivery_frequency: customer?.delivery_frequency,
      max_swaps: customer?.max_swaps,
      swaps_count: customer?.swaps_count,
      exclude_sr_mgmt: customer?.exclude_sr_mgmt,
      headcounts_active: customer?.headcounts_active,
      qualitative_data_active: customer?.qualitative_data_active,
      domain: customer?.domain,
      type: customer?.type,
      aws_access_key_id: customer?.aws_access_key_id,
      aws_secret_access_key: customer?.aws_secret_access_key,
      aws_region: customer?.aws_region,
      aws_bucket: customer?.aws_bucket,
      aws_folder: customer?.aws_folder,
    }),
    [customer]
  )

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <Input
          type="text"
          name="name"
          error={getErrors("name")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="type" className="form-label">
          Type
        </label>
        <Select
          name="type"
          field="type"
          control={control}
          options={[
            { id: "DataCustomers::Fixed", name: "Fixed" },
            { id: "DataCustomers::Unlimited", name: "Unlimited (AYCE)" },
          ]}
          error={getErrors("type")[0]}
          className={errors.type && "invalid-input"}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Domain
        </label>
        <Input
          type="text"
          name="domain"
          placeholder="example.com"
          error={getErrors("domain")[0]}
          register={register}
        />
      </div>
      <Row>
        <Col>
          <div className="mb-3">
            <label htmlFor="signed_at" className="form-label">
              Date Signed
            </label>
            <Input
              type="date"
              name="signed_at"
              error={getErrors("signed_at")[0]}
              register={register}
            />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <label htmlFor="companies_limit" className="form-label">
              Purchased Companies
            </label>
            <input
              id="companies_limit"
              type="number"
              placeholder={defaultValues.companies_limit}
              className="form-control"
              disabled={selectedType === "DataCustomers::Unlimited"}
              {...register("companies_limit")}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Max Swaps
            </label>
            <input
              id="max_swaps"
              type="number"
              placeholder={defaultValues.max_swaps}
              className="form-control"
              disabled={selectedType === "DataCustomers::Unlimited"}
              {...register("max_swaps")}
            />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <label htmlFor="swaps_count" className="form-label">
              Swaps Used
            </label>
            <input
              id="swaps_count"
              type="number"
              placeholder={defaultValues.swaps_count}
              className="form-control"
              disabled={selectedType === "DataCustomers::Unlimited"}
              {...register("swaps_count")}
            />
          </div>
        </Col>
      </Row>
      <div className="form-check mb-2">
        <input
          id="exclude_sr_mgmt"
          name="exclude_sr_mgmt"
          className="form-check-input"
          type="checkbox"
          {...register("exclude_sr_mgmt")}
          defaultChecked={defaultValues.exclude_sr_mgmt}
        />
        <label htmlFor="sr_management" className="form-check-label">
          Exclude Senior Management Ratings
        </label>
      </div>
      <div className="form-check mb-2">
        <input
          id="headcounts_active"
          name="headcounts_active"
          className="form-check-input"
          type="checkbox"
          {...register("headcounts_active")}
          defaultChecked={defaultValues.headcounts_active}
        />
        <label htmlFor="headcounts_active" className="form-check-label">
          Include Headcounts
        </label>
      </div>
      <div className="form-check mb-3">
        <input
          id="qualitative_data_active"
          name="qualitative_data_active"
          className="form-check-input"
          type="checkbox"
          {...register("qualitative_data_active")}
          defaultChecked={defaultValues.qualitative_data_active}
        />
        <label htmlFor="qualitative_data_active" className="form-check-label">
          Include Qualitative Data
        </label>
      </div>

      <hr />

      <Row>
        <Col>
          <div className="mb-3">
            <label htmlFor="deliver_method" className="form-label">
              Delivery Method
            </label>
            <EnumSelect
              name="deliver_method"
              field="deliver_method"
              control={control}
              error={getErrors("deliver_method")[0]}
              className={errors.deliver_method && "invalid-input"}
            />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <label htmlFor="delivery_frequency" className="form-label">
              Delivery Frequency
            </label>
            <EnumSelect
              name="delivery_frequency"
              field="delivery_frequency"
              control={control}
              error={getErrors("delivery_frequency")[0]}
              className={errors.delivery_frequency && "invalid-input"}
            />
          </div>
        </Col>
      </Row>

      <div
        className={`s3-info mb-3 ${
          selectedDeliverMethod === "S3" ? "d-block" : "d-none"
        }`}
      >
        <div className="mb-2">
          <label htmlFor="asw_access_key_id" className="form-label">
            <small>AWS Access Key ID</small>
          </label>
          <input
            id="asw_access_key_id"
            type="text"
            className="form-control form-control-sm font-monospace"
            {...register("aws_access_key_id")}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="asw_access_key_id" className="form-label">
            <small>AWS Secret Access Key</small>
          </label>
          <input
            id="aws_secret_access_key"
            type="text"
            className="form-control form-control-sm font-monospace"
            {...register("aws_secret_access_key")}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="aws_region" className="form-label">
            <small>AWS Region</small>
          </label>
          <input
            id="aws_region"
            type="text"
            className="form-control form-control-sm"
            {...register("aws_region")}
          />
        </div>
        <Row>
          <Col>
            <div className="mb-2">
              <label htmlFor="aws_bucket" className="form-label">
                <small>AWS Bucket</small>
              </label>
              <input
                id="aws_bucket"
                type="text"
                className="form-control form-control-sm"
                {...register("aws_bucket")}
              />
            </div>
          </Col>
          <Col>
            <div className="mb-2">
              <label htmlFor="aws_folder" className="form-label">
                <small>AWS Folder</small>
              </label>
              <input
                id="aws_folder"
                type="text"
                className="form-control form-control-sm"
                {...register("aws_folder")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="form-check mb-3">
        <input
          id="active"
          name="active"
          className="form-check-input"
          type="checkbox"
          {...register("active")}
          defaultChecked={defaultValues.active}
        />
        <label htmlFor="active" className="form-check-label">
          Active
        </label>
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default DataCustomerForm
