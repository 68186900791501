import Icon from "components/Icon"
import "./MetricCard.scss"

const MetricCard = ({ name, icon, all, month, day }) => (
  <div className="card">
    <div className="card-body">
      {all && (
        <div className="stat">
          <div className="stat-header strong">
            <Icon name={icon} />
            {name}
          </div>
          <h3>{all}</h3>
        </div>
      )}
      {month && (
        <div className="stat">
          <div className="stat-header">This Month</div>
          <h5>{month}</h5>
        </div>
      )}
      {day && (
        <div className="stat">
          <div className="stat-header font-sm">Today</div>
          <h5>{day}</h5>
        </div>
      )}
    </div>
  </div>
)

export default MetricCard
