import Badge from "react-bootstrap/Badge"

import "./SentimentBadge.scss"

function SentimentBadge({ sentiment }) {
  return (
    <Badge bg="light" className={`sentiment-badge ${sentiment.toLowerCase()}`}>
      {sentiment}
    </Badge>
  )
}

export default SentimentBadge
