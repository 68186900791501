import Input from "components/forms/Input"
import CompaniesSelect from "components/companies/Select"
import Button from "react-bootstrap/Button"

import "./NewCompany.scss"

const NewCompanyForm = ({ register, control }) => {
  return (
    <tr className="new-company-row-form">
      <td>
        <CompaniesSelect
          name="company_id"
          control={control}
          value={"customer?.company_id"}
        />
      </td>
      <td className="text-end">--</td>
      <td className="new-company-date">
        <Input
          type="date"
          name="added_at"
          label="Date Added"
          register={register}
          className="form-control-sm"
        />
      </td>
      <td className="new-company-date">
        <Input
          type="date"
          name="removed_at"
          label="Date Removed"
          register={register}
          className="form-control-sm"
        />
      </td>
      <td className="data-companies-actions">
        <Button type="submit" size="sm" className="add btn-success add-company">
          Add
        </Button>
      </td>
    </tr>
  )
}

export default NewCompanyForm
