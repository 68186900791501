import React from "react"

import Tabs from "components/Tabs"
import Header from "components/Header"
import Vendors from "routes/tech_stacks/Vendors"
import Categories from "routes/tech_stacks/Categories"

const TechStacksRoute = () => {
  const tabs = [
    {
      label: "Vendors",
      hash: "#vendors",
      component: <Vendors />,
    },
    {
      label: "Categories",
      hash: "#categories",
      component: <Categories />,
    },
  ]

  return (
    <>
      <Header title="Tech Stacks" icon="techStacks" />
      <div className="tech-stack content">
        <Tabs tabs={tabs} defaultTab="vendors" />
      </div>
    </>
  )
}

export default TechStacksRoute
