import { useState, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { updateUserRecons } from "api/users"
import toast from "react-hot-toast"
import Alert from "react-bootstrap/Alert"

import Input from "components/forms/Input"
import MetroLocationsSelect from "components/metro_locations/Select"
import Icon from "components/Icon"

import "./UserRecon.scss"

const UserRecon = ({ userId, userRecon, handleSuccess }) => {
  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState({})

  const { register, handleSubmit, control, reset } = useForm()

  const defaultValues = useMemo(
    () => ({
      first_name: userRecon?.first_name,
      last_name: userRecon?.last_name,
      linkedin_url: userRecon?.linkedin_url,
      company_name: userRecon?.company_name,
      job_title: userRecon?.job_title,
      company_email: userRecon?.company_email,
      metro_location: userRecon?.metro_location,
    }),
    [userRecon]
  )

  const {
    first_name,
    last_name,
    linkedin_url,
    company_name,
    job_title,
    linkedin_location,
    company_email,
    metro_location,
    open_to_work,
    years_of_experience,
  } = userRecon

  // since the company data is coming in async
  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const handleUpdate = data => {
    data.metro_location_id = data.metro_location
    delete data.metro_location

    if (data.linkedin_url === "") {
      data.linkedin_url = null
    }

    updateUserRecons(userId, data)
      .then(response => {
        handleSuccess(response.data)
        toast.success("Update successful")
        setEditing(false)
        setErrors({})
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const cancelEditing = () => {
    setEditing(false)
    setErrors({})
    reset({ ...defaultValues })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <div className="user-recon">
      <div className="user-recon-header">
        <h5>
          <Icon name="recon" /> Internal User
        </h5>
        {!editing ? (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setEditing(true)}
          >
            Edit
          </button>
        ) : (
          <div>
            <button
              className="btn btn-light btn-sm me-2"
              onClick={cancelEditing}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={handleSubmit(handleUpdate)}
            >
              Save
            </button>
          </div>
        )}
      </div>

      <Alert key="warning" variant="warning" className="mb-0">
        Only admins can see this info.
      </Alert>

      <form className="user-recon-form">
        <div className={`editable-table ${editing ? "editing" : ""}`}>
          <table className="table horizontal">
            <tbody>
              <tr>
                <th>First Name</th>
                <td>
                  <div className="form-value">
                    {first_name || String.fromCharCode(8212)}
                  </div>
                  <div className="form-field">
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="first_name"
                      error={getErrors("first_name")[0]}
                      register={register}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>
                  <div className="form-value">
                    {last_name || String.fromCharCode(8212)}
                  </div>
                  <div className="form-field">
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="last_name"
                      error={getErrors("last_name")[0]}
                      register={register}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>LinkedIn URL</th>
                <td>
                  <div className="form-value">
                    {linkedin_url ? (
                      <a href={linkedin_url} target="_blank" rel="noreferrer">
                        {linkedin_url}
                      </a>
                    ) : (
                      String.fromCharCode(8212)
                    )}
                  </div>
                  <div className="form-field">
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="linkedin_url"
                      error={getErrors("linkedin_url")[0]}
                      register={register}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Company Name</th>
                <td>
                  <div className="form-value">
                    {company_name || String.fromCharCode(8212)}
                  </div>
                  <div className="form-field">
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="company_name"
                      error={getErrors("company_name")[0]}
                      register={register}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>LinkedIn Location</th>
                <td>{linkedin_location || String.fromCharCode(8212)}</td>
              </tr>
              <tr>
                <th>Metro Location</th>
                <td>
                  <div className="form-value">
                    {metro_location?.name || String.fromCharCode(8212)}
                  </div>
                  <div className="form-field">
                    <MetroLocationsSelect
                      name="metro_location"
                      value={metro_location}
                      control={control}
                      multi={false}
                      error={getErrors("metro_location")[0]}
                    />
                    <div className="form-text">
                      Updated automatically when LinkedIn Location changes
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Job Title</th>
                <td>
                  <div className="form-value">
                    {job_title || String.fromCharCode(8212)}
                  </div>
                  <div className="form-field">
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="job_title"
                      error={getErrors("job_title")[0]}
                      register={register}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Company Email</th>
                <td className="active-boolean">
                  {!editing ? (
                    <div className="form-value">
                      {company_email ? (
                        <Icon name="check" />
                      ) : (
                        String.fromCharCode(8212)
                      )}
                    </div>
                  ) : (
                    <div className="form-field">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          defaultChecked={company_email}
                          {...register("company_email")}
                        />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>Years Experience</th>
                <td>
                  <div className="form-value">
                    {years_of_experience || String.fromCharCode(8212)}
                  </div>
                  <div className="form-field">
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="years_of_experience"
                      error={getErrors("years_of_experience")[0]}
                      register={register}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Open to Work</th>
                <td className="active-boolean">
                  {!editing ? (
                    <div className="form-value">
                      {open_to_work ? (
                        <Icon name="check" />
                      ) : (
                        String.fromCharCode(8212)
                      )}
                    </div>
                  ) : (
                    <div className="form-field">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          defaultChecked={open_to_work}
                          {...register("open_to_work")}
                        />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  )
}

export default UserRecon
