import CompaniesSelect from "components/companies/Select"
import Button from "react-bootstrap/Button"

import "./NewCompanyAssignment.scss"

const NewCompanyAssignmentForm = ({ control }) => {
  return (
    <tr className="new-company-assignment-row-form">
      <td>
        <CompaniesSelect
          name="company_id"
          control={control}
          value={"customer?.company_id"}
        />
      </td>
      <td className="text-end">--</td>
      <td className="assignment-actions">
        <Button type="submit" size="sm" className="add btn-success">
          Add
        </Button>
      </td>
    </tr>
  )
}

export default NewCompanyAssignmentForm
