import "./TypeBadge.scss"

function TypeBadge({ type, header = false }) {
  return (
    <span
      className={`${type?.toLowerCase()} ${
        header ? "header" : ""
      } badge location-type-badge`}
    >
      {type}
    </span>
  )
}

export default TypeBadge
