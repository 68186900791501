import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import Accordion from "react-bootstrap/Accordion"
import Icon from "components/Icon"
import { Link } from "react-router-dom"

import "./AccordionItem.scss"

const AccordionMenuItem = ({ item, eventKey }) => {
  const [isActive, setIsActive] = useState(false)

  let location = useLocation()

  const { title, icon, subItems } = item

  const currentPathMatch = subItems.filter(
    itemObject => location.pathname === itemObject.path
  )

  const containsCurrentPath = currentPathMatch.length > 0

  useEffect(() => {
    if (location) {
      setIsActive(containsCurrentPath)
    }
  }, [containsCurrentPath, location])

  return (
    <Accordion
      key={title}
      className="accordion"
      defaultActiveKey={containsCurrentPath && eventKey}
    >
      <Accordion.Item eventKey={eventKey}>
        <li
          className={`${isActive ? "accordion-item-active" : "accordion-item"}`}
        >
          <Accordion.Header onClick={() => setIsActive(!isActive)} as="div">
            <Icon name={icon} />
            <div className="label-wrapper">
              {title}
              <Icon name="chevronDown" className="accordion-chevron" />
            </div>
          </Accordion.Header>
        </li>
        <Accordion.Body>
          {subItems.length > 0 &&
            subItems.map(subItem => {
              if (subItem.routeRestricted) return null
              return (
                <li key={`sub${subItems.indexOf(subItem)}`}>
                  <Link
                    to={subItem.path}
                    className={
                      containsCurrentPath &&
                      subItem.path === currentPathMatch[0].path
                        ? "accordion-sub-item-active"
                        : "accordion-sub-item"
                    }
                  >
                    {subItem.title}
                  </Link>
                </li>
              )
            })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionMenuItem
