import Icon from "components/Icon"
import { formatDateTime } from "utils/format"

import styles from "./Comment.module.scss"

const Comment = ({ comment }) => (
  <div className={styles.comment}>
    <div className={styles.commentHeader}>
      <div className={styles.commentAdmin}>
        <Icon name="user" className={styles.adminIcon} />
        <span>{comment.admin.first_name}</span>
      </div>
      <div className={styles.commentDate}>
        {formatDateTime(comment.created_at)}
      </div>
    </div>
    <div className={styles.commentBody}>{comment.body}</div>
  </div>
)

export default Comment
