import Input from "components/forms/Input"
import Button from "react-bootstrap/Button"
import HeadcountsSelect from "./Select"

import "./Form.scss"

const NewHeadcountForm = ({ register, errors, control }) => {
  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <tr className="headcount-row-form">
      <td>
        <Input
          type="date"
          name="date"
          label="Date"
          register={register}
          className="form-control-sm"
        />
      </td>
      <td>
        <Input
          type="number"
          name="total"
          label="Total"
          register={register}
          className="form-control-sm text-end"
          error={getErrors("total")[0]}
        />
      </td>
      <td>
        <Input
          type="number"
          name="sales"
          label="Sales Org"
          register={register}
          className="form-control-sm text-end"
          error={getErrors("sales")[0]}
        />
      </td>
      <td>
        <Input
          type="number"
          name="open_sales_roles"
          label="Open Sales Roles"
          register={register}
          className="form-control-sm text-end"
          error={getErrors("open_sales_roles")[0]}
        />
      </td>
      <td>
        <HeadcountsSelect
          name="us_remote_roles"
          control={control}
          className="headcounts-select"
        />
      </td>
      <td>
        <Input
          type="number"
          name="new_hires"
          label="New Hires"
          register={register}
          className="form-control-sm text-end"
          error={getErrors("new_hires")[0]}
        />
      </td>
      <td>{String.fromCharCode(8212)}</td>
      <td>
        <Button type="submit" size="sm" className="add">
          Add
        </Button>
      </td>
    </tr>
  )
}

export default NewHeadcountForm
