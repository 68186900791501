export const customStyles = {
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#027597" : isFocused ? "#ebebeb" : undefined,
    ":active": {
      backgroundColor: "#027597",
      color: "#fff",
    },
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    borderColor: isFocused || isSelected ? "#81bacb" : "#ced4da",
    boxShadow:
      isFocused || isSelected
        ? "0 0 0 0.25rem rgb(2 117 151 / 25%)"
        : undefined,
    ":hover": {
      borderColor: "#81bacb;",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(2 117 151 / 25%)",
    },
  }),
}
