import apiCall from "utils/api"

export const getJobJobApplications = (id, params) => {
  const options = { params }
  return apiCall("get", `jobs/${id}/job_applications`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
