import { useState, useRef } from "react"
import { getUsers } from "api/users"
import { useNavigate } from "react-router-dom"
import { AgGridHyperlink, AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"

import SearchInput from "components/forms/SearchInput"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"

const UsersRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""
  const [search, setSearch] = useState(initialSearch)

  const [columnDefs] = useState([
    {
      field: "users.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      width: 120,
      cellRenderer: ({ data }) => data.id,
    },
    {
      field: "first_name",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.first_name || String.fromCharCode(8212),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.last_name || String.fromCharCode(8212),
    },
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
    },
    {
      field: "linkedin_url",
      headerName: "LinkedIn",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) => AgGridHyperlink(data.linkedin_url, true),
      externalURL: true,
    },
    {
      field: "ratings_count",
      headerName: "Ratings Count",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
    },
    {
      field: "countries.name",
      headerName: "Country",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.country || String.fromCharCode(8212),
    },
    {
      field: "users.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])

  const navigate = useNavigate()
  const gridRef = useRef()

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/users/${e.data.id}`)
  }

  return (
    <>
      <Header title="Users" icon="users" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search users..."
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getUsers}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
        />
      </div>
    </>
  )
}

export default UsersRoute
