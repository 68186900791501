import React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import store from "store"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

import "./index.scss"

const container = document.getElementById("root")
const root = createRoot(container)

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
  enabledReleaseStages: ["staging", "production"],
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
