import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getMetroLocation } from "api/metro_locations"

import Header from "components/Header"
import Tabs from "components/Tabs"
import TypeBadge from "components/metro_locations/TypeBadge"
import LocationOverviewRoute from "routes/locations/Overview"
import LocationCompaniesRoute from "routes/locations/Companies"
import LocationJobsRoute from "routes/locations/Jobs"

const MetroLocationRoute = () => {
  const { id } = useParams()

  const [location, setLocation] = useState({})

  const fetchLocation = id => {
    getMetroLocation(id)
      .then(response => {
        setLocation(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const CountTab = ({ label, count }) => {
    return (
      <div className="d-flex align-items-center gap-2">
        {label}
        <div className="badge rounded-pill bg-primary me-2">{count}</div>
      </div>
    )
  }

  const { name, type, active_jobs_count, company_locations_count } = location

  const tabs = [
    {
      label: "Overview",
      hash: "#overview",
      component: <LocationOverviewRoute location={location} />,
    },
    {
      label: <CountTab label="Active Jobs" count={active_jobs_count} />,
      hash: "#jobs",
      component: <LocationJobsRoute id={id} />,
    },
    {
      label: <CountTab label="Companies" count={company_locations_count} />,
      hash: "#companies",
      component: <LocationCompaniesRoute id={id} />,
    },
  ]

  useEffect(() => {
    fetchLocation(id)
  }, [id])

  return (
    <>
      <Header title={name} icon="location">
        <div className="header-badges">
          <TypeBadge type={type} header={true} />
        </div>
      </Header>
      <div className="user content">
        <Tabs tabs={tabs} defaultTab="overview" />
      </div>
    </>
  )
}

export default MetroLocationRoute
