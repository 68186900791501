import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { updateUserRecons } from "api/users"

import Input from "components/forms/Input"

import "./EditableField.scss"

const EditableField = ({ value, userId, handleSuccess }) => {
  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState({})

  const { register, handleSubmit, reset } = useForm()

  const defaultValues = useMemo(
    () => ({
      linkedin_url: value,
    }),
    [value]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const handleUpdate = data => {
    updateUserRecons(userId, data)
      .then(response => {
        handleSuccess(response.data)
        setEditing(false)
        toast.success("Update successful")
        setErrors({})
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const cancelEditing = () => {
    setEditing(false)
    setErrors({})
    reset({ ...defaultValues })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <tr className="editable-field">
      <td>
        <div className="editable-field-label">
          Internal LinkedIn URL
          {!editing ? (
            <button
              className="btn btn-primary btn-xs"
              onClick={() => setEditing(true)}
            >
              Edit
            </button>
          ) : (
            <div>
              <button
                className="btn btn-light me-2 btn-xs"
                onClick={cancelEditing}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </td>
      <td>
        {!editing ? (
          <div className="form-value">
            {value ? (
              <a href={value} target="_blank" rel="noreferrer">
                {value}
              </a>
            ) : (
              String.fromCharCode(8212)
            )}
          </div>
        ) : (
          <form className="d-flex align-items-center">
            <div className="col">
              <div className="form-field">
                <Input
                  className="form-control-sm"
                  type="text"
                  name="linkedin_url"
                  error={getErrors("linkedin_url")[0]}
                  register={register}
                />
              </div>
            </div>
            <div>
              {editing && (
                <button
                  type="button"
                  className="btn btn-success btn-xs"
                  onClick={handleSubmit(handleUpdate)}
                >
                  Save
                </button>
              )}
            </div>
          </form>
        )}
      </td>
    </tr>
  )
}

export default EditableField
