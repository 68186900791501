import { useState, useRef } from "react"
import { getCategories } from "api/tech_stacks/categories"

import { AgGridDateTime } from "utils/cellRenderers"
import AgGrid from "components/ag_grid/AgGrid"
import Button from "react-bootstrap/Button"
import Modal from "components/Modal"
import NewCategory from "components/tech_stacks/categories/New"

const Categories = () => {
  const gridRef = useRef()

  const [watchedValues, setWatchedValues] = useState([])
  const [showNew, setShowNew] = useState(false)
  // const [showEdit, seShowEdit] = useState(false)

  const closeModal = () => {
    setShowNew(false)
  }

  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      filter: "agTextColumnFilter",
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])

  return (
    <>
      <div className="tab-header">
        <h3>Categories</h3>
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Add Category
        </Button>
      </div>
      <div className="tech-grid-wrapper">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getCategories}
          sizeToFit={true}
          watchedValues={watchedValues}
        />
      </div>
      <Modal
        title={
          // showEdit ? "Edit Category" :
          "New Category"
        }
        show={
          // showEdit ||
          showNew
        }
        handleClose={closeModal}
      >
        {showNew && (
          <NewCategory
            handleSuccess={setWatchedValues}
            handleClose={() => setShowNew(false)}
          />
        )}
        {/* {showEdit && (
          <EditLocation
            location={editing}
            handleSuccess={replaceLocation}
            handleClose={() => seShowEdit(false)}
          />
        )} */}
      </Modal>
    </>
  )
}

export default Categories
