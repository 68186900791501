import { useState, useRef, useCallback } from "react"
import { getUserReviews } from "api/users"
import { AgGridDateTime } from "utils/cellRenderers"
import { Link } from "react-router-dom"

import AgGrid from "components/ag_grid/AgGrid"
import ReviewDetail from "components/reviews/Detail"
import StatusBadge from "components/reviews/StatusBadge"

const UserReviewsRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "reviews.id", // since companies also has an id col
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      width: 100,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data?.company?.name ? (
          <Link to={`/companies/${data?.company?.id}`}>
            {data?.company?.name}
          </Link>
        ) : (
          String.fromCharCode(8212)
        ),
    },
    {
      field: "body",
      headerName: "Body",
      filter: "agTextColumnFilter",
    },
    {
      field: "reviews.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Approved", "Pending", "Removed"],
      },
      sortable: false,
      cellRenderer: ({ data }) => {
        return <StatusBadge status={data.status} />
      },
      width: 150,
    },
    {
      field: "reviews.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])
  const [showDetail, setShowDetail] = useState(null)

  const hideDetail = () => {
    setShowDetail(undefined)
  }

  const retrieveUserReviews = useCallback(
    params => getUserReviews(id, params),
    [id]
  )

  const onCellClicked = e => {
    setShowDetail(e.data.id)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Reviews</h3>
      </div>
      <AgGrid
        onCellClicked={onCellClicked}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={retrieveUserReviews}
        search={""}
        sizeToFit={true}
      />
      <ReviewDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        refresh={() => setShowDetail(id)}
      />
    </>
  )
}

export default UserReviewsRoute
