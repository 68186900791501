import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { resetPassword } from "api/auth"
import "./SignIn.scss"

const ForgotPasswordRoute = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setError(false)

    const params = { admin: { email } }
    dispatch(resetPassword(params))
      .then(response => {
        if (response.status === 201) {
          setSuccess(true)
        }
      })
      .catch(response => {
        console.error(response)
        setError(true)
      })
  }

  return (
    <div className="sign-in">
      {error && <div className="alert alert-danger">Something went wrong</div>}

      {success ? (
        <div className="success">
          <h4>Password reset link sent</h4>

          <p className="mb-4">A password reset link was sent to:</p>

          <p className="mb-4">
            <strong>{email}</strong>
          </p>

          <p>
            If you don't see it in a couple of minutes, check your spam folder.
            It was sent from{" "}
            <a href="mailto:support@repvue.com">support@repvue.com</a>.
          </p>
          <p className="text-center mt-4">
            <Link to="/sign_in">Back to sign in</Link>
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h5>Forgot your password?</h5>

          <p>
            Enter your email address and we'll send you an email with a password
            reset link.
          </p>

          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              className="form-control"
              type="email"
              id="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <input
            className="btn btn-primary w-100"
            type="submit"
            value="Email password reset link"
          />

          <p className="text-center mt-4">
            <Link to="/sign_in">Cancel</Link>
          </p>
        </form>
      )}
    </div>
  )
}

export default ForgotPasswordRoute
