import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { getVendors } from "api/tech_stacks/vendors"
import { customStyles } from "components/forms/reactSelectStyles"

import "./Select.scss"

const TechStackSelect = ({ className, name, label, error, value, ...rest }) => {
  const [selected, setSelected] = useState({})

  useEffect(() => {
    setSelected(value)
  }, [value])

  const loadOptions = inputValue => {
    return getVendors({ q: inputValue }).then(response => {
      let optionsArray = []
      let responseArray = response.data.rows

      for (let i = 0; i < responseArray.length; i++) {
        if (responseArray[i].tech_stacks.length) {
          let option = {}
          option.label = responseArray[i].name

          option.options = responseArray[i].tech_stacks.map(stack => {
            return {
              name: stack.tech_stack_category.name,
              id: stack.id,
              cost: stack.cost,
            }
          })

          optionsArray.push(option)
        }
      }

      return optionsArray
    })
  }

  const formatOptionLabel = ({ name, cost }) => {
    return (
      <div className="tech-option">
        <div>{name}</div>
        {cost && <span className="badge rounded-pill bg-success">${cost}</span>}
      </div>
    )
  }

  return (
    <div className="mb-3">
      <Controller
        name={name}
        {...rest}
        render={({ field: { onChange } }) => {
          return (
            <AsyncSelect
              className={`${error ? "is-invalid" : ""}`}
              styles={customStyles}
              aria-label={label}
              defaultOptions
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              formatOptionLabel={formatOptionLabel}
              loadOptions={loadOptions}
              defaultValue={selected}
              value={selected}
              onChange={value => {
                onChange(value.id)
                setSelected(value)
              }}
            />
          )
        }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export default TechStackSelect
