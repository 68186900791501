import React from "react"

import Icon from "components/Icon"

const SearchInput = ({ value, placeholder, handleSearch }) => (
  <div className="form-group search-input">
    <div className="has-icon">
      <input
        placeholder={placeholder}
        type="search"
        className="form-control"
        onChange={e => handleSearch(e.target.value)}
        value={value}
      />
      <span className="icon">
        <Icon name="search" />
      </span>
    </div>
  </div>
)

export default SearchInput
