import dayjs from "dayjs"
import { useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"

import Input from "components/forms/Input"
import EnumSelect from "components/customers/EnumSelect"
import CompaniesSelect from "components/companies/Select"

const EmployerCustomerForm = ({ onSubmit, customer, errors }) => {
  const { handleSubmit, register, reset, control } = useForm()

  const defaultValues = useMemo(
    () => ({
      company_id: customer?.company_id,
      access_type: customer?.access_type,
      candidates_active: customer?.candidates_active,
      status: customer?.status,
      signed_at: customer?.signed_at
        ? dayjs(customer?.signed_at).format("YYYY-MM-DD")
        : null,
    }),
    [customer]
  )

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const showStatus = customer?.access_type !== "Free"
  const showStatusMessage = customer?.status === "Pending"

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {customer ? (
        <div className="mb-3">
          <label htmlFor="company" className="form-label">
            Company
          </label>
          <input
            type="text"
            name="company"
            label="Company"
            className="form-control"
            disabled
            value={customer.company.name}
          />
        </div>
      ) : (
        <div className={`mb-3 ${errors?.customer && "invalid-select"}`}>
          <label htmlFor="customer" className="form-label">
            Company
          </label>
          <div className={`form-field ${errors?.customer && "is-invalid"}`}>
            <CompaniesSelect
              name="company_id"
              control={control}
              value={customer?.company_id}
              error={getErrors("company_id")[0]}
            />
          </div>
        </div>
      )}
      <div className={`mb-3 ${errors.access_type && "invalid-select"}`}>
        <label htmlFor="access_type" className="form-label">
          Tier
        </label>
        <EnumSelect
          name="access_type"
          field="access_type"
          control={control}
          error={getErrors("access_type")[0]}
          className={errors.access_type && "invalid-input"}
        />
        {errors.access_type && (
          <p className="error-message">{errors.access_type}</p>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="candidates_active" className="form-label">
          Source Candidates Enabled
        </label>
        <div className="form-field">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              defaultChecked={customer?.candidates_active}
              {...register("candidates_active")}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="company" className="form-label">
          Date Signed
        </label>
        <Input
          type="date"
          name="signed_at"
          error={getErrors("signed_at")[0]}
          register={register}
        />
      </div>
      {showStatus && (
        <div className={`mb-3 ${errors.status && "invalid-select"}`}>
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <EnumSelect
            name="status"
            field="status"
            control={control}
            error={getErrors("status")[0]}
            className={errors.status && "invalid-input"}
          />
          {errors.access_type && (
            <p className="error-message">{errors.access_type}</p>
          )}
        </div>
      )}
      {showStatusMessage && (
        <div className="mb-3 alert alert-warning alert-sm">
          <strong>Approve or deny this customer in the detail view.</strong>
        </div>
      )}
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default EmployerCustomerForm
