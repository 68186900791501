import apiCall from "utils/api"

export const getCompanyTechStack = id => {
  return apiCall("get", `companies/${id}/tech_stacks`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCompanyTechStack = (companyId, params) => {
  const options = { data: { company_tech_stack: params } }
  return apiCall("post", `companies/${companyId}/tech_stacks`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteCompanyTechStack = id => {
  return apiCall("delete", `company_tech_stacks/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
