import React, { useState } from "react"
import toast from "react-hot-toast"
import { updateHeadcount } from "api/companies/headcounts"

import Form from "./Form"

import "./Form.scss"

const EditHeadcount = ({ headcount, handleSuccess, handleClose }) => {
  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    return updateHeadcount(headcount.id, data)
      .then(response => {
        toast.success("Update successful")
        handleSuccess(response.data)
        handleClose()
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  return (
    <>
      <Form onSubmit={onSubmit} headcount={headcount} errors={errors} />
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default EditHeadcount
