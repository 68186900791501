import { useState, useRef, useCallback } from "react"
import { getUserRatings } from "api/users"
import { AgGridDateTime } from "utils/cellRenderers"
import { Link } from "react-router-dom"

import AgGrid from "components/ag_grid/AgGrid"
import RatingDetail from "components/ratings/Detail"
import StatusBadge from "components/ratings/StatusBadge"

const UserRatingsRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "ratings.id",
      headerName: "ID",
      cellRenderer: ({ data }) => data.id,
      width: 100,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.company?.id}`}>{data.company?.name}</Link>
      ),
    },
    {
      field: "user_status",
      headerName: "User Status",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "user_role",
      headerName: "Role",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "tenure",
      headerName: "Tenure",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "sales_division",
      headerName: "Division",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "office_status",
      headerName: "Office Status",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "ratings.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "effective_at",
      headerName: "Effective Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.effective_at),
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: ({ data }) => <StatusBadge status={data.status} />,
    },
  ])
  const [showDetail, setShowDetail] = useState(null)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const hideDetail = () => {
    setShowDetail(null)
  }

  const retrieveUserRatings = useCallback(
    params => getUserRatings(id, params),
    [id]
  )

  const onCellClicked = e => {
    setShowDetail(e.data.id)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Ratings</h3>
      </div>
      <AgGrid
        onCellClicked={onCellClicked}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={retrieveUserRatings}
        search={""}
        sizeToFit={true}
        updatedRecord={updatedRecord}
      />
      <RatingDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setUpdatedRating={setUpdatedRecord}
      />
    </>
  )
}

export default UserRatingsRoute
