import { useState, useRef } from "react"
import { getVendors, deleteVendor } from "api/tech_stacks/vendors"
import { AgGridDateTime } from "utils/cellRenderers"
import { Link, useNavigate } from "react-router-dom"
import toast from "react-hot-toast"

import Button from "react-bootstrap/Button"
import Imgix from "react-imgix"
import Icon from "components/Icon"
import Modal from "components/Modal"
import NewVendor from "components/tech_stacks/vendors/New"
import EditVendor from "components/tech_stacks/vendors/Edit"
import AgGrid from "components/ag_grid/AgGrid"

import "./Vendors.scss"

const VendorsRoute = () => {
  const gridRef = useRef()
  const navigate = useNavigate()

  const [showNew, setShowNew] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [updatedRecord, setUpdatedRecord] = useState(null)
  const [watchedValue, setWatchedValue] = useState(null)
  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      pinned: "left",
      width: 120,
    },
    {
      field: "logo",
      headerName: "Logo",
      cellRenderer: ({ data }) =>
        data.logo ? (
          <Imgix
            src={`${process.env.REACT_APP_IMAGE_URL}/${data.logo}`}
            alt={data.name}
            width={30}
            height={30}
          />
        ) : (
          String.fromCharCode(8212)
        ),
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      filter: "agTextColumnFilter",
    },
    {
      field: "companies_count",
      headerName: "Assigned",
      filter: "agNumberColumnFilter",
    },
    {
      field: "tech_stacks",
      headerName: "Categories",
      cellClass: "auto-height",
      autoHeight: true,
      cellRenderer: ({ data }) => {
        const { tech_stacks } = data
        if (tech_stacks.length < 1) return String.fromCharCode(8212)
        return (
          <div className="tech-stacks">
            {tech_stacks.map(tech_stack => (
              <div className="tech-stack-category" key={tech_stack.id}>
                <span>{tech_stack.tech_stack_category.name}</span>
                {tech_stack.cost && (
                  <span className="badge rounded-pill bg-success">
                    ${tech_stack.cost}
                  </span>
                )}
              </div>
            ))}
          </div>
        )
      },
      sortable: false,
      width: 250,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return data.company ? (
          <Link to={`/companies/${data.company.id}`}>{data.company?.name}</Link>
        ) : (
          String.fromCharCode(8212)
        )
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "actions",
      headerName: "Edit",
      headerClass: "ag-center-aligned",
      cellClass: "ag-center-aligned-cell",
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: ({ data }) => {
        return (
          <>
            <button
              className="btn me-1 p-1 employer-util"
              onClick={() => handleEdit(data)}
            >
              <Icon name="edit" />
            </button>
            {data?.companies_count === 0 && (
              <button
                className="btn me-1 p1 employer-util"
                onClick={() => handleDelete(data)}
              >
                <Icon name="delete" />
              </button>
            )}
          </>
        )
      },
      width: 140,
    },
  ])

  const handleEdit = vendor => {
    setShowEdit(vendor)
  }

  const handleDelete = data => {
    if (window.confirm(`Are you sure you want to delete this vendor?`)) {
      deleteVendor(data.id).then(response => {
        setWatchedValue(data)
        toast.success("Delete successful")
      })
    }
  }

  const handleEditSuccess = vendor => {
    setUpdatedRecord(vendor)
    closeModal()
  }

  const handleNewSuccess = vendor => {
    setWatchedValue(vendor)
    closeModal()
  }

  const closeModal = () => {
    setShowNew(false)
    setShowEdit(null)
  }

  const onCellClicked = e => {
    navigate(`/tech_stacks/${e.data.tech_stacks[0].id}`)
  }

  return (
    <>
      <div className="add-vendor-wrapper">
        <h3>Vendors</h3>
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Create Vendor
        </Button>
      </div>
      <div className="tech-grid-wrapper editable-table">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getVendors}
          sizeToFit={true}
          updatedRecord={updatedRecord}
          watchedValues={watchedValue}
          onCellClicked={onCellClicked}
        />
      </div>
      <Modal
        title={showEdit ? "Edit Vendor" : "New Vendor"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewVendor
            handleSuccess={handleNewSuccess}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditVendor
            vendor={showEdit}
            handleSuccess={handleEditSuccess}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default VendorsRoute
