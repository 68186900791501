import { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { inviteAdmin } from "api/admins"
import { Row, Col } from "react-bootstrap"

import Input from "components/forms/Input"
import { Controller } from "react-hook-form"
import AccessSelect from "./AccessSelect"

const InviteAdminForm = ({ handleSuccess }) => {
  const [errors, setErrors] = useState({})
  const { handleSubmit, register, control } = useForm()

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const onSubmit = data => {
    return inviteAdmin(data)
      .then(() => {
        toast.success("Invitation sent!")
        handleSuccess(data)
      })
      .catch(response => {
        setErrors(response?.data?.errors)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <Col>
          <label htmlFor="first_name" className="form-label">
            First Name
          </label>
          <Input
            type="text"
            name="first_name"
            label="First Name"
            error={getErrors("first_name")[0]}
            register={register}
          />
        </Col>
        <Col>
          <label htmlFor="last_name" className="form-label">
            Last Name
          </label>
          <Input
            type="text"
            name="last_name"
            label="Last Name"
            error={getErrors("last_name")[0]}
            register={register}
          />
        </Col>
      </Row>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <Input
          type="text"
          name="email"
          label="Email"
          error={getErrors("email")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="access_level" className="form-label">
          Access Level
        </label>
        <Controller
          name="access_level"
          control={control}
          render={({ field: { onChange } }) => (
            <AccessSelect defaultValue={"limited"} onChange={onChange} />
          )}
        />
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default InviteAdminForm
