import { useState, useEffect, useMemo } from "react"
import { getJobApplication, updateJobApplication } from "api/job_applications"
import { formatDateTime } from "utils/format"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"

import { Button, Offcanvas } from "react-bootstrap"
import { Link } from "react-router-dom"
import DetailSlideout from "components/shared/DetailSlideout"
import StatusBadge from "components/job_applications/StatusBadge"
import Icon from "components/Icon"
import Input from "components/forms/Input"

import "./Detail.scss"

const ApplicationDetail = ({ id, show, handleClose, setGridRecord }) => {
  const [application, setApplication] = useState({})
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState({})

  const { register, handleSubmit, reset } = useForm()

  const defaultValues = useMemo(
    () => ({
      influenced_hire: application?.influenced_hire,
      job_match_score: application?.job_match_score,
      job_match_score_notes: application?.job_match_score_notes,
    }),
    [application]
  )

  const {
    company,
    influenced_hire,
    job,
    job_match_score,
    job_match_score_notes,
    outreached,
    resume,
    stage,
    status,
    user,
    job_application_histories,
    created_at,
    updated_at,
  } = application

  const handleUpdate = data => {
    updateJobApplication(id, data)
      .then(response => {
        setApplication(response.data)
        setGridRecord(response.data)
        toast.success("Rating updated!")
        setEditing(false)
        setErrors({})
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      getJobApplication(id)
        .then(response => {
          setApplication(response.data)
          setLoading(false)
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }, [id])

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  if (loading) {
    return <div />
  }

  return (
    <DetailSlideout show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="d-flex align-items-center">
          Job Application
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column">
        <div className="detail-body">
          <section>
            <div className="section-header">
              <label>Status</label>
              <StatusBadge status={status} />
            </div>
            <table>
              <tbody>
                <tr>
                  <td>Current Stage</td>
                  <td>{stage || String.fromCharCode(8212)}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <section>
            <div className="section-header">
              <label>
                <Icon name="job" /> Job
              </label>
              <Link to={`/jobs/${job.id}`} onClick={handleClose}>
                {job.title}
              </Link>
            </div>
          </section>
          <section>
            <div className="section-header">
              <label>
                <Icon name="user" /> User
              </label>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    <Link to={`/users/${user.id}`}>{user.name}</Link>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>LinkedIn</td>
                  <td>
                    <a href={user.linkedin_url}>{user.linkedin_url}</a>
                  </td>
                </tr>
                {resume && (
                  <tr>
                    <td>Resume</td>
                    <td>
                      <a href={resume?.url} target="_blank" rel="noreferrer">
                        {resume?.filename}
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
          <section>
            <div className="section-header">
              <label>
                <Icon name="company" /> Company
              </label>
              <Link to={`/companies/${company.id}`}>{company.name}</Link>
            </div>
          </section>
          <section>
            <div className="section-header">
              <label>
                <Icon name="microscope" /> Internal
              </label>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>Outreached</td>
                  <td>
                    {outreached ? (
                      <Icon name="outreached" />
                    ) : (
                      String.fromCharCode(8212)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Influenced Hire</td>
                  <td>
                    {editing ? (
                      <div className="form-check form-switch d-flex align-items-center justify-content-end">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          defaultChecked={influenced_hire}
                          {...register("influenced_hire")}
                        />
                      </div>
                    ) : influenced_hire === true ? (
                      <Icon name="check" />
                    ) : influenced_hire === false ? (
                      "No"
                    ) : (
                      String.fromCharCode(8212)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Job Match Score</td>
                  <td>
                    {editing ? (
                      <Input
                        type="number"
                        name="job_match_score"
                        placeholder={defaultValues.job_match_score}
                        className="form-control w-25 ms-auto"
                        register={register}
                        error={getErrors("job_match_score")[0]}
                      />
                    ) : (
                      job_match_score || String.fromCharCode(8212)
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="text-start align-top" colSpan="2">
                    Job Match Score Notes
                  </td>
                </tr>
                <tr>
                  <td className="text-start" colSpan="2">
                    <div className="my-2">
                      {editing ? (
                        <>
                          <textarea
                            placeholder={defaultValues.job_match_score_notes}
                            className={`form-control ${
                              getErrors("job_match_score_notes")[0]
                                ? "form-error"
                                : ""
                            }`}
                            rows="4"
                            {...register("job_match_score_notes")}
                          />
                          {getErrors("job_match_score_notes")[0] && (
                            <div className="error-message">
                              {getErrors("job_match_score_notes")[0]}
                            </div>
                          )}
                        </>
                      ) : (
                        <p className="px-2">{job_match_score_notes}</p> ||
                        String.fromCharCode(8212)
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {job_application_histories.length > 0 && (
            <section className="job-application-history">
              <div className="section-header">
                <label>
                  <Icon name="history" /> History
                </label>
              </div>
              <table>
                <tbody>
                  {job_application_histories.map(history => (
                    <tr key={history.id}>
                      <td>{history.stage}</td>
                      <td>{formatDateTime(history.created_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          )}
          <section>
            <div className="section-header">
              <label>
                <Icon name="info" /> About
              </label>
            </div>
            <table className="company-table">
              <tbody>
                <tr>
                  <td>Created</td>
                  <td>{formatDateTime(created_at)}</td>
                </tr>
                <tr>
                  <td>Updated</td>
                  <td>{formatDateTime(updated_at)}</td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <div className="application-detail-footer">
          {editing ? (
            <>
              <Button
                variant="success"
                className="w-100"
                onClick={handleSubmit(handleUpdate)}
              >
                Save
              </Button>
              <Button
                variant="secondary"
                className="w-100"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="primary"
              className="w-100"
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          )}
        </div>
      </Offcanvas.Body>
    </DetailSlideout>
  )
}

export default ApplicationDetail
