import { useState } from "react"
import { deleteCompanyBusinessUnit } from "api/companies/business_unit"
import toast from "react-hot-toast"

import Button from "react-bootstrap/Button"
import BusinessUnitForm from "./Form"
import Modal from "components/Modal"
import Icon from "components/Icon"

import "./BusinessUnits.scss"

const BusinessUnitsTable = ({ business_units, company, setCompany }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [showNew, setShowNew] = useState(false)

  const closeModal = () => {
    setShowEdit(false)
    setShowNew(false)
  }

  const handleDelete = businessUnit => {
    if (
      window.confirm(`Are you sure you want to delete ${businessUnit.name}?`)
    ) {
      deleteCompanyBusinessUnit(company.id, businessUnit.id).then(() => {
        setCompany({
          ...company,
          business_units: company.business_units.filter(
            unit => unit.id !== businessUnit.id
          ),
        })
        toast.success("Delete successful")
      })
    }
  }

  return (
    <>
      <div className="business-units">
        <div className="business-units-header">
          <h4>Business Units </h4>
          <Button variant="primary" size="sm" onClick={() => setShowNew(true)}>
            Add
          </Button>
        </div>
        {business_units.length > 0 && (
          <div className="editable-table">
            <table className="table">
              <tbody>
                {business_units.map(unit => (
                  <tr key={unit.id}>
                    <td className="name">{unit.name}</td>
                    <td>{unit.description}</td>
                    <td className="actions">
                      <button className="btn" onClick={() => setShowEdit(unit)}>
                        <Icon name="edit" />
                      </button>
                      <button
                        className="btn"
                        onClick={() => handleDelete(unit)}
                      >
                        <Icon name="delete" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Modal
        title={showEdit ? "Edit Business Unit" : "New Business Unit"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        <BusinessUnitForm
          business_unit={showEdit}
          company={company}
          handleSuccess={(updatedBusinessUnit, edit = false) => {
            const newCompanyBusinessUnits = [...company.business_units]
            if (edit) {
              const replaceIndex = newCompanyBusinessUnits.findIndex(
                unit => unit.id === updatedBusinessUnit.id
              )
              newCompanyBusinessUnits[replaceIndex] = updatedBusinessUnit
            } else {
              newCompanyBusinessUnits.push(updatedBusinessUnit)
            }
            setCompany({
              ...company,
              business_units: newCompanyBusinessUnits,
            })
          }}
          handleClose={closeModal}
        />
      </Modal>
    </>
  )
}

export default BusinessUnitsTable
