import { useState, useRef } from "react"
import { getEmployers, deleteEmployer, grantUserAccess } from "api/employers"
import { Link } from "react-router-dom"
import { setQueryParams, getInitialParams } from "utils/query"
import { AgGridDateTime } from "utils/cellRenderers"
import toast from "react-hot-toast"
import { Badge, Button } from "react-bootstrap"

import SearchInput from "components/forms/SearchInput"
import Icon from "components/Icon"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"
import Modal from "components/Modal"
import NewEmployer from "components/employers/New"
import EditEmployer from "components/employers/Edit"

import "./Employers.scss"

const EmployersRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const inviteDataUser = id => {
    if (
      window.confirm(
        "Are you sure you want to invite this employer to create a user account?"
      )
    ) {
      grantUserAccess(id)
        .then(res => {
          toast.success("User invited")
          console.log(res.data)
          setUpdatedRecord(res.data)
        })
        .catch(error => console.error(error))
    }
  }

  const [columnDefs] = useState([
    {
      field: "employers.id",
      headerName: "ID",
      hide: true,
      filter: "agNumberColumnFilter",
      width: 100,
      cellRenderer: ({ data }) => data.id || String.fromCharCode(8212),
    },
    {
      field: "first_name",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.first_name || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.last_name || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      width: 335,
      cellRenderer: ({ data }) =>
        data.discarded_at ? (
          <>
            {data.email}
            <Badge bg="danger" className="deleted-badge">
              Deleted
            </Badge>
          </>
        ) : (
          <span className="d-flex align-items-center justify-content-between gap-1">
            <span>{data.email}</span>
            {data.pending && (
              <Badge pill bg="info" text="dark">
                Invite Pending
              </Badge>
            )}
          </span>
        ),
    },
    {
      field: "companies.name",
      headerName: "Company",
      width: 130,
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.customer.company.id}`}>
          {data.customer.company.name}
        </Link>
      ),
    },
    {
      field: "employers.title",
      headerName: "Title",
      width: 130,
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.title || String.fromCharCode(8212),
    },
    {
      field: "employers.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "employers.last_active_at",
      headerName: "Last Active",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.last_active_at),
    },
    {
      field: "user",
      headerName: "User Account",
      filter: false,
      suppressMenu: true,
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      width: 160,
      cellRenderer: ({ data }) => (
        <>
          {data.user ? (
            <Link to={`/users/${data.user.id}`}>
              <Icon name="user" />
            </Link>
          ) : (
            <Button
              className="d-block"
              size="sm"
              variant="secondary"
              onClick={() => inviteDataUser(data?.id)}
            >
              Invite
            </Button>
          )}
        </>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      filter: false,
      suppressMenu: true,
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      width: 120,
      cellRenderer: ({ data }) => (
        <>
          <Button
            variant="link"
            className="employer-util"
            onClick={() => handleEdit(data)}
          >
            <Icon name="edit" />
          </Button>
          <Button
            variant="link"
            className="employer-util"
            onClick={() => handleDelete(data)}
          >
            <Icon name="delete" />
          </Button>
        </>
      ),
    },
  ])
  const [showNew, setShowNew] = useState(false)
  const [showEdit, setShowEdit] = useState(null)

  const handleEdit = employer => {
    setShowEdit(employer)
  }

  const handleDelete = data => {
    if (window.confirm(`Are you sure you want to delete this employer?`)) {
      deleteEmployer(data.id)
        .then(res => {
          toast.success("Delete successful")
          setUpdatedRecord(res.data)
        })
        .catch(error => console.error(error))
    }
  }

  const gridRef = useRef()

  const handleSearch = q => {
    setSearch(q)
  }

  const handleSuccess = updatedRecord => {
    setUpdatedRecord(updatedRecord)
    closeModal()
  }

  const closeModal = () => {
    setShowNew(false)
    setShowEdit(null)
  }

  return (
    <>
      <Header title="Employers" icon="employers" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search employers..."
          handleSearch={handleSearch}
        />
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Create Employer
        </Button>
      </Header>
      <div className="grid-wrapper editable-table">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getEmployers}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
          sizeToFit
        />
      </div>
      <Modal
        title={showNew ? "Create Employer" : "Edit Employer"}
        show={showNew || showEdit}
        handleClose={closeModal}
      >
        {showNew && (
          <NewEmployer
            handleSuccess={handleSuccess}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditEmployer
            handleSuccess={handleSuccess}
            employer={showEdit}
            handleClose={closeModal}
          />
        )}
      </Modal>
    </>
  )
}

export default EmployersRoute
