import { useState } from "react"
import { Form, Button } from "react-bootstrap"
import Modal from "components/Modal"

const RemoveModal = ({ data, show, handleClose, handleSubmit, review }) => {
  const [removeReason, setRemoveReason] = useState("policy_violation") // first option is default

  const onSubmit = e => {
    e.preventDefault()
    handleSubmit({ remove_reason: removeReason })
  }

  const buttonText =
    removeReason === "policy_violation"
      ? "Remove Review & Notify User"
      : "Remove Review"

  return (
    <Modal title={"Remove review"} handleClose={handleClose} show={show}>
      <p>
        Are you sure you want to ignore this review from user{" "}
        <strong>{data?.user?.name}</strong>?
      </p>

      <Form onSubmit={onSubmit}>
        <h6>Select a reason for removing this review.</h6>
        <Form.Group className="mb-3">
          <Form.Select
            aria-label="Select notification"
            name="ignoreReason"
            onChange={e => setRemoveReason(e.target.value)}
          >
            <option value="policy_violation">Policy Violation</option>
            <option value="not_relevant">Not Relevant</option>
            <option value="insufficient_content">Insufficient Content</option>
          </Form.Select>
        </Form.Group>
        <Button variant="danger" type="submit" className="w-100 mb-2">
          {buttonText}
        </Button>
        <div className="text-center">
          <Button onClick={handleClose} variant="link" className="btn-sm">
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default RemoveModal
