import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import IndustrySelect from "components/industries/Select"
import Input from "components/forms/Input"

const TagForm = ({ onSubmit, tag, errors }) => {
  const { handleSubmit, register, reset, control } = useForm({ tag })

  const defaultValues = useMemo(
    () => ({
      name: tag?.name,
      industry_id: tag?.industry?.id,
    }),
    [tag]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Tag
        </label>
        <Input
          type="text"
          name="name"
          label="Category Name"
          error={getErrors("name")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="industry_id" className="form-label">
          Industry (optional)
        </label>
        <IndustrySelect
          name="industry_id"
          control={control}
          error={getErrors("industry_id")[0]}
        />
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default TagForm
