import apiCall from "utils/api"

export const getUsers = params => {
  const options = { params }
  return apiCall("get", `users`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getN2SUsers = params => {
  const options = { params }
  return apiCall("get", `users/new_to_sales`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const approveN2SUser = id => {
  return apiCall("post", `users/${id}/approve_new_to_sales`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getDeletedUsers = params => {
  const options = { params }
  return apiCall("get", `users/deleted`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getUser = id => {
  return apiCall("get", `users/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getUserRatings = (id, params) => {
  const options = { params }
  return apiCall("get", `users/${id}/ratings`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getUserReviews = (id, params) => {
  const options = { params }
  return apiCall("get", `users/${id}/reviews`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getUserRankings = id => {
  return apiCall("get", `users/${id}/rankings`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteUser = id => {
  return apiCall("delete", `users/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const undiscardUser = id => {
  return apiCall("put", `users/${id}/undiscard`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateUserRecons = (id, params) => {
  const options = { data: { user_recon: params } }
  return apiCall("patch", `users/${id}/user_recon`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const grantUnemployedAccess = id => {
  return apiCall("patch", `users/${id}/grant_unemployed_access`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getUserAudits = id => {
  return apiCall("get", `users/${id}/audits`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
