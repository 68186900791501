import { useState, useRef } from "react"
import { getReferences } from "api/references"
import { AgGridDateTime } from "utils/cellRenderers"

import { Link } from "react-router-dom"
import Modal from "components/Modal"
import GridUser from "components/users/GridUser"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"
import AddProviderModal from "components/references/AddProviderModal"

const ReferencesRoute = () => {
  const gridRef = useRef()

  const [addReferenceProvider, setAddReferenceProvider] = useState(false)
  const [referenceToUpdate, setReferenceToUpdate] = useState(null)
  const [columnDefs] = useState([
    {
      field: '"references".id', // quotes because references is a reserved word in rails
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      width: 100,
    },
    {
      field: '"references".created_at', // quotes because references is a reserved word in rails
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "requesters.name",
      headerName: "Requester",
      filter: false,
      cellRenderer: ({ data }) => <GridUser user={data.requester} />,
      sortable: false,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/companies/${data.company.id}`}>{data.company.name}</Link>
      ),
      width: 200,
    },
    {
      field: "user_role",
      headerName: "Role",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.user_role || String.fromCharCode(8212),
      width: 240,
    },
    {
      field: "providers.name",
      headerName: "Provider",
      filter: false,
      cellRenderer: ({ data }) => <GridUser user={data.provider} />,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellClass: "ag-center-aligned",
      headerClass: "ag-center-aligned",
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: ({ data }) => (
        <>
          {data.provider ? (
            String.fromCharCode(8212)
          ) : (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setAddReferenceProvider(data)}
            >
              Add Provider
            </button>
          )}
        </>
      ),
    },
  ])

  const closeModal = () => {
    setAddReferenceProvider(false)
  }

  return (
    <>
      <Header title="References" icon="reference" />
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={null}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getReferences}
          sizeToFit={true}
          updatedRecord={referenceToUpdate}
        />
      </div>
      <Modal
        title="Add Provider"
        show={addReferenceProvider}
        handleClose={closeModal}
      >
        <AddProviderModal
          reference={addReferenceProvider}
          setReferenceToUpdate={setReferenceToUpdate}
          handleClose={closeModal}
        />
      </Modal>
    </>
  )
}

export default ReferencesRoute
