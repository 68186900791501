import React, { useMemo, useState } from "react"

import Input from "components/forms/Input"
import Icon from "components/Icon"
import "./Video.scss"

const Video = ({ name, value, register, error }) => {
  const [preview, setPreview] = useState(null)

  const onBlur = event => {
    const submission = event.target.value
    if (/^http(s)?:\/\/player\.vimeo\.com\/video\/\d+/.test(submission)) {
      setPreview(submission)
    } else {
      setPreview(null)
    }
  }

  const placeholder = useMemo(() => {
    if (preview || value) {
      return (
        <iframe
          src={preview ? preview : value}
          alt="preview"
          className="company-video"
          title={preview ? "Company Video Preview" : "Company Video"}
        />
      )
    }
    return <Icon name="video" />
  }, [preview, value])

  return (
    <>
      <div className="video-upload">{placeholder}</div>
      <label htmlFor="title" className="form-label">
        URL
      </label>
      <Input
        name={name}
        register={register}
        onBlur={onBlur}
        error={error}
        placeholder="Video URL"
      />
    </>
  )
}

export default Video
