export const filterParams = {
  filterOptions: [
    "empty",
    {
      displayKey: "dateTrue",
      displayName: "Yes",
      predicate: (_, cellValue) => cellValue === "true",
      numberOfInputs: 0,
    },
    {
      displayKey: "dateFalse",
      displayName: "No",
      predicate: (_, cellValue) => cellValue === "false",
      numberOfInputs: 0,
    },
  ],
  suppressAndOrCondition: true,
}
