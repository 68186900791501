import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBriefcase,
  faBuilding,
  faBuildingUser,
  faCircleCheck,
  faCircleInfo,
  faCircleQuestion,
  faClipboard,
  faClipboardUser,
  faClockRotateLeft,
  faDollarSign,
  faFile,
  faFilePdf,
  faFileWord,
  faFilter,
  faFileLines,
  faFlag,
  faGauge,
  faImage,
  faMagnifyingGlass,
  faMicroscope,
  faPaperPlane,
  faPencil,
  faPeopleGroup,
  faTemperatureHalf,
  faShirt,
  faStar,
  faTrashCan,
  faUser,
  faUsers,
  faUserCheck,
  faUserPlus,
  faUserSecret,
  faUserTie,
  faUsersGear,
  faUsersViewfinder,
  faDesktop,
  faVideo,
  faQuestion,
  faChevronDown,
  faLifeRing,
  faRightFromBracket,
  faMapLocationDot,
  faGear,
  faTag,
  faTags,
  faTimes,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"

import { faComment } from "@fortawesome/free-regular-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

import "./Icon.scss"

function Icon({ name, className }) {
  const icons = {
    admins: faUsersGear,
    application: faClipboardUser,
    approval: faThumbsUp,
    audits: faClipboard,
    candidateProfiles: faUsersViewfinder,
    categories: faTags,
    check: faCircleCheck,
    chevronDown: faChevronDown,
    comment: faComment,
    company: faBuilding,
    compensation: faDollarSign,
    customer: faPeopleGroup,
    delete: faTrashCan,
    edit: faPencil,
    employers: faBuildingUser,
    file: faFile,
    filter: faFilter,
    flag: faFlag,
    gauge: faGauge,
    history: faClockRotateLeft,
    image: faImage,
    info: faCircleInfo,
    job: faBriefcase,
    linkedin: faLinkedin,
    location: faMapLocationDot,
    logo: faImage,
    microscope: faMicroscope,
    outreached: faPaperPlane,
    question: faQuestion,
    questionFlag: faCircleQuestion,
    pdf: faFilePdf,
    ratings: faStar,
    recon: faUserSecret,
    referral: faUserPlus,
    reference: faUserCheck,
    review: faFileLines,
    salesPro: faUserTie,
    search: faMagnifyingGlass,
    sentiment: faTemperatureHalf,
    settings: faGear,
    shirt: faShirt,
    signOut: faRightFromBracket,
    support: faLifeRing,
    tags: faTag,
    techStacks: faDesktop,
    times: faTimes,
    user: faUser,
    users: faUsers,
    video: faVideo,
    word: faFileWord,
  }

  return (
    <FontAwesomeIcon
      icon={icons[name]}
      className={`${name} ${className || ""}`}
    />
  )
}

export default Icon
