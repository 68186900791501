import apiCall from "utils/api"

export const updateUserLinkedinWorkExperience = (
  userId,
  experienceId,
  params
) => {
  const options = { data: params }
  return apiCall(
    "patch",
    `users/${userId}/linkedin_work_experiences/${experienceId}`,
    options
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
