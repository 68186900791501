import Badge from "react-bootstrap/Badge"

function StatusBadge({ status }) {
  let variant
  switch (status?.toLowerCase()) {
    case "active":
      variant = "success"
      break
    case "pending":
      variant = "warning"
      break
    case "denied":
      variant = "danger"
      break
    default:
      variant = "secondary"
  }

  return (
    <Badge bg={variant} className="customer-status-badge">
      {status}
    </Badge>
  )
}

export default StatusBadge
