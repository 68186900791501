import apiCall from "utils/api"

export const getRatings = params => {
  const options = { params }
  return apiCall("get", `ratings`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getRatingsValues = params => {
  const options = { params }
  return apiCall("get", `ratings/values`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getDeletedRatings = params => {
  const options = { params }
  return apiCall("get", `ratings/deleted`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getRating = id => {
  return apiCall("get", `ratings/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateRating = (id, params) => {
  const options = { data: { rating: params } }
  return apiCall("patch", `ratings/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const verifyRating = id => {
  return apiCall("post", `ratings/${id}/verify`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const rejectRating = id => {
  return apiCall("post", `ratings/${id}/reject`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const discardRating = (id, params) => {
  const options = { data: params }
  return apiCall("post", `ratings/${id}/discard`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const undiscardRating = id => {
  return apiCall("put", `ratings/${id}/undiscard`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getRatingOptions = (id, field) => {
  const options = { params: { field: field } }
  return apiCall("get", `ratings/${id}/form_options`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
