export const getFileIcon = contentType => {
  const FILE_TYPES = {
    "application/pdf": "pdf",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "text/plain": "txt",
  }

  const file = FILE_TYPES[contentType] || "file"

  return <img src={require(`assets/${file}.svg`)} width="20" alt={file} />
}
