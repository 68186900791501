import Spinner from "react-bootstrap/Spinner"

import "./Loading.scss"

const Loading = () => (
  <div className="loading">
    <Spinner animation="border" />
  </div>
)

export default Loading
