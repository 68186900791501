import { useState } from "react"
import { useForm } from "react-hook-form"
import { updateReference } from "api/references"
import { toast } from "react-hot-toast"

import ReferenceSelect from "./Select"

import "./AddProviderModal.scss"

const AddProviderModal = ({ reference, handleClose, setReferenceToUpdate }) => {
  const [errors, setErrors] = useState({})

  const { company, id, provider } = reference
  const { handleSubmit, control } = useForm()

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const handleSuccess = data => {
    setReferenceToUpdate(data)
    handleClose()
  }

  const onSubmit = data => {
    return updateReference(id, data)
      .then(response => {
        toast.success("Provider added!")
        handleSuccess(response.data)
      })
      .catch(response => {
        setErrors(response?.data?.errors)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="add-provider">
      <div className="form-field">
        <p>
          Select a user who has submitted a verified rating as a former
          employee. An email will be sent to the requester and provider.
        </p>
        {company?.id && (
          <ReferenceSelect
            companyId={company.id}
            name="provider_id"
            control={control}
            value={provider}
            error={getErrors("provider_id")[0]}
          />
        )}
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default AddProviderModal
