import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Navigate, useLocation } from "react-router-dom"
import { signIn } from "api/auth"
import "./SignIn.scss"

const SignInRoute = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { authenticated, currentAdmin } = useSelector(state => state.auth)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)

  const handleSignIn = e => {
    e.preventDefault()
    setError(false)

    const params = { admin: { email, password } }
    dispatch(signIn(params))
      .then(() => {})
      .catch(() => {
        setError(true)
      })
  }

  if (authenticated) {
    const path =
      currentAdmin.access_level === "full"
        ? location.state || "/dashboard"
        : "/operations"
    return <Navigate to={path} replace />
  }

  return (
    <div className="sign-in">
      <h5>Sign In</h5>

      {error && (
        <div className="alert alert-danger">Incorrect email or password</div>
      )}

      <form onSubmit={handleSignIn}>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-control"
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <input
          className="btn btn-primary w-100"
          type="submit"
          value="Sign in"
        />

        <p className="text-center mt-4">
          <Link to="/forgot_password">Forgot Password</Link>
        </p>
      </form>
    </div>
  )
}

export default SignInRoute
