import apiCall from "utils/api"

export const getHeadcounts = companyId => {
  return apiCall("get", `companies/${companyId}/headcounts`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createHeadcount = (companyId, params) => {
  const options = { data: { headcount: [params] } }
  return apiCall("post", `companies/${companyId}/headcounts`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateHeadcount = (id, params) => {
  const options = { data: { headcount: params } }
  return apiCall("patch", `headcounts/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteHeadcount = id => {
  return apiCall("delete", `headcounts/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
