import { useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col } from "react-bootstrap"

import Input from "components/forms/Input"
import DataUsersSelect from "components/data_users/Select"
import RoleSelect from "components/data_users/RoleSelect"

import "./Form.scss"

const DataUserForm = ({ onSubmit, dataUser, errors }) => {
  const { handleSubmit, register, reset, control } = useForm()

  const defaultValues = useMemo(
    () => ({
      first_name: dataUser?.first_name,
      last_name: dataUser?.last_name,
      email: dataUser?.email,
      data_customer_id: dataUser?.data_customer_id,
      role: dataUser?.role,
    }),
    [dataUser]
  )

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <Col>
          <label htmlFor="first_name" className="form-label">
            First Name
          </label>
          <Input
            type="text"
            name="first_name"
            label="First Name"
            error={getErrors("first_name")[0]}
            register={register}
          />
        </Col>
        <Col>
          <label htmlFor="last_name" className="form-label">
            Last Name
          </label>
          <Input
            type="text"
            name="last_name"
            label="Last Name"
            error={getErrors("last_name")[0]}
            register={register}
          />
        </Col>
      </Row>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <Input
          type="text"
          name="email"
          label="Email"
          error={getErrors("email")[0]}
          register={register}
        />
      </div>
      <div className={`mb-3 ${errors?.customer && "invalid-select"}`}>
        <label htmlFor="customer" className="form-label">
          Role
        </label>
        <div className={`form-field ${errors?.customer && "is-invalid"}`}>
          <RoleSelect
            name="role"
            field="role"
            control={control}
            error={getErrors("role")[0]}
            className={errors.access_type && "invalid-input"}
          />
        </div>
      </div>
      <div className={`mb-3 ${errors?.customer && "invalid-select"}`}>
        <label htmlFor="customer" className="form-label">
          Data Customer
        </label>
        <div className={`form-field ${errors?.customer && "is-invalid"}`}>
          <DataUsersSelect
            name="data_customer_id"
            control={control}
            value={dataUser?.data_customer}
            error={getErrors("data_customer")[0]}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default DataUserForm
