import { useState, useRef } from "react"
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import { getRewards, awardReward } from "api/rewards"
import { AgGridDateTime } from "utils/cellRenderers"

import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"

const RewardsRoute = () => {
  const gridRef = useRef()

  const [updatedRecord, setUpdatedRecord] = useState(null)

  const [columnDefs] = useState([
    {
      field: "rewards.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      width: 120,
    },
    {
      field: "users.first_name", // dictated by API
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/users/${data.user.id}`}>{data.user.name}</Link>
      ),
    },
    {
      field: "users.email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.user.email,
    },
    {
      field: "rewards.type", // dictated by API
      headerName: "Type",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.type,
    },
    {
      field: "rewards.notified_at", // dictated by API
      headerName: "Notified",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.notified_at),
    },
    {
      field: "rewards.awarded_at",
      headerName: "Sent",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.awarded_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      cellClass: "ag-center-aligned",
      headerClass: "ag-center-aligned",
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: ({ data }) => (
        <>
          {data.awarded_at ? (
            String.fromCharCode(8212)
          ) : (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleMarkAsSent(data.id)}
            >
              Mark as Sent
            </button>
          )}
        </>
      ),
    },
  ])

  const handleMarkAsSent = id => {
    awardReward(id)
      .then(response => {
        setUpdatedRecord(response.data)
        toast.success("Marked as sent")
      })
      .catch()
  }

  return (
    <>
      <Header title="Rewards" icon="shirt" />
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={null}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getRewards}
          sizeToFit={true}
          updatedRecord={updatedRecord}
        />
      </div>
    </>
  )
}

export default RewardsRoute
