import { useState, useRef, useCallback } from "react"
import { getCompanyReviews } from "api/companies"
import { AgGridDateTime } from "utils/cellRenderers"

import AgGrid from "components/ag_grid/AgGrid"
import ReviewDetail from "components/reviews/Detail"
import StatusBadge from "components/reviews/StatusBadge"
import GridUser from "components/users/GridUser"

const CompanyReviewsRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "reviews.id", // since companies also has an id col
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      width: 100,
    },
    {
      field: "users.name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
      width: 150,
    },
    {
      field: "body",
      headerName: "Body",
      filter: "agTextColumnFilter",
    },
    {
      field: "reviews.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Approved", "Pending", "Removed"],
      },
      sortable: false,
      cellRenderer: ({ data }) => {
        return <StatusBadge status={data.status} />
      },
      width: 120,
    },
    {
      field: "reviews.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])
  const [showDetail, setShowDetail] = useState(null)
  const [updatedRecord, setUpdatedRecord] = useState({})

  const hideDetail = () => {
    setShowDetail(undefined)
  }

  const retrieveCompanyReviews = useCallback(
    params => getCompanyReviews(id, params),
    [id]
  )

  const onCellClicked = e => {
    setShowDetail(e.data.id)
  }

  return (
    <>
      <div className="tab-header">
        <h3>Reviews</h3>
      </div>
      <AgGrid
        onCellClicked={onCellClicked}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={retrieveCompanyReviews}
        search={""}
        sizeToFit={true}
        updatedRecord={updatedRecord}
      />
      <ReviewDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        refresh={() => setShowDetail(id)}
        setUpdatedRecord={setUpdatedRecord}
      />
    </>
  )
}

export default CompanyReviewsRoute
