import React from "react"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"
// import hmacSHA256 from "crypto-js/hmac-sha256"
// import Hex from "crypto-js/enc-hex"
import { v4 as uuidv4 } from "uuid"

import Header from "components/Header"

import "./Sigma.scss"

const SigmaRoute = () => {
  const { currentAdmin } = useSelector(state => state.auth)
  const { email } = currentAdmin

  // const email = "ryan@repvue.com"

  const EMBED_PATH =
    "https://app.sigmacomputing.com/embed/1-MtVzCzCvekqZAdF7FgVkb"
  // const EMBED_SECRET =
  //   "4b08bfe271196639fb34f679a9ef8b1fe77f979db6ee8a2de8f26cbe52c171ca354ccafbc5507550d30a366d18adfcc51fde9d45bef1c0d6a05167bd4df885d2"

  let searchParams = `?:nonce=${uuidv4()}`
  searchParams += "&:session_length=3600"
  searchParams += "&:mode=userbacked"
  searchParams += `&:email=${email}`
  // searchParams += "&:show_footer=False"
  // searchParams += "&:loading_bg=f4f4f4"
  // searchParams += "&:loading_text=000"
  // searchParams += `&:theme=${user.theme}`
  searchParams += "&:external_user_team=all_internal_team"
  searchParams += `&:time=${Math.floor(new Date().getTime() / 1000)}`

  searchParams +=
    "&:client_id=f1dc894ee1685c238309886340f6dc629ba37470acb24765cece42167c2fdb7f"
  searchParams += `&:external_user_id=${email}`

  const URL_WITH_SEARCH_PARAMS = EMBED_PATH + searchParams

  // const hash = hmacSHA256(URL_WITH_SEARCH_PARAMS, EMBED_SECRET)
  // const SIGNATURE = hash.toString(Hex)

  const URL_TO_SEND = `${URL_WITH_SEARCH_PARAMS}` //&:signature=${SIGNATURE}`

  return (
    <div className="sigma-container">
      <div className="dashboard-header">
        <Header title="Sigma Reporting" icon="gauge" />
      </div>
      <div className="content">
        <Container fluid>
          <iframe
            title="Sigma Dashboard"
            id="sigmaDashboard"
            style={{ width: "100%", height: 740 }}
            src={URL_TO_SEND}
          />
        </Container>
      </div>
    </div>
  )
}

export default SigmaRoute
