import { useEffect, useMemo } from "react"
import { useForm, useFieldArray } from "react-hook-form"

import Input from "components/forms/Input"
import CompaniesSelect from "components/companies/Select"
import CategoriesSelect from "components/tech_stacks/categories/Select"
import "./Form.scss"

const VendorForm = ({ onSubmit, vendor, errors }) => {
  const { handleSubmit, watch, register, control, reset, setValue } = useForm({
    vendor,
  })
  const { fields, append } = useFieldArray({
    control,
    name: "tech_stacks_attributes",
  })

  const defaultValues = useMemo(
    () => ({
      name: vendor?.name,
      company: vendor?.company,
      tech_stacks_attributes: vendor?.tech_stacks.map(
        ({ id, tech_stack_category, cost }) => {
          return { id, tech_stack_category_id: tech_stack_category.id, cost }
        }
      ),
    }),
    [vendor]
  )

  const watchDestroy = watch("tech_stacks_attributes")

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="vendor-form">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <Input
          type="text"
          name="name"
          label="Name"
          error={getErrors("name")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="company" className="form-label">
          Company
        </label>
        <CompaniesSelect
          name="company_id"
          control={control}
          value={vendor?.company}
          error={getErrors("company_id")[0]}
        />
      </div>
      <div className="mb-3">
        <div className="category-header">
          <label htmlFor="category" className="form-label">
            Categories
          </label>
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={() => {
              append({ tech_stack_category_id: "", cost: "" })
            }}
          >
            Add
          </button>
        </div>
        {fields.map((field, index) => {
          const category = watchDestroy[index]
          if (category?._destroy) return null

          return (
            <div
              className={`row mb-3 align-items-center ${
                field._destroy ? "d-none" : ""
              }`}
              key={field.id}
            >
              <div className="col-8">
                <CategoriesSelect
                  name={`tech_stacks_attributes.${index}.tech_stack_category_id`}
                  control={control}
                  error={
                    getErrors(
                      "tech_stacks_attributes.tech_stack_category_id"
                    )[0]
                  }
                />
              </div>
              <div className="col-3">
                <Input
                  type="number"
                  name={`tech_stacks_attributes.${index}.cost`}
                  label="Cost"
                  min="0"
                  error={getErrors("tech_stacks_attributes.cost")[0]}
                  register={register}
                />
              </div>
              <div className="col-1 d-flex">
                <button
                  type="button"
                  className="btn btn-close btn-sm"
                  onClick={() =>
                    setValue(`tech_stacks_attributes.${index}._destroy`, true)
                  }
                />
              </div>
            </div>
          )
        })}
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default VendorForm
