import React, { useEffect, useState } from "react"
import {
  getCompanyTechStack,
  deleteCompanyTechStack,
} from "api/companies/tech_stack"
import { formatDateTime } from "utils/format"
import toast from "react-hot-toast"
import Button from "react-bootstrap/Button"
import Imgix from "react-imgix"

import Modal from "components/Modal"
import Icon from "components/Icon"
import NewTechStack from "components/companies/tech_stacks/New"

const CompanyTechStackRoute = ({ id }) => {
  const [techStack, setTechStack] = useState([])
  const [showNew, setShowNew] = useState(false)
  const [loading, setLoading] = useState(true)

  const loadTechStack = id => {
    getCompanyTechStack(id)
      .then(response => {
        setTechStack(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadTechStack(id)
  }, [id])

  const handleDelete = id => {
    if (window.confirm(`Are you sure you want to delete this product?`)) {
      deleteCompanyTechStack(id).then(() => {
        setTechStack(techStack.filter(tech => tech.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowNew(false)
  }

  if (loading) return <div />

  return (
    <div className=".company-tech-stacks">
      <div className="tab-header">
        <h3>Tech Stack</h3>
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Add Tech Stack
        </Button>
      </div>
      <table className="table table-hover editable-table">
        <thead>
          <tr>
            <th>Vendor</th>
            <th>Category</th>
            <th className="text-end">Cost</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {techStack &&
            techStack.map(tech => {
              const {
                id,
                created_at,
                tech_stack: { cost, tech_stack_category, tech_stack_vendor },
              } = tech
              return (
                <tr key={id}>
                  <td>
                    <div className="d-flex align-items-center">
                      {tech_stack_vendor.logo && (
                        <div className="me-2">
                          <Imgix
                            src={`${process.env.REACT_APP_IMAGE_URL}/${tech_stack_vendor.logo}`}
                            alt={tech_stack_vendor.name}
                            width={30}
                            height={30}
                          />
                        </div>
                      )}
                      {tech_stack_vendor.name}
                    </div>
                  </td>
                  <td>{tech_stack_category.name}</td>
                  <td className="text-end">
                    {cost || String.fromCharCode(8212)}
                  </td>
                  <td>{formatDateTime(created_at)}</td>
                  <td className="actions">
                    <button className="btn" onClick={() => handleDelete(id)}>
                      <Icon name="delete" />
                    </button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <Modal title="Add Tech Stack" show={showNew} handleClose={closeModal}>
        <NewTechStack
          companyId={id}
          handleSuccess={() => loadTechStack(id)}
          handleClose={() => setShowNew(false)}
        />
      </Modal>
    </div>
  )
}

export default CompanyTechStackRoute
