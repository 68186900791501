export const getCompanyAttributeSelectStyles = modalForm => ({
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#c8ced4",
    minHeight: modalForm ? "38px" : "31px",
    height: modalForm ? "38px" : "31px",
    boxShadow: state.isFocused ? null : null,
    ":hover": {
      cursor: "pointer",
    },
  }),
  option: provided => ({
    ...provided,
    minHeight: modalForm ? "38px" : "31px",
    height: modalForm ? "38px" : "31px",
  }),
  valueContainer: provided => ({
    ...provided,
    height: modalForm ? "38px" : "31px",
    padding: "0 6px",
  }),
  input: provided => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: modalForm ? "38px" : "30px",
  }),
})
