import { useState, useEffect, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { getDataCustomer, createDataCustomerCompany } from "api/data_customers"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import Header from "components/Header"
import Modal from "components/Modal"
import { Alert, Button } from "react-bootstrap"
import StatusBadge from "components/data_customers/StatusBadge"
import NewCompanyForm from "components/data_customers/Companies/NewCompany"
import EditDataCustomerCompany from "components/data_customers/Companies/Edit"

import "./DataCustomer.scss"

const DataCustomerRoute = () => {
  const { id } = useParams()

  const [customer, setCustomer] = useState({})
  const [showEdit, setShowEdit] = useState(null)
  const [customerCompanies, setCustomerCompanies] = useState({})
  const [showOnlyActiveCompanies, setShowOnlyActiveCompanies] = useState(false)
  const [loading, setLoading] = useState(true)

  const { handleSubmit, register, reset, control } = useForm()

  dayjs.extend(utc)

  const defaultValues = useMemo(
    () => ({
      company_id: null,
      added_at: null,
      removed_at: null,
    }),
    []
  )

  const fetchCustomer = id => {
    setLoading(true)
    getDataCustomer(id)
      .then(response => {
        setCustomer(response.data.rows)
        setCustomerCompanies({
          active: response.data.rows.data_customer_companies.filter(
            company => company.removed_at === null
          ),
          all: response.data.rows.data_customer_companies,
        })
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
      })
  }

  const onSubmit = data => {
    return createDataCustomerCompany(customer.id, data)
      .then(res => {
        const newDataCustomerCompanies = [...customer.data_customer_companies]
        newDataCustomerCompanies.push(res.data)
        setCustomer({
          ...customer,
          data_customer_companies: newDataCustomerCompanies,
        })
        toast.success("Company added successfully!")
      })
      .catch(() => {
        toast.error("Something went wrong.")
      })
  }

  const handleSuccess = company => {
    const newDataCustomerCompanies = [...customer.data_customer_companies]
    const replaceIndex = newDataCustomerCompanies.findIndex(
      element => element.id === company.id
    )
    newDataCustomerCompanies.splice(replaceIndex, 1, company)

    setCustomer({
      ...customer,
      data_customer_companies: newDataCustomerCompanies,
    })
  }

  const toggleOnlyActiveCompanies = value => {
    if (!value) {
      setCustomer({
        ...customer,
        data_customer_companies: customerCompanies.active,
      })
    } else {
      setCustomer({
        ...customer,
        data_customer_companies: customerCompanies.all,
      })
    }
    setShowOnlyActiveCompanies(!showOnlyActiveCompanies)
  }

  useEffect(() => {
    fetchCustomer(id)
  }, [id])

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  if (loading) {
    return <div />
  }

  return (
    <>
      <Header title={customer.name} icon="customer">
        <StatusBadge status={customer.active} />
      </Header>
      <div className="content">
        {customer?.type === "DataCustomers::Unlimited" ? (
          <Alert key="warning" variant="warning">
            This customer is on the <strong>All You Can Eat</strong> model and
            has access to all companies. However, company associations are still
            maintained.
          </Alert>
        ) : (
          <>
            <section className="d-flex align-items-center mb-2 justify-content-between">
              <h4 className="mb-0">Companies</h4>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="showActive"
                  className="form-check-input"
                  checked={showOnlyActiveCompanies}
                  onChange={() =>
                    toggleOnlyActiveCompanies(showOnlyActiveCompanies)
                  }
                />
                <label htmlFor="showActive" className="form-check-label">
                  Show only active companies
                </label>
              </div>
            </section>
            <form onSubmit={handleSubmit(onSubmit)}>
              <table className="table data-customer-table table-hover">
                <thead>
                  <tr className="table-header">
                    <th>Name</th>
                    <th className="text-end">Ratings</th>
                    <th className="w-25">Added At</th>
                    <th className="w-25">Removed At</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <NewCompanyForm register={register} control={control} />
                  {customer?.data_customer_companies?.map(
                    data_customer_company => {
                      const { company } = data_customer_company

                      return (
                        <tr key={company.id}>
                          <td>
                            <Link to={`/companies/${company.id}`}>
                              {company.name}
                            </Link>
                          </td>
                          <td className="text-end">{company.ratings_count}</td>
                          <td>
                            {data_customer_company.added_at
                              ? dayjs(data_customer_company.added_at)
                                  .utc()
                                  .format("YYYY-MM-DD")
                              : String.fromCharCode(8212)}
                          </td>
                          <td>
                            {data_customer_company.removed_at
                              ? dayjs(data_customer_company.removed_at)
                                  .utc()
                                  .format("YYYY-MM-DD")
                              : String.fromCharCode(8212)}
                          </td>
                          <td className="data-companies-actions">
                            <Button
                              size="sm"
                              className="add"
                              onClick={() => setShowEdit(data_customer_company)}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </form>
          </>
        )}
      </div>
      <Modal
        show={showEdit}
        title={"Edit Data Customer Company"}
        handleClose={() => setShowEdit(false)}
      >
        <EditDataCustomerCompany
          company={showEdit}
          handleSuccess={handleSuccess}
          handleClose={() => setShowEdit(false)}
        />
      </Modal>
    </>
  )
}

export default DataCustomerRoute
