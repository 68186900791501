import { formatDateTime } from "utils/format"
import "./Audits.scss"

const DetailAudits = ({ audits }) => (
  <div className="rating-audits">
    <table className="audit-table">
      <tbody>
        {audits?.map(audit => {
          const { id, name, audited_changes, created_at } = audit
          return (
            <tr key={id} className="audit">
              <td className="w-25">
                {name}
                <br />
                <small>{formatDateTime(created_at)}</small>
              </td>
              <td className="w-75">
                {Object.keys(audited_changes).map(field => (
                  <div key={field}>
                    <strong>{field}:</strong>{" "}
                    {`${audited_changes[field][0]} -> ${audited_changes[field][1]}`}
                  </div>
                ))}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </div>
)

export default DetailAudits
