import React from "react"

const Input = ({
  className,
  handleChange,
  handleKeyPress,
  name,
  label,
  error,
  register,
  ...rest
}) => {
  return (
    <>
      <input
        name={name}
        id={name}
        className={`form-control ${className || ""} ${
          error ? "is-invalid" : ""
        }`}
        placeholder={label}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        aria-label={label}
        {...register(name)}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}

export default Input
