import apiCall from "utils/api"

export const createCompanyBusinessUnit = (companyId, params) => {
  const options = { data: { business_unit: params } }
  return apiCall("post", `companies/${companyId}/business_units`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateCompanyBusinessUnit = (companyId, unitId, params) => {
  const options = { data: { business_unit: params } }
  return apiCall(
    "put",
    `companies/${companyId}/business_units/${unitId}`,
    options
  )
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteCompanyBusinessUnit = (companyId, unitId) => {
  return apiCall("delete", `companies/${companyId}/business_units/${unitId}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
