import apiCall from "utils/api"

export const getSellingPersonas = () => {
  return apiCall("get", `selling_personas`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
