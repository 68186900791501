import qs from "qs"
import { difference } from "utils/objects"

export const setQueryParams = request => {
  const defaultParams = { startRow: 0, endRow: 50, pivotMode: false }
  const diffParams = difference(defaultParams, request)

  const params = qs.stringify(diffParams, {
    encode: false,
    arrayFormat: "brackets",
  })

  const path = window.location.pathname
  const hash = window.location.hash
  const base = path + hash

  const newPath =
    Array.from(params).length > 0 ? base + "?" + params.toString() : base
  window.history.replaceState(null, "", newPath)
}

export const getInitialParams = () => {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
}

export const getQueryString = () => {
  return window.location.search
}
