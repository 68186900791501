import React, { useEffect, useState } from "react"
import { getCompanyLeaders, deleteLeader } from "api/companies/leaders"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"
import Button from "react-bootstrap/Button"
import Imgix from "react-imgix"

import Modal from "components/Modal"
import Icon from "components/Icon"
import EditLeader from "components/companies/leaders/Edit"
import NewLeader from "components/companies/leaders/New"

import "./Leadership.scss"

const CompanyLeadershipRoute = ({ id }) => {
  const [leaders, setLeaders] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [editing, setEditing] = useState({})
  const [loading, setLoading] = useState(true)

  const loadLeaders = id => {
    getCompanyLeaders(id)
      .then(response => {
        setLeaders(response.data.leaders)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadLeaders(id)
  }, [id])

  const handleEdit = id => {
    const leader = leaders.find(leader => leader.id === id)
    setEditing(leader)
    setShowEdit(true)
  }

  const handleDelete = id => {
    const leader = leaders.find(leader => leader.id === id)
    if (window.confirm(`Are you sure you want to delete ${leader.name}?`)) {
      deleteLeader(id).then(() => {
        setLeaders(leaders.filter(leader => leader.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowEdit(false)
    setShowNew(false)
  }

  // use this to update the leader in state as opposed to refreshing all or using redux
  const replaceLeader = leader => {
    const newLeaders = [...leaders]
    const index = newLeaders.findIndex(l => l.id === leader.id)
    newLeaders[index] = leader
    setLeaders(newLeaders)
  }

  if (loading) return <div />

  return (
    <div className="company-leadership">
      <div className="section-header">
        <h5>Leadership</h5>
        <Button variant="primary btn-sm" onClick={() => setShowNew(true)}>
          Add Leader
        </Button>
      </div>
      <table className="table table-hover editable-table">
        <thead>
          <tr>
            <th className="text-center">Photo</th>
            <th>Name</th>
            <th>Title</th>
            <th>LinkedIn URL</th>
            <th>Last Update</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
          {leaders &&
            leaders.map(leader => {
              const { id, photo, name, title, linkedin_url, updated_at } =
                leader
              return (
                <tr key={id}>
                  <td className="leader-photo">
                    {photo ? (
                      <Imgix
                        src={`${process.env.REACT_APP_IMAGE_URL}/${photo}`}
                        alt={name}
                        width={50}
                        height={50}
                      />
                    ) : (
                      <Icon name="user" />
                    )}
                  </td>
                  <td>
                    <div className="field-value">{name}</div>
                  </td>
                  <td>{title}</td>
                  <td>
                    {linkedin_url ? (
                      <a href={linkedin_url} target="_blank" rel="noreferrer">
                        {linkedin_url}
                      </a>
                    ) : (
                      String.fromCharCode(8212)
                    )}
                  </td>
                  <td>{formatDateTime(updated_at)}</td>
                  <td className="actions">
                    <button className="btn me-1" onClick={() => handleEdit(id)}>
                      <Icon name="edit" />
                    </button>
                    <button className="btn" onClick={() => handleDelete(id)}>
                      <Icon name="delete" />
                    </button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <Modal
        title={showEdit ? "Edit Leader" : "New Leader"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewLeader
            companyId={id}
            handleSuccess={() => loadLeaders(id)}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditLeader
            leader={editing}
            handleSuccess={replaceLeader}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </div>
  )
}

export default CompanyLeadershipRoute
