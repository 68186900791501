import { useState, useRef, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getRatings } from "api/ratings"
import { AgGridDateTime, AgGridHyperlink } from "utils/cellRenderers"
import { setQueryParams, getInitialParams, getQueryString } from "utils/query"

import StatusBadge from "components/ratings/StatusBadge"
import GridCompany from "components/companies/GridCompany"
import GridUser from "components/users/GridUser"
import RatingDetail from "components/ratings/Detail"
import AgGrid from "components/ag_grid/AgGrid"
import Header from "components/Header"
import Icon from "components/Icon"

const RatingsRoute = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const gridRef = useRef()

  const [updatedRecord, setUpdatedRecord] = useState(null)
  const [showDetail, setShowDetail] = useState(undefined)

  const initialParams = getInitialParams()

  const [columnDefs] = useState([
    {
      field: "ratings.id", // since companies also has an id col
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      width: 120,
    },
    {
      field: "users.name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridCompany company={data.company} />,
    },
    {
      field: "user_status",
      headerName: "User Status",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.user_status || String.fromCharCode(8212),
    },
    {
      field: "user_role",
      headerName: "Role",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.user_role || String.fromCharCode(8212),
      hide: true,
    },
    {
      field: "tenure",
      headerName: "Tenure",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.tenure || String.fromCharCode(8212),
      hide: true,
    },
    {
      field: "sales_division",
      headerName: "Division",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.sales_division || String.fromCharCode(8212),
      hide: true,
    },
    {
      field: "office_status",
      headerName: "Office Status",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.office_status || String.fromCharCode(8212),
      hide: true,
    },
    {
      field: "user_recons.linkedin_url",
      headerName: "Internal LinkedIn URL",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) =>
        AgGridHyperlink(data.user?.user_recon?.linkedin_url, true),
      externalURL: true,
    },
    {
      field: "countries.name",
      headerName: "Country",
      filter: "agTextColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => data.country?.name,
    },
    {
      field: "effective_at",
      headerName: "Effective Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.effective_at),
      hide: true,
    },
    {
      field: "ratings.flagged_for_question",
      headerName: "Flagged",
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
      },
      sortable: false,
      cellRenderer: ({ data }) => {
        return data.flagged_for_question ? (
          <Icon name="questionFlag" />
        ) : (
          String.fromCharCode(8212)
        )
      },
    },
    {
      field: "ratings.status",
      headerName: "Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Not Reviewed", "Unverified", "Verified", "Deleted"],
      },
      sortable: false,
      cellRenderer: ({ data }) => {
        return <StatusBadge status={data.status} />
      },
    },
    {
      field: "admins.first_name",
      headerName: "Last Updated By",
      filter: "agTextColumnFilter",
      hide: true,
      cellRenderer: ({ data }) =>
        data.most_recent_audit?.name || String.fromCharCode(8212),
    },
    {
      field: "audits.created_at",
      headerName: "Last Updated",
      filter: false,
      hide: true,
      cellRenderer: ({ data }) =>
        AgGridDateTime(data.most_recent_audit?.created_at),
    },
    {
      field: "ratings.created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
  ])

  const hideDetail = () => {
    const search = getQueryString()
    setShowDetail(undefined)
    navigate(`/ratings${search}`)
  }

  const onCellClicked = e => {
    const search = getQueryString()
    navigate(`/ratings/${e.data.id}${search}`)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header title="Ratings" icon="ratings" />
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getRatings}
          sizeToFit={true}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
        />
      </div>
      <RatingDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setUpdatedRating={setUpdatedRecord}
      />
    </>
  )
}

export default RatingsRoute
