import Badge from "react-bootstrap/Badge"

function StatusBadge({ status, className }) {
  let variant

  switch (status?.toLowerCase()) {
    case "verified":
      variant = "success"
      break
    case "not_reviewed":
      variant = "secondary"
      break
    case "unverified":
      variant = "warning"
      break
    case "deleted":
      variant = "danger"
      break
    default:
      variant = "secondary"
  }

  return (
    <Badge bg={variant} className={`rating-status-badge ${className}`}>
      {status}
    </Badge>
  )
}

export default StatusBadge
