import { DirectUpload } from "activestorage"

export async function uploadImage(file) {
  const upload = new DirectUpload(
    file,
    `${process.env.REACT_APP_API_URL}/direct_uploads`
  )
  const result = new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
        reject(error)
      } else {
        resolve(blob)
      }
    })
  })
  return await result
}
