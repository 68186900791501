import { useMemo, useEffect } from "react"
import { AgGridReact } from "ag-grid-react"

import "components/ag_grid/GridStyles.scss"

import { LicenseManager } from "ag-grid-enterprise"
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)

const AgGrid = ({
  onCellClicked,
  gridRef,
  placeholder,
  getterFunction,
  search,
  initialParams = [],
  setQueryParams,
  sizeToFit,
  watchedValues,
  updatedRecord,
  ...rest
}) => {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  )

  const handleFilterTransform = params => {
    if (Object.keys(params.request.filterModel).length !== 0) {
      for (const filter in params.request.filterModel) {
        if (filter.includes("cents")) {
          params.request.filterModel[`${filter}`].filter =
            params.request.filterModel[`${filter}`].filter * 100
        }
      }
    }
    return params
  }

  const datasource = useMemo(
    () => ({
      getRows(params) {
        if (search?.length) params.request.q = search
        params = handleFilterTransform(params)

        if (typeof setQueryParams == "function") {
          setQueryParams(params.request)
        }
        getterFunction(params.request)
          .then(response => {
            params.success({
              rowData: response.data.rows,
              rowCount: response.data.meta.rows_count,
            })
          })
          .catch(error => {
            console.error(error)
            params.failCallback()
          })
      },
    }),
    [getterFunction, search, setQueryParams]
  )

  const handleCellClick = e => {
    if (onCellClicked) {
      if (e.colDef.externalURL || e.colDef.field === "actions") {
        return
      } else {
        onCellClicked(e)
      }
    }
  }

  const onGridReady = event => {
    const { api, columnApi } = event

    api.setServerSideDatasource(datasource)

    if (initialParams && initialParams.filterModel) {
      api.setFilterModel(initialParams.filterModel)
    }

    if (initialParams && initialParams.sortModel) {
      columnApi.applyColumnState({
        state: initialParams.sortModel,
        defaultState: { sort: null },
      })
    }

    if (sizeToFit) {
      api.sizeColumnsToFit()
    }
  }

  const onFirstDataRendered = event => {
    const { api } = event

    if (initialParams && initialParams.startRow) {
      api.paginationGoToPage(Math.floor(initialParams.startRow / 50))
    }
  }

  const loadingCellRenderer = () => {
    return <div></div>
  }

  const loadingCellRendererParams = () => {
    return null
  }

  const getRowId = params => {
    return params.data.id
  }

  useEffect(() => {
    if (gridRef?.current.api && updatedRecord) {
      const gridApi = gridRef?.current.api
      gridApi.forEachNode(rowNode => {
        if (updatedRecord.id === rowNode.data?.id) {
          // directly update data in rowNode
          rowNode.setData(updatedRecord)
        }
      })
    }
  }, [gridRef, updatedRecord])

  useEffect(() => {
    if (gridRef?.current.api) {
      gridRef.current.api.setServerSideDatasource(datasource)
    }
  }, [gridRef, datasource, watchedValues])

  return (
    <>
      <div className="ag-theme-custom">
        <AgGridReact
          ref={gridRef}
          onCellClicked={handleCellClick}
          animateRows={true}
          rowSelection="multiple"
          rowModelType="serverSide"
          pagination={true}
          paginationPageSize={50}
          cacheBlockSize={50}
          serverSideInfiniteScroll={true}
          maxBlocksInCache={1}
          headerHeight={46}
          suppressLoadingOverlay={false}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true,
                },
              },
            ],
          }}
          getRowId={getRowId}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          loadingCellRenderer={loadingCellRenderer}
          loadingCellRendererParams={loadingCellRendererParams}
          {...rest}
        />
      </div>
    </>
  )
}

export default AgGrid
