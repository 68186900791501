import { useState } from "react"
import { Form, Button } from "react-bootstrap"
import { discardRating } from "api/ratings"

import Modal from "components/Modal"

const DeleteModal = ({ rating, show, handleClose, afterDiscard }) => {
  const NOTIFY_REASONS = [
    "inaccurate_data",
    "attempted_access_management",
    "attempted_access_ta",
    "attempted_access_other",
    "n2s_reroute",
  ]

  const [deleteReason, setDeleteReason] = useState("inaccurate_data") // first option is default
  const [submitting, setSubmitting] = useState(false)

  const { id, company, user } = rating

  const onSubmit = e => {
    e.preventDefault()
    if (submitting) return
    setSubmitting(true)
    return discardRating(id, { discard_reason: deleteReason })
      .then(response => {
        afterDiscard(response.data)
        handleClose()
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  const buttonText = NOTIFY_REASONS.includes(deleteReason)
    ? "Delete Rating & Notify User"
    : "Delete Rating"

  return (
    <Modal title="Delete Rating" handleClose={handleClose} show={show}>
      <p>
        Are you sure you want to delete this rating for company{" "}
        <strong>{company?.name}</strong> from user <strong>{user?.name}</strong>
        ?
      </p>

      <Form onSubmit={onSubmit}>
        <h6>Select a reason for deleting this rating.</h6>
        <Form.Group className="mb-3">
          <Form.Select
            aria-label="Select notification"
            name="deleteReason"
            onChange={e => setDeleteReason(e.target.value)}
          >
            <option value="inaccurate_data">Inaccurate data</option>
            <option value="attempted_access_management">
              Attempted access - Management
            </option>
            <option value="attempted_access_ta">Attempted access - TA</option>
            <option value="attempted_access_other">
              Attempted access - Other
            </option>
            <option value="n2s_reroute">New-to-sales reroute</option>
            <option value="duplicate">Duplicate (Do Not Notify)</option>
            <option value="discretionary">Discretionary (Do Not Notify)</option>
            <option value="user_requested">
              User requested (Do Not Notify)
            </option>
          </Form.Select>
        </Form.Group>
        <Button
          variant="danger"
          type="submit"
          className="w-100 mb-2"
          disabled={submitting}
        >
          {buttonText}
        </Button>
        <div className="text-center">
          <Button onClick={handleClose} variant="link" className="btn-sm">
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default DeleteModal
