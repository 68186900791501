import React from "react"
import Icon from "components/Icon"

const EmptyPhotos = () => (
  <div className="empty-section">
    <Icon name="image" />
    <p className="text-muted">No photos</p>
  </div>
)

export default EmptyPhotos
