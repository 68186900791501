import apiCall from "utils/api"

export const getCompanyLocations = companyId => {
  return apiCall("get", `companies/${companyId}/locations`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCompanyLocation = (companyId, params) => {
  const options = { data: { location: params } }
  return apiCall("post", `companies/${companyId}/locations`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateLocation = (id, params) => {
  const options = { data: { location: params } }
  return apiCall("patch", `locations/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteLocation = id => {
  return apiCall("delete", `locations/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
