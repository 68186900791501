import { formatDateTime } from "utils/format"
import Icon from "components/Icon"

const ApprovalList = ({ approvals }) => (
  <section>
    <div className="section-header">
      <label>
        <Icon name="approval" /> Approvals
      </label>
    </div>
    <table className="approval-table">
      <tbody>
        {approvals?.map(approval => {
          const { id, admin, created_at } = approval
          const { first_name, last_name } = admin
          return (
            <tr key={id}>
              <td className="w-25">{`${first_name} ${last_name}`}</td>
              <td className="w-75">{formatDateTime(created_at)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </section>
)

export default ApprovalList
