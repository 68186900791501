import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import Input from "components/forms/Input"
import ImageUpload from "components/forms/ImageUpload"

const PhotoForm = ({ onSubmit, photo, errors }) => {
  const { handleSubmit, register, setValue, reset } = useForm({ photo })

  const defaultValues = useMemo(
    () => ({
      caption: photo?.caption,
      file: undefined,
    }),
    [photo]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3 text-center">
        <label htmlFor="photo" className="form-label text-center">
          Photo
        </label>
        <ImageUpload
          handleChange={setValue}
          register={register}
          name="file"
          type="image"
          value={
            photo
              ? `${process.env.REACT_APP_IMAGE_URL}/${photo?.file}`
              : undefined
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="caption" className="form-label">
          Caption
        </label>
        <Input
          type="text"
          name="caption"
          label="Caption"
          error={getErrors("caption")[0]}
          register={register}
        />
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default PhotoForm
