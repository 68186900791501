import apiCall from "utils/api"

export const getCategories = params => {
  const options = { params }
  return apiCall("get", `tech_stack_categories`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCategory = params => {
  const options = { data: { tech_stack_category: params } }
  return apiCall("post", `tech_stack_categories`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
