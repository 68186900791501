import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { getUserRankings } from "api/users"
import { formatDateTime } from "utils/format"

import "./Rankings.scss"

const UserRankingsRoute = ({ id }) => {
  const [rankings, setRankings] = useState([])

  useEffect(() => {
    getUserRankings(id)
      .then(response => {
        setRankings(response.data.rankings)
      })
      .catch(() => {
        // setLoading(false)
      })
  }, [id])

  return (
    <div className="user-rankings">
      <div className="tab-header">
        <h3>Rankings</h3>
      </div>
      <Row>
        <Col md={12}>
          {rankings.map((ranking, index) => {
            const { id, created_at, metric_rankings } = ranking
            return (
              <div key={id} className="ranking">
                <h5>
                  {formatDateTime(created_at)}
                  {index === 0 && (
                    <span className="badge bg-info text-dark">Current</span>
                  )}
                </h5>
                <table>
                  <tbody>
                    {metric_rankings.map(metric_ranking => (
                      <tr>
                        <td>{metric_ranking.position + 1}</td>
                        <td>{metric_ranking.metric}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          })}
        </Col>
      </Row>
    </div>
  )
}

export default UserRankingsRoute
