import { useState, useRef, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getDeletedRatings } from "api/ratings"
import { AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams, getQueryString } from "utils/query"
import toast from "react-hot-toast"
import { undiscardRating } from "api/ratings"

import GridUser from "components/users/GridUser"
import GridCompany from "components/companies/GridCompany"
import RatingDetail from "components/ratings/Detail"
import AgGrid from "components/ag_grid/AgGrid"
import Header from "components/Header"

const DeletedRatingsRoute = () => {
  const [updatedRecord, setUpdatedRecord] = useState(null)
  const [watchedValue, setWatchedValue] = useState(null)

  const initialParams = getInitialParams()

  const handleUndiscard = rating => {
    if (
      window.confirm(
        `Are you sure you want to restore this rating?\nIf the associated company and/or user is deleted, they will also be restored too.`
      )
    ) {
      undiscardRating(rating.id).then(res => {
        setWatchedValue(res.data)
        toast.success("Restore successful")
      })
    }
  }

  const [columnDefs] = useState([
    {
      field: "ratings.id", // since companies also has an id col
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      width: 120,
    },
    {
      field: "users.first_name",
      headerName: "User",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "companies.name",
      headerName: "Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridCompany company={data.company} />,
    },
    {
      field: "user_status",
      headerName: "User Status",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "user_role",
      headerName: "Role",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "tenure",
      headerName: "Tenure",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "sales_division",
      headerName: "Division",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "office_status",
      headerName: "Office Status",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "countries.name",
      headerName: "Country",
      filter: "agTextColumnFilter",
      hide: true,
      cellRenderer: ({ data }) => data.country?.name,
    },
    {
      field: "ratings.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "discard_reason",
      headerName: "Reason Deleted",
      filter: "agSetColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) =>
        data.discard_reason ? data.discard_reason : String.fromCharCode(8212),
      filterParams: {
        values: [
          "Inaccurate data",
          "Attempted access - Management",
          "Attempted access - TA",
          "Attempted access - Other",
          "New-to-sales reroute",
          "Duplicate",
          "Discretionary",
          "User requested",
        ],
      },
    },
    {
      field: "effective_at",
      headerName: "Effective Date",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.effective_at),
      hide: true,
    },
    {
      field: "ratings.discarded_at",
      headerName: "Deleted",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.discarded_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      suppressMenu: true,
      headerClass: "ag-center-aligned",
      cellClass: "justify-content-center",
      width: 170,
      cellRenderer: ({ data }) => (
        <button
          className="btn btn-primary employer-util btn-sm"
          onClick={() => handleUndiscard(data)}
        >
          Restore
        </button>
      ),
    },
  ])

  const [showDetail, setShowDetail] = useState(false)

  const navigate = useNavigate()

  const { id } = useParams()

  const gridRef = useRef()

  const hideDetail = () => {
    const search = getQueryString()
    setShowDetail(false)
    navigate(`/ratings/deleted${search}`)
  }

  const onCellClicked = e => {
    const search = getQueryString()
    navigate(`/ratings/deleted/${e.data.id}${search}`)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header title="Deleted Ratings" icon="ratings" />
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getDeletedRatings}
          sizeToFit={true}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
          watchedValues={watchedValue}
        />
      </div>
      <RatingDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
        setUpdatedRating={setUpdatedRecord}
      />
    </>
  )
}

export default DeletedRatingsRoute
