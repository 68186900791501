import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import Input from "components/forms/Input"
import ImageUpload from "components/forms/ImageUpload"
import TextArea from "components/forms/TextArea"

const TestimonialForm = ({ onSubmit, testimonial, errors }) => {
  const { handleSubmit, register, setValue, reset } = useForm({ testimonial })

  const defaultValues = useMemo(
    () => ({
      name: testimonial?.name,
      title: testimonial?.title,
      body: testimonial?.body,
      photo: undefined,
    }),
    [testimonial]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3 text-center">
        <label htmlFor="photo" className="form-label text-center">
          Photo
        </label>
        <ImageUpload
          handleChange={setValue}
          register={register}
          name="photo"
          type="user"
          value={
            testimonial && testimonial.photo
              ? `${process.env.REACT_APP_IMAGE_URL}/${testimonial?.photo}`
              : undefined
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <Input
          type="text"
          name="name"
          label="Name"
          error={getErrors("name")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <Input
          type="text"
          name="title"
          label="Title"
          error={getErrors("title")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="linkedin_url" className="form-label">
          Body
        </label>
        <TextArea
          rows={4}
          name="body"
          labe="Body"
          error={getErrors("body")[0]}
          register={register}
        />
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default TestimonialForm
