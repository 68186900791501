import apiCall from "utils/api"
import qs from "qs"

export const getJobOutreachSummary = (id, params) => {
  const searchParams =
    params?.length > 0
      ? `?${qs.stringify(
          { metro_location_ids: params },
          { encode: false, arrayFormat: "brackets" }
        )}`
      : ""
  return apiCall("get", `jobs/${id}/outreach_summary${searchParams}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const sendJobOutreachSummary = id => {
  return apiCall("post", `jobs/${id}/send_outreach_notifications`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
