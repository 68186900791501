import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import dayjs from "dayjs"

import HeadcountsSelect from "./Select"
import Input from "components/forms/Input"

const HeadcountForm = ({ onSubmit, headcount, errors }) => {
  const { handleSubmit, register, reset, control } = useForm({ headcount })

  const DATE_FORMAT = "YYYY-MM-DD"

  const defaultValues = useMemo(
    () => ({
      date: headcount
        ? dayjs.utc(headcount?.date).format(DATE_FORMAT)
        : dayjs.utc().format(DATE_FORMAT),
      total: headcount?.total,
      sales: headcount?.sales,
      new_hires: headcount?.new_hires,
      open_sales_roles: headcount?.open_sales_roles,
      us_remote_roles: headcount?.us_remote_roles,
    }),
    [headcount]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="date" className="form-label">
          Date
        </label>
        <Input
          type="date"
          name="date"
          label="Date"
          error={getErrors("date")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="total" className="form-label">
          Total
        </label>
        <Input
          type="number"
          name="total"
          label="Total"
          register={register}
          error={getErrors("total")[0]}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="sales" className="form-label">
          Sales Org HC
        </label>
        <Input
          type="number"
          name="sales"
          label="Sales Org HC"
          error={getErrors("sales")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="sales" className="form-label">
          New Hires
        </label>
        <Input
          type="number"
          name="new_hires"
          label="New Hires"
          error={getErrors("new_hires")[0]}
          register={register}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="sales" className="form-label">
          Open Sales Roles
        </label>
        <Input
          type="number"
          name="open_sales_roles"
          label="Open Sales Roles"
          error={getErrors("open_sales_roles")[0]}
          register={register}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="sales" className="form-label">
          Remote US Roles
        </label>
        <div className="w-50">
          <HeadcountsSelect
            name="us_remote_roles"
            control={control}
            className="headcounts-select"
            value={defaultValues.us_remote_roles}
            modalForm
          />
        </div>
      </div>
      <button className="btn btn-primary w-100">Save</button>
    </form>
  )
}

export default HeadcountForm
