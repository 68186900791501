import apiCall from "utils/api"

export const getCompanyPhotos = id => {
  return apiCall("get", `companies/${id}/photos`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCompanyPhoto = (companyId, params) => {
  const options = { data: { photo: params } }
  return apiCall("post", `companies/${companyId}/photos`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateCompanyPhoto = (id, params) => {
  const options = { data: { photo: params } }
  return apiCall("patch", `photos/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteCompanyPhoto = id => {
  return apiCall("delete", `photos/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
