import "./DailyUsers.scss"

const DailyUsers = ({ users }) => (
  <div className="card daily-users mb-3">
    <div className="card-body">
      <div className="daily-users-header">
        <h5>Daily Active Users</h5>
        <strong>{users?.toLocaleString()}</strong>
      </div>
    </div>
  </div>
)

export default DailyUsers
