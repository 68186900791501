import apiCall from "utils/api"

export const getQuestions = params => {
  const options = { params }
  return apiCall("get", `questions`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getQuestion = id => {
  return apiCall("get", `questions/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const publishQuestion = id => {
  return apiCall("post", `questions/${id}/publish`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateQuestion = (id, params) => {
  const options = { data: params }
  return apiCall("patch", `questions/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
    })
}

export const ignoreQuestion = (id, params) => {
  const options = { data: params }
  return apiCall("post", `questions/${id}/ignore`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const publishAnswer = id => {
  return apiCall("post", `answers/${id}/publish`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createQuestion = params => {
  const options = { data: params }
  return apiCall("post", `questions`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
