import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import Input from "components/forms/Input"
import TextArea from "components/forms/TextArea"

const CompanyBenefitForm = ({ onSubmit, benefit, company_benefit, errors }) => {
  const { handleSubmit, register, reset } = useForm({ company_benefit })

  const defaultValues = useMemo(
    () => ({
      active: company_benefit?.active,
      comment: company_benefit?.comment,
      metric_value: company_benefit?.value,
      benefit_id: benefit.id,
    }),
    [benefit, company_benefit]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        Updating <strong>{benefit.name}</strong> benefit.
      </p>

      <Input type="hidden" name="benefit_id" register={register} />

      <label htmlFor="count" className="form-label">
        Value
      </label>
      <div className="input-group mb-3">
        <Input
          type="number"
          name="metric_value"
          label="Value"
          error={getErrors("metric_value")[0]}
          register={register}
        />
        {benefit.metric_type && (
          <span className="input-group-text">{benefit.metric_type}</span>
        )}
      </div>

      <div className="mb-3">
        <label htmlFor="count" className="form-label">
          Comments
        </label>
        <TextArea
          name="comment"
          label="Comment"
          rows={4}
          error={getErrors("comment")[0]}
          register={register}
        />
      </div>

      <div className="form-check mb-3">
        <input
          id="active"
          name="active"
          className="form-check-input"
          type="checkbox"
          defaultChecked={company_benefit?.active}
          {...register("active")}
        />
        <label htmlFor="active" className="form-check-label">
          Active
        </label>
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default CompanyBenefitForm
