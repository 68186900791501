import Offcanvas from "react-bootstrap/Offcanvas"

import "./DetailSlideout.scss"

const DetailSlideout = ({ show, onHide, onExited, children, placement }) => {
  return (
    <Offcanvas
      className="detail-slideout"
      show={show}
      onHide={onHide}
      onExited={onExited}
      placement={placement}
    >
      {children}
    </Offcanvas>
  )
}

export default DetailSlideout
