import apiCall from "utils/api"

export const createComment = async (type, id, data) => {
  const options = { data }
  return apiCall("post", `${type}/${id}/comments`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
