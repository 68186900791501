import { useState } from "react"
import { useForm } from "react-hook-form"
import { createQuestion } from "api/questions"
import toast from "react-hot-toast"

import CompaniesSelect from "components/companies/Select"
import TextArea from "components/forms/TextArea"

import "./Form.scss"

const Form = ({ handleClose }) => {
  const [errors, setErrors] = useState(null)

  const { control, handleSubmit, register, watch } = useForm()

  const onSubmit = data => {
    const payload = { question: data }
    createQuestion(payload)
      .then(() => {
        toast.success("Question created successfully")
        handleClose()
      })
      .catch(error => setErrors(error.data))
  }

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="mb-3 d-flex flex-column align-items-center">
          <div className="w-100 mb-3">
            <label htmlFor="company" className="form-label">
              Company
            </label>
            <CompaniesSelect
              name="company_id"
              control={control}
              merge
              published
              error={getErrors("company")[0]}
            />
          </div>
          <div className="w-100 mb-2">
            <TextArea
              name="body"
              label="Question"
              rows={4}
              error={getErrors("body")[0]}
              register={register}
              watch={watch}
              maxLength={300}
            />
          </div>
        </section>
        <button type="submit" className="btn btn-primary w-100">
          Submit
        </button>
      </form>
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default Form
