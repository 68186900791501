import { useMemo, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { formatDate } from "utils/format"
import { updateUserLinkedinWorkExperience } from "api/users/linkedin_work_experiences"

import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import Icon from "components/Icon"
import CompanySelect from "components/companies/Select"

import "./WorkExperience.scss"

const WorkExperience = ({
  experience,
  userId,
  experiences,
  setExperiences,
}) => {
  const [errors, setErrors] = useState([])
  const [editExperience, setEditExperience] = useState(null)

  const { id, company, start_date, end_date, job_title, company_name } =
    experience

  const { control, reset, handleSubmit } = useForm()

  const defaultValues = useMemo(
    () => ({
      company_id: company?.id,
    }),
    [company]
  )

  const getCompany = () =>
    company ? (
      <Link to={`/companies/${company?.id}`}>{company?.name}</Link>
    ) : (
      String.fromCharCode(8212)
    )

  const getErrors = name => {
    return errors[name] || []
  }

  const handleUpdate = data => {
    const params = { linkedin_work_experience: data }
    updateUserLinkedinWorkExperience(userId, id, params)
      .then(response => {
        const newExperiences = [...experiences]
        const indexToUpdate = newExperiences.findIndex(
          experience => experience.id === response.data.id
        )
        newExperiences[indexToUpdate] = response.data
        setExperiences(newExperiences)
        toast.success("Experience updated successfully")
        setEditExperience(null)
      })
      .catch(response => {
        setErrors(response?.data)
        setEditExperience(null)
      })
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <tr key={id} className="work-experience">
      <td>{company_name}</td>
      <td>
        {editExperience ? (
          <CompanySelect
            name="company_id"
            control={control}
            value={company}
            error={getErrors("company")[0]}
          />
        ) : (
          getCompany()
        )}
      </td>
      <td>{job_title}</td>
      <td className="date">{formatDate(start_date)}</td>
      <td className="date">{formatDate(end_date)}</td>
      <td className="actions">
        <div className="d-flex justify-content-center">
          {editExperience ? (
            <div className="d-flex">
              <article className="btn" onClick={handleSubmit(handleUpdate)}>
                <Icon name="check" className="approve" />
              </article>
              <article className="btn" onClick={() => setEditExperience(null)}>
                <Icon name="times" className="cancel" />
              </article>
            </div>
          ) : (
            <button
              className="btn employer-util"
              onClick={() => setEditExperience(true)}
            >
              <Icon name="edit" />
            </button>
          )}
        </div>
      </td>
    </tr>
  )
}

export default WorkExperience
