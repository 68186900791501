import { useState, useEffect } from "react"
import { deleteCompanyVideo } from "api/companies/videos"
import { getCompanyVideos } from "api/companies/videos"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"

import Button from "react-bootstrap/Button"
import Icon from "components/Icon"
import NewVideo from "./New"
import EditVideo from "./Edit"
import EmptyVideos from "./Empty"
import Modal from "components/Modal"

const Videos = ({ id }) => {
  const [videos, setVideos] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [editing, setEditing] = useState({})
  const [loading, setLoading] = useState(true)

  const handleEdit = id => {
    const video = videos.find(video => video.id === id)
    setEditing(video)
    setShowEdit(true)
  }

  const handleDelete = id => {
    if (window.confirm(`Are you sure you want to delete this video?`)) {
      deleteCompanyVideo(id).then(() => {
        setVideos(videos.filter(video => video.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowEdit(false)
    setShowNew(false)
  }

  // use this to update the testimonial in state as opposed to refreshing all or using redux
  const replaceVideo = video => {
    const newVideos = [...videos]
    const index = newVideos.findIndex(t => t.id === video.id)
    newVideos[index] = video
    setVideos(newVideos)
  }

  const loadVideos = id => {
    getCompanyVideos(id)
      .then(response => {
        setVideos(response.data.videos)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => loadVideos(id), [id])

  if (loading) return <div />

  const Table = () => (
    <table className="table table-hover editable-table">
      <thead>
        <tr>
          <th className="w-25">Video</th>
          <th>Title</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {videos &&
          videos.map(video => {
            const { id, title, url, updated_at } = video

            return (
              <tr key={id}>
                <td>
                  <iframe
                    src={url}
                    alt={title}
                    title={`${title} ${id}`}
                    width={250}
                  />
                </td>
                <td>{title}</td>
                <td>{formatDateTime(updated_at)}</td>
                <td className="actions">
                  <button className="btn me-1" onClick={() => handleEdit(id)}>
                    <Icon name="edit" />
                  </button>
                  <button className="btn" onClick={() => handleDelete(id)}>
                    <Icon name="delete" />
                  </button>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )

  return (
    <>
      <div className="section-header">
        <h5>
          Videos
          <span>{`${videos.length} of 5 used`}</span>
        </h5>
        {videos.length < 5 && (
          <Button variant="primary" size="sm" onClick={() => setShowNew(true)}>
            Add Video
          </Button>
        )}
      </div>

      {!loading && videos.length > 0 ? <Table /> : <EmptyVideos />}

      <Modal
        title={showEdit ? "Edit Video" : "New Video"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewVideo
            companyId={id}
            handleSuccess={() => loadVideos(id)}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditVideo
            video={editing}
            handleSuccess={replaceVideo}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default Videos
