import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import qs from "qs"
import { registerAdmin } from "api/auth"
import "./SignIn.scss"

const AcceptInviteRoute = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { authenticated, currentAdmin } = useSelector(state => state.auth)

  const [password, setPassword] = useState("")
  const [password_confirmation, setPasswordConfirmation] = useState("")
  const [error, setError] = useState(false)

  const queryStr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const token = queryStr.invitation_token

  const handleSubmit = e => {
    e.preventDefault()
    setError(false)

    const params = {
      admin: {
        password,
        password_confirmation,
        invitation_token: token,
      },
    }

    dispatch(registerAdmin(params))
      .then(() => {})
      .catch(response => {
        console.error(response.data)
        setError(true)
      })
  }

  if (authenticated) {
    const path =
      currentAdmin.access_level === "full"
        ? location.state || "/dashboard"
        : "/operations"
    return <Navigate to={path} replace />
  }

  return (
    <div className="sign-in">
      <h5>Admin Register</h5>

      {error && <div className="alert alert-danger">Something went wrong</div>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Create a password
          </label>
          <input
            className="form-control"
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Confirm your password
          </label>
          <input
            className="form-control"
            type="password"
            id="password_confirmation"
            value={password_confirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
          />
        </div>
        <input
          className="btn btn-primary w-100"
          type="submit"
          value="Register"
        />
      </form>
    </div>
  )
}

export default AcceptInviteRoute
