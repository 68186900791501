import { Link } from "react-router-dom"
import Badge from "react-bootstrap/Badge"

import "./GridCompany.scss"

function GridCompany({ company }) {
  if (!company) return String.fromCharCode(8212)

  const { id, name, discarded_at } = company

  return (
    <div className="grid-company">
      <Link to={`/companies/${id}`}>{name}</Link>
      {discarded_at && <Badge bg="danger">Deleted</Badge>}
    </div>
  )
}

export default GridCompany
