import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Container } from "react-bootstrap"
import { getDashboardMetrics } from "api/dashboard"

import MetricCard from "components/dashboard/MetricCard"
import Header from "components/Header"
import Loading from "components/Loading"
import DashboardChart from "components/dashboard/Chart"
import RatingsGauge from "components/dashboard/RatingsGauge"
import DailyUsers from "components/dashboard/DailyUsers"
import MostRated from "components/dashboard/MostRated"
import Icon from "components/Icon"

import "./Dashboard.scss"

const DashboardRoute = () => {
  const [stats, setStats] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchDashboard = async () => {
    getDashboardMetrics()
      .then(response => {
        setStats(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchDashboard()
  }, [])

  const {
    all_ratings,
    month_ratings,
    today_ratings,
    all_reviews,
    month_reviews,
    today_reviews,
    ratings_per_hour,
    all_users,
    all_published_companies,
    month_published_companies,
    today_published_companies,
    most_rated_companies,
    all_jobs,
    engaged_candidates,
    hired_candidates,
    all_applications,
    month_users,
    today_users,
    daily_active_users,
    month_applications,
    today_applications,
  } = stats

  return (
    <>
      <div className="dashboard-header">
        <Header title="Dashboard" icon="gauge" />
      </div>
      <div className="dashboard content">
        {loading ? (
          <Loading />
        ) : (
          <Container fluid>
            <Row className="mb-2">
              <Col lg className="mb-2">
                <MetricCard
                  icon="ratings"
                  name="Ratings"
                  all={Number(all_ratings).toLocaleString()}
                  month={Number(month_ratings).toLocaleString()}
                  day={Number(today_ratings).toLocaleString()}
                />
              </Col>
              <Col lg className="mb-2">
                <MetricCard
                  icon="review"
                  name="Reviews"
                  all={Number(all_reviews).toLocaleString()}
                  month={Number(month_reviews).toLocaleString()}
                  day={Number(today_reviews).toLocaleString()}
                />
              </Col>
              <Col lg className="mb-2">
                <MetricCard
                  icon="user"
                  name="Users"
                  all={Number(all_users).toLocaleString()}
                  month={Number(month_users).toLocaleString()}
                  day={Number(today_users).toLocaleString()}
                />
              </Col>
              <Col lg className="mb-2">
                <MetricCard
                  icon="company"
                  name="Published Orgs"
                  all={Number(all_published_companies).toLocaleString()}
                  month={Number(month_published_companies).toLocaleString()}
                  day={Number(today_published_companies).toLocaleString()}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} xl={3} xxl={3}>
                <div className="mb-3">
                  <MetricCard
                    icon="application"
                    name="Job Applications"
                    all={Number(all_applications).toLocaleString()}
                    month={Number(month_applications).toLocaleString()}
                    day={Number(today_applications).toLocaleString()}
                  />
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    {all_jobs && (
                      <Link
                        className="stat"
                        to="/jobs?filterModel[status][values][]=Active&filterModel[status][filterType]=set"
                      >
                        <div className="stat-header strong">
                          <Icon name="job" />
                          Active Jobs
                        </div>
                        <h3>{Number(all_jobs).toLocaleString()}</h3>
                      </Link>
                    )}
                    {engaged_candidates && (
                      <Link
                        className="stat"
                        to="/job_applications?filterModel[job_applications.status][values][]=Engagement&filterModel[job_applications.status][filterType]=set"
                      >
                        <div className="stat-header">Engaged</div>
                        <h5>{Number(engaged_candidates).toLocaleString()}</h5>
                      </Link>
                    )}
                    {hired_candidates && (
                      <Link
                        className="stat"
                        to="/job_applications?filterModel[job_applications.status][values][]=Hire%20successful&filterModel[job_applications.status][filterType]=set"
                      >
                        <div className="stat-header font-sm">Hired</div>
                        <h5>{Number(hired_candidates).toLocaleString()}</h5>
                      </Link>
                    )}
                  </div>
                </div>

                <RatingsGauge speed={ratings_per_hour} />
                <DailyUsers users={daily_active_users} />
                <MostRated companies={most_rated_companies} />
              </Col>
              <Col lg={9} xl={9} xxl={9}>
                <DashboardChart />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  )
}

export default DashboardRoute
