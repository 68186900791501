import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import Input from "components/forms/Input"
import Video from "components/forms/Video"

const VideoForm = ({ onSubmit, video, errors }) => {
  const { handleSubmit, register, reset } = useForm({ video })

  const defaultValues = useMemo(
    () => ({
      title: video?.title,
      url: video?.url,
    }),
    [video]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <Video
          register={register}
          name="url"
          value={video?.url || undefined}
          error={getErrors("url")[0]}
        />
        <div id="companyVideoHelpBlock" className="form-text">
          URL must match format https://player.vimeo.com/video/...
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <Input
          type="text"
          name="title"
          label="Title"
          error={getErrors("title")[0]}
          register={register}
        />
      </div>
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default VideoForm
