import { useState, useEffect } from "react"
import { deleteCompanyPhoto } from "api/companies/photos"
import { getCompanyPhotos } from "api/companies/photos"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"
import Imgix from "react-imgix"

import Button from "react-bootstrap/Button"
import Icon from "components/Icon"
import NewPhoto from "./New"
import EditPhoto from "./Edit"
import EmptyPhotos from "./Empty"
import Modal from "components/Modal"

const Photos = ({ id }) => {
  const [photos, setPhotos] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [editing, setEditing] = useState({})
  const [loading, setLoading] = useState(true)

  const handleEdit = id => {
    const photo = photos.find(photo => photo.id === id)
    setEditing(photo)
    setShowEdit(true)
  }

  const handleDelete = id => {
    if (window.confirm(`Are you sure you want to delete this photo?`)) {
      deleteCompanyPhoto(id).then(() => {
        setPhotos(photos.filter(photo => photo.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowEdit(false)
    setShowNew(false)
  }

  // use this to update the testimonial in state as opposed to refreshing all or using redux
  const replacePhoto = photo => {
    const newPhotos = [...photos]
    const index = newPhotos.findIndex(t => t.id === photo.id)
    newPhotos[index] = photo
    setPhotos(newPhotos)
  }

  const loadPhotos = id => {
    getCompanyPhotos(id)
      .then(response => {
        setPhotos(response.data.photos)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => loadPhotos(id), [id])

  if (loading) return <div />

  const Table = () => (
    <table className="table table-hover editable-table">
      <thead>
        <tr>
          <th className="w-25">Photo</th>
          <th>Caption</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {photos &&
          photos.map(photo => {
            const { id, caption, file, updated_at } = photo

            return (
              <tr key={id}>
                <td>
                  {file ? (
                    <Imgix
                      src={`${process.env.REACT_APP_IMAGE_URL}/${file}?ar=4:3&fit=fill&fill=solid&fill-color=grey`}
                      alt={caption}
                      width={250}
                    />
                  ) : (
                    <Icon name="user" />
                  )}
                </td>
                <td>{caption}</td>
                <td>{formatDateTime(updated_at)}</td>
                <td className="actions">
                  <button className="btn me-1" onClick={() => handleEdit(id)}>
                    <Icon name="edit" />
                  </button>
                  <button className="btn" onClick={() => handleDelete(id)}>
                    <Icon name="delete" />
                  </button>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )

  return (
    <>
      <div className="section-header">
        <h5>
          Photos
          <span>{`${photos.length} of 5 used`}</span>
        </h5>
        {photos.length < 5 && (
          <Button variant="primary" size="sm" onClick={() => setShowNew(true)}>
            Add Photo
          </Button>
        )}
      </div>

      {!loading && photos.length > 0 ? <Table /> : <EmptyPhotos />}

      <Modal
        title={showEdit ? "Edit Photo" : "New Photo"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewPhoto
            companyId={id}
            handleSuccess={() => loadPhotos(id)}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditPhoto
            photo={editing}
            handleSuccess={replacePhoto}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default Photos
