import apiCall from "utils/api"

export const getCompanies = params => {
  const options = { params }
  return apiCall("get", `companies`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompaniesBasic = params => {
  const options = { params }
  return apiCall("get", `companies/basic`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompany = id => {
  return apiCall("get", `companies/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateCompany = (id, params) => {
  const options = { data: { company: params } }
  return apiCall("patch", `companies/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCompany = params => {
  const options = { data: params }
  return apiCall("post", `companies`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const publishCompany = (id, params) => {
  const options = { data: params }
  return apiCall("post", `companies/${id}/publish`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteCompany = id => {
  return apiCall("delete", `companies/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyAudits = id => {
  return apiCall("get", `companies/${id}/audits`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyBenefits = id => {
  return apiCall("get", `companies/${id}/benefits`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyRatings = (id, params) => {
  const options = { params }
  return apiCall("get", `companies/${id}/ratings`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyReviews = (id, params) => {
  const options = { params }
  return apiCall("get", `companies/${id}/reviews`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getCompanyOptions = field => {
  const options = { params: { field: field } }
  return apiCall("get", `companies/form_options`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const mergeCompanies = (id, params) => {
  const options = { params }
  return apiCall("post", `companies/${id}/merge`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateCompanyReviewDate = id => {
  return apiCall("patch", `companies/${id}/mark_as_reviewed`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
