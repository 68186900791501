import { useState, useEffect, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { getTechStackCompanies, getTechStack } from "api/tech_stack"
import { useForm } from "react-hook-form"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import Header from "components/Header"

import "./TechStack.scss"

const TechStackRoute = () => {
  const { id } = useParams()

  const [techStackCompanies, setTechStackCompanies] = useState({})
  const [techStack, setTechStack] = useState({})

  const { handleSubmit, reset } = useForm()

  dayjs.extend(utc)

  const defaultValues = useMemo(
    () => ({
      company_id: null,
    }),
    []
  )

  const fetchAssignment = id => {
    getTechStackCompanies(id)
      .then(response => {
        setTechStackCompanies(response.data.companies)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const fetchStack = id => {
    getTechStack(id)
      .then(response => {
        setTechStack(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchAssignment(id)
    fetchStack(id)
  }, [id])

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const pageTitle = `${techStack?.tech_stack_vendor?.name || ""}: ${
    techStack?.tech_stack_category?.name || ""
  }`

  return (
    <>
      <Header title={pageTitle} icon="techStacks" />
      <div className="content">
        <h4>Companies</h4>
        <form
          onSubmit={handleSubmit(() =>
            console.log("Add and edit coming soon.")
          )}
        >
          <table className="table assignments-table table-hover">
            <thead>
              <tr className="table-header">
                <th>Name</th>
                <th className="text-end">Ratings</th>
              </tr>
            </thead>
            <tbody>
              {techStackCompanies.length > 0 &&
                techStackCompanies?.map(company => {
                  const { id, name, ratings_count } = company

                  return (
                    <tr key={company.id}>
                      <td className="w-50">
                        <Link to={`/companies/${id}`}>{name}</Link>
                      </td>
                      <td className="text-end w-25">{ratings_count}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </form>
      </div>
    </>
  )
}

export default TechStackRoute
