import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import Input from "components/forms/Input"

const CategoryForm = ({ onSubmit, category, errors }) => {
  const { handleSubmit, register, reset } = useForm({ category })

  const defaultValues = useMemo(
    () => ({
      name: category?.name,
    }),
    [category]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Category Name
        </label>
        <Input
          type="text"
          name="name"
          label="Category Name"
          error={getErrors("name")[0]}
          register={register}
        />
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default CategoryForm
