import { useEffect, useState } from "react"
import { getCompanyAttributeSelectStyles } from "components/companies/companyAttributeSelectStyles"

import { Controller } from "react-hook-form"
import Select from "react-select"

const TypeSelect = ({
  className,
  name,
  label,
  error,
  value,
  merge,
  modalForm,
  ...rest
}) => {
  const TYPES = ["Actual", "Guidance"]

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (value) {
      setSelected({ name: value, value: value })
    }
  }, [value])

  const customStyles = getCompanyAttributeSelectStyles(modalForm)

  return (
    <>
      <Controller
        name={name}
        {...rest}
        render={({ field: { onChange } }) => {
          return (
            <Select
              className={`${className || ""} ${error ? "is-invalid" : ""}`}
              isClearable={true}
              aria-label={label}
              styles={customStyles}
              defaultOptions
              getOptionValue={option => option.value}
              getOptionLabel={option => option.name}
              options={TYPES.map(type => ({
                name: type,
                value: type,
              }))}
              defaultValue={selected}
              value={selected}
              onChange={option => {
                onChange(option?.value)
                setSelected(option)
              }}
            />
          )
        }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}

export default TypeSelect
