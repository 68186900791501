import { useState, useEffect } from "react"
import {
  getEmployerCustomer,
  approveEmployerCustomer,
  denyEmployerCustomer,
} from "api/customers"
import { formatDateTime } from "utils/format"
import toast from "react-hot-toast"

import { Badge, Button, Offcanvas, Tabs, Tab } from "react-bootstrap"
import { Link } from "react-router-dom"
import DetailSlideout from "components/shared/DetailSlideout"
import CustomerIntegration from "./Integration"
import StatusBadge from "components/customers/StatusBadge"
import Icon from "components/Icon"
import Comments from "components/comments"
import Audits from "components/shared/Audits"

const CustomerDetail = ({ id, show, handleClose, setGridRecord }) => {
  const [customer, setCustomer] = useState({})
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState()
  const [showIntegration, setShowIntegration] = useState(false)

  const {
    access_type,
    company,
    employers,
    job_board,
    number_of_active_jobs,
    status,
    comments,
    audits,
  } = customer
  const { name, integration } = company || {}
  const { applicant_method, tech_stack_vendor } = job_board || {}

  const prependComment = comment => {
    setCustomer({ ...customer, comments: [comment, ...comments] })
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      getEmployerCustomer(id)
        .then(response => {
          setCustomer(response.data)
          setLoading(false)
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }, [id])

  const handleApprove = () => {
    if (
      window.confirm(
        "Are you sure you want to approve this customer? An email will be sent to the employer."
      )
    ) {
      approveEmployerCustomer(id)
        .then(response => {
          setCustomer(response.data)
          setGridRecord(response.data)
          toast.success("Customer approved")
        })
        .catch(() => {})
    }
  }

  const handleDeny = () => {
    if (
      window.confirm(
        "Are you sure you want to deny this customer? An email will be sent to the employer."
      )
    ) {
      denyEmployerCustomer(id)
        .then(response => {
          setCustomer(response.data)
          setGridRecord(response.data)
          toast.success("Customer denied")
        })
        .catch(() => {})
    }
  }

  const showApprove = status === "Pending" || status === "Denied"
  const showDeny = status === "Pending"

  if (loading) {
    return <div />
  }

  return (
    <DetailSlideout show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="d-flex align-items-center">
          {name}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column">
        <Tabs
          id="review-tabs"
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          className="mb-2"
          transition={false}
        >
          <Tab eventKey="details" title="Details">
            <div className="detail-body">
              <section>
                <div className="section-header">
                  <label>Status</label>
                  <StatusBadge status={status} />
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>Tier</td>
                      <td>{access_type}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section>
                <div className="section-header">
                  <label>
                    <Icon name="company" /> Company
                  </label>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>Company</td>
                      <td>
                        <Link
                          to={`/companies/${company.id}`}
                          onClick={handleClose}
                        >
                          {name}
                        </Link>
                      </td>
                    </tr>
                    {integration && (
                      <>
                        <tr>
                          <td>
                            <button
                              className="btn btn-link btn-sm p-0"
                              onClick={() =>
                                setShowIntegration(!showIntegration)
                              }
                            >
                              Integration
                            </button>
                          </td>
                          <td>
                            <div className="customer-integration d-flex justify-content-end">
                              <span>{integration?.name}</span>
                              {integration?.enabled ? (
                                <div className="pulse">
                                  <div className="ring"></div>
                                  <div className="circle"></div>
                                </div>
                              ) : (
                                <b>&bull;</b>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr
                          className={
                            Boolean(showIntegration) ? "d-table-row" : "d-none"
                          }
                        >
                          <td colSpan="2">
                            <CustomerIntegration integration={integration} />
                          </td>
                        </tr>
                      </>
                    )}
                    {number_of_active_jobs && (
                      <tr>
                        <td>Indicated Active Jobs</td>
                        <td>{number_of_active_jobs}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>
              {job_board && (
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="job" /> Job Board
                    </label>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>Applicant Method</td>
                        <td>{applicant_method}</td>
                      </tr>
                      {tech_stack_vendor && (
                        <tr>
                          <td>Tech Stack Vendor</td>
                          <td>{tech_stack_vendor.name}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </section>
              )}
              <section>
                <div className="section-header">
                  <label>
                    <Icon name="employers" /> Employer User(s)
                  </label>
                </div>
                <table>
                  <tbody>
                    {employers.map(employer => (
                      <tr key={employer.id}>
                        <td>
                          {employer.discarded_at ? (
                            <span className="d-flex align-items-center">
                              {employer.email}
                              <Badge bg="danger" className="deleted-badge">
                                Deleted
                              </Badge>
                            </span>
                          ) : (
                            <span className="d-flex align-items-center">
                              <span>{employer.email}</span>
                              {employer.pending && (
                                <Badge pill bg="info" text="dark">
                                  Invite Pending
                                </Badge>
                              )}
                            </span>
                          )}
                        </td>
                        <td>{employer.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
              <section>
                <div className="section-header">
                  <label>
                    <Icon name="info" /> Dates
                  </label>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>Created</td>
                      <td>{formatDateTime(customer.created_at)}</td>
                    </tr>
                    <tr>
                      <td>Signed</td>
                      <td>{formatDateTime(customer.signed_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
            <div className="detail-footer">
              <div className="detail-footer-actions">
                {showApprove && (
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={() => handleApprove()}
                  >
                    Approve
                  </Button>
                )}
                {showDeny && (
                  <Button
                    variant="danger"
                    className="w-100"
                    onClick={() => handleDeny()}
                  >
                    Deny
                  </Button>
                )}
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="comments"
            title={
              <>
                Comments
                {comments?.length > 0 && (
                  <span className="badge rounded-pill bg-secondary">
                    {comments.length}
                  </span>
                )}
              </>
            }
          >
            <Comments
              id={id}
              type="customers"
              comments={comments}
              addComment={prependComment}
            />
          </Tab>
          <Tab
            eventKey="audits"
            title={
              <>
                Audits
                {audits?.length > 0 && (
                  <span className="badge rounded-pill bg-secondary">
                    {audits.length}
                  </span>
                )}
              </>
            }
          >
            <Audits audits={audits} />
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </DetailSlideout>
  )
}

export default CustomerDetail
