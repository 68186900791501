import React from "react"
import Icon from "components/Icon"

const EmptyVideos = () => (
  <div className="empty-section">
    <Icon name="video" />
    <p className="text-muted">No videos</p>
  </div>
)

export default EmptyVideos
