import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import {
  createCompanyBusinessUnit,
  updateCompanyBusinessUnit,
} from "api/companies/business_unit"
import toast from "react-hot-toast"

import Input from "components/forms/Input"

const BusinessUnitForm = ({
  business_unit,
  company,
  handleSuccess,
  handleClose,
}) => {
  const { handleSubmit, register, reset } = useForm({ business_unit })

  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    if (business_unit) {
      updateCompanyBusinessUnit(company.id, business_unit.id, data)
        .then(response => {
          toast.success("Update successful")
          handleSuccess(response.data, true)
          handleClose()
        })
        .catch(response => {
          toast.error("Something went wrong")
          setErrors(response?.data)
        })
    } else {
      createCompanyBusinessUnit(company.id, data)
        .then(response => {
          toast.success("Add successful")
          handleSuccess(response.data)
          handleClose()
        })
        .catch(response => {
          toast.error("Something went wrong")
          setErrors(response?.data)
        })
    }
  }

  const defaultValues = useMemo(
    () => ({
      name: business_unit?.name,
      description: business_unit?.description,
    }),
    [business_unit]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <Input
            type="text"
            name="name"
            label="Name"
            error={getErrors("name")[0]}
            register={register}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Description
          </label>
          <textarea
            className="form-control form-control-sm"
            rows="4"
            {...register("description")}
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Save
        </button>
      </form>
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default BusinessUnitForm
