import { formatDateTime, formatDate } from "utils/format"

export const AgGridHyperlink = (data, external = false) => {
  if (data) {
    const target = external ? "_blank" : null
    const link = (
      <a href={data} target={target} rel="noreferrer">
        {data}
      </a>
    )
    return link
  }

  return String.fromCharCode(8212)
}

export const AgGridDateTime = data => {
  return data ? <div>{formatDateTime(data)}</div> : String.fromCharCode(8212)
}

export const AgGridDate = data => {
  return data ? <div>{formatDate(data)}</div> : String.fromCharCode(8212)
}

export const AgGridCommaSeparatedNumber = ({ data }) => {
  return <div>{data.toLocaleString("en-US")}</div>
}
