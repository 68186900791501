import Badge from "react-bootstrap/Badge"

function StatusBadge({ status, className }) {
  let variant
  switch (status?.toLowerCase()) {
    case "approved":
      variant = "success"
      break
    case "rejected":
      variant = "danger"
      break
    default:
      variant = "secondary"
  }

  return (
    <Badge bg={variant} className={`response-status-badge ${className}`}>
      {status}
    </Badge>
  )
}

export default StatusBadge
