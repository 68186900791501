import React, { useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import { uploadImage } from "api/images"
import Imgix from "react-imgix"

import Input from "components/forms/Input"
import Icon from "components/Icon"
import "./ImageUpload.scss"

const baseStyle = {
  alignItems: "center",
  cursor: "pointer",
  borderWidth: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  margin: "0 auto 10px",
  transition: "border .24s ease-in-out",
}

const focusedStyle = {
  borderColor: "#027597",
}

const ImageUpload = ({
  handleChange,
  name,
  value,
  type,
  register,
  ...rest
}) => {
  const [preview, setPreview] = useState(null)

  const onDrop = acceptedFiles => {
    const file = acceptedFiles[0]
    setPreview(URL.createObjectURL(file))
    uploadImage(file).then(blob => {
      handleChange(name, blob.signed_id)
    })
  }

  const { getRootProps, getInputProps, isFocused } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    maxFiles: 1,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
    }),
    [isFocused]
  )

  const placeholder = useMemo(() => {
    if (preview) {
      return (
        <img
          src={preview}
          onLoad={() => {
            URL.revokeObjectURL(preview)
          }}
          alt="preview"
        />
      )
    }
    if (value) {
      /* max width for any of the images in forms */
      return <Imgix src={value} alt="existing" width={250} />
    }
    return (
      <>
        <input {...getInputProps()} />
        <Icon name={type} />
      </>
    )
  }, [preview, value, type, getInputProps])

  return (
    <>
      <div {...getRootProps({ style })} className={`image-upload ${type}`}>
        {placeholder}
      </div>
      <Input type="hidden" name={name} register={register} {...rest} />
    </>
  )
}

export default ImageUpload
