import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { signOut } from "api/auth"
import logo from "assets/repvue.png"

import AccordionMenuItem from "./sidebar/AccordionItem"
import Icon from "components/Icon"

import "./Sidebar.scss"

const Sidebar = () => {
  const { currentAdmin } = useSelector(state => state.auth)
  const { access_level } = currentAdmin

  const dispatch = useDispatch()
  let location = useLocation()

  const restricted = access_level !== "full"
  const restrictedRoutes = ["Users", "Customers", "Jobs", "User Support"]

  const menuItems = [
    {
      title: "Analytics",
      path: null,
      icon: "gauge",
      subItems: [
        { title: "Dashboard", path: "/dashboard", routeRestricted: restricted },
        { title: "Operations Activity", path: "/operations" },
        { title: "Sigma Reporting", path: "/sigma" },
      ],
    },
    {
      title: "Users",
      path: null,
      icon: "users",
      subItems: [
        { title: "All Users", path: "/users" },
        { title: "New to Sales", path: "/users/new_to_sales" },
        { title: "Employers", path: "/employers" },
        { title: "Data Users", path: "/data_users" },
        { title: "Admins", path: "/admins" },
        { title: "Deleted Users", path: "/users/deleted" },
      ],
    },
    {
      title: "Companies",
      path: "/companies",
      icon: "company",
    },
    {
      title: "Ratings",
      path: null,
      icon: "ratings",
      subItems: [
        { title: "All Ratings", path: "/ratings" },
        { title: "Rating Values", path: "/ratings/values" },
        { title: "Deleted Ratings", path: "/ratings/deleted" },
        { title: "Reviews", path: "/reviews", routeRestricted: restricted },
      ],
    },
    {
      title: "Jobs",
      path: null,
      icon: "job",
      subItems: [
        { title: "Job Posts", path: "/jobs" },
        { title: "Job Applications", path: "/job_applications" },
        { title: "Ignored Jobs", path: "/jobs/ignored" },
      ],
    },
    {
      title: "Customers",
      path: "null",
      icon: "customer",
      subItems: [
        { title: "Employer Customers", path: "/customers/employers" },
        { title: "Data Customers", path: "/customers/data" },
      ],
    },
    {
      title: "User Support",
      path: null,
      icon: "support",
      subItems: [
        { title: "Candidate Profiles", path: "/candidate_profiles" },
        { title: "Q&A", path: "/questions" },
        { title: "References", path: "/references" },
        { title: "Referrals", path: "/referrals" },
        { title: "Rewards", path: "/rewards" },
      ],
    },
    {
      title: "Settings",
      path: null,
      icon: "settings",
      subItems: [
        {
          title: "Locations",
          path: "/metro_locations",
          routeRestricted: restricted,
        },
        { title: "Tags", path: "/tags", routeRestricted: restricted },
        { title: "Tech Stacks", path: "/tech_stacks" },
      ],
    },
  ]

  return (
    <nav className="position-fixed sidebar overflow-scroll">
      <Link to="/" className="navbar-brand mx-auto mb-4">
        <img src={logo} alt="RepVue" />
      </Link>
      <ul className="navbar-nav w-100">
        {menuItems.map(item => {
          const { title, path, icon, subItems } = item
          if (restrictedRoutes.includes(title) && restricted) return null

          return !subItems ? (
            <li className="nav-item" key={title}>
              <Link
                to={path}
                className={`${location.pathname.match(path) ? "active" : ""}`}
              >
                <Icon name={icon} />
                {title}
              </Link>
            </li>
          ) : (
            <AccordionMenuItem
              item={item}
              eventKey={menuItems.indexOf(item)}
              key={title}
            />
          )
        })}
      </ul>

      <div className="navbar-bottom w-100">
        <button className="btn" onClick={() => dispatch(signOut())}>
          <Icon name="signOut" />
          Sign Out
        </button>
      </div>
    </nav>
  )
}

export default Sidebar
