import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getCandidateProfiles } from "api/candidate_profiles"
import { AgGridDateTime } from "utils/cellRenderers"
import { setQueryParams, getInitialParams } from "utils/query"
import { filterParams } from "utils/filters"

import Badge from "react-bootstrap/Badge"

import Header from "components/Header"
import GridUser from "components/users/GridUser"
import AgGrid from "components/ag_grid/AgGrid"
import SearchInput from "components/forms/SearchInput"
import CandidateProfileDetail from "components/candidate_profiles/Detail"

const CandidateProfilesRoute = () => {
  const gridRef = useRef()
  const { id } = useParams()
  const navigate = useNavigate()
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""
  const [showDetail, setShowDetail] = useState(undefined)
  const [search, setSearch] = useState(initialSearch)

  const [columnDefs] = useState([
    {
      field: "candidate_profiles.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
      cellRenderer: ({ data }) => data.id,
    },
    {
      field: "users.name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => <GridUser user={data.user} />,
    },
    {
      field: "candidate_sales_experiences.company_name",
      headerName: "Latest Company",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.latest_company_name || String.fromCharCode(8212),
    },
    {
      field: "candidate_sales_experiences.job_title",
      headerName: "Latest Title",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.latest_job_title || String.fromCharCode(8212),
    },
    {
      field: "job_preferences.search_status",
      headerName: "Search Status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Actively Looking", "Open to Opportunities", "Not Looking"],
      },
      cellRenderer: ({ data }) =>
        data.search_status || String.fromCharCode(8212),
    },
    {
      field: "job_preferences.years_selling_experience",
      headerName: "Total Exp (Years)",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        data.years_selling_experience || String.fromCharCode(8212),
    },
    {
      field: "job_preferences.years_closing_experience",
      headerName: "Closing Exp (Years)",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) =>
        data.years_closing_experience || String.fromCharCode(8212),
    },
    {
      field: "candidate_profiles.published_at",
      headerName: "Published",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      sortable: false,
      width: 140,
      cellRenderer: ({ data }) => {
        const variant = data.published ? "success" : "danger"
        const text = data.published ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
    },
    {
      field: "metro_locations.name",
      headerName: "Preferred Location(s)",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) =>
        data.metro_locations.map(location => location.name).join(", ") ||
        String.fromCharCode(8212),
    },
    {
      field: "candidate_profiles.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "candidate_profiles.updated_at",
      headerName: "Updated",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.updated_at),
      hide: true,
    },
  ])

  const hideDetail = () => {
    setShowDetail(undefined)
    navigate(`/candidate_profiles`)
  }

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/candidate_profiles/${e.data.id}`)
  }

  useEffect(() => {
    if (id) {
      setShowDetail(id)
    } else {
      setShowDetail(undefined)
    }
  }, [id])

  return (
    <>
      <Header
        title="Candidate Profiles"
        icon="candidateProfiles"
        className="has-search"
      >
        <SearchInput
          value={search}
          placeholder={"Search candidate profiles..."}
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getCandidateProfiles}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
        />
      </div>
      <CandidateProfileDetail
        id={showDetail}
        show={Boolean(showDetail)}
        handleClose={hideDetail}
      />
    </>
  )
}

export default CandidateProfilesRoute
