import "./Integration.scss"

const CustomerIntegration = ({ integration }) => {
  const prettify = str => {
    return str.replace(/(_|^)([^_]?)/g, function (_, prep, letter) {
      return (prep && " ") + letter.toUpperCase()
    })
  }

  const { credentials } = integration

  let credentialItem = []

  for (const credential in credentials) {
    credentialItem.push({ id: credential, value: credentials[credential] })
  }

  return (
    <div className="customer-integration-details">
      <h6>Credentials</h6>
      <table>
        <tbody>
          {credentialItem.map(credential => (
            <tr key={credential.id}>
              <td className="text-start">{prettify(credential.id)}</td>
              <td className="font-monospace text-end">{credential.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CustomerIntegration
