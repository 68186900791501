import { useState, useRef } from "react"
import { getMetroLocations } from "api/metro_locations"
import { setQueryParams, getInitialParams } from "utils/query"
import { deleteMetroLocation } from "api/metro_locations"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

import Button from "react-bootstrap/Button"
import Modal from "components/Modal"
import NewMetroLocation from "components/metro_locations/New"
import EditMetroLocation from "components/metro_locations/Edit"
import Icon from "components/Icon"
import SearchInput from "components/forms/SearchInput"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"
import TypeBadge from "components/metro_locations/TypeBadge"

const MetroLocationsRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [showNew, setShowNew] = useState(false)
  const [showEdit, setShowEdit] = useState(null)
  const [search, setSearch] = useState(initialSearch)
  const [watchedValues, setWatchedValues] = useState(null)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const closeModal = () => {
    setShowNew(false)
    setShowEdit(false)
  }

  const handleEdit = data => setShowEdit(data)
  const handleDelete = data => {
    if (
      window.confirm(`Are you sure you want to delete this metro location?`)
    ) {
      deleteMetroLocation(data.id).then(() => {
        setWatchedValues(data)
        toast.success("Delete successful")
        window.location.reload()
      })
    }
  }

  const handleEditSuccess = vendor => {
    setUpdatedRecord(vendor)
    closeModal()
  }

  const [columnDefs] = useState([
    {
      field: "metro_locations.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      width: 120,
      cellRenderer: ({ data }) => data.id,
      hide: true,
    },
    {
      field: "metro_locations.name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.name || String.fromCharCode(8212),
      width: 280,
    },
    {
      field: "metro_locations.label",
      headerName: "Label",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.label || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "metro_locations.type",
      headerName: "Type",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["city", "metro", "state"],
      },
      cellRenderer: ({ data }) => <TypeBadge type={data.type} />,
      width: 120,
    },
    {
      field: "metro_locations.name",
      headerName: "Parent",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.parent?.name || String.fromCharCode(8212),
    },
    {
      field: "states.name",
      headerName: "State",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.state?.name || String.fromCharCode(8212),
    },
    {
      field: "countries.name",
      headerName: "Country",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data.country?.name || String.fromCharCode(8212),
    },
    {
      field: "active_jobs_count",
      headerName: "Active Jobs",
      cellStyle: { justifyContent: "flex-end" },
      width: 120,
      filter: "agNumberColumnFilter",
    },
    {
      field: "company_locations_count",
      headerName: "Companies",
      cellStyle: { justifyContent: "flex-end" },
      width: 120,
      filter: "agNumberColumnFilter",
    },
    {
      field: "actions",
      headerName: "Edit / Delete",
      filter: false,
      suppressMenu: true,
      headerClass: "ag-center-aligned",
      cellClass: "justify-content-center",
      cellRenderer: ({ data }) => (
        <>
          <button className="btn" onClick={() => handleEdit(data)}>
            <Icon name="edit" />
          </button>
          <button className="btn" onClick={() => handleDelete(data)}>
            <Icon name="delete" />
          </button>
        </>
      ),
    },
  ])

  const gridRef = useRef()
  const navigate = useNavigate()

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/metro_locations/${e.data.id}`)
  }

  return (
    <>
      <Header title="Locations" icon="location" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search locations..."
          handleSearch={handleSearch}
        />
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Add Location
        </Button>
      </Header>
      <div className="grid-wrapper editable-table">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getMetroLocations}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
          watchedValues={watchedValues}
          onCellClicked={onCellClicked}
          sizeToFit
        />
      </div>
      <Modal
        title={showEdit ? "Edit Location" : "New Location"}
        show={showEdit || showNew}
        handleClose={closeModal}
      >
        {showNew && (
          <NewMetroLocation
            handleSuccess={setWatchedValues}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditMetroLocation
            location={showEdit}
            handleSuccess={handleEditSuccess}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default MetroLocationsRoute
