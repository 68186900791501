import React, { useEffect, useState } from "react"
import { getJobOptions } from "api/jobs"

import Select from "components/forms/Select"

const EnumSelect = ({ field, ...props }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    getJobOptions(field).then(response => {
      // response is enum key/value pairs; map to options with id/name
      // don't need the key, just the value
      const options = Object.entries(response.data).map(([_, v]) => {
        return { id: v, name: v }
      })
      setOptions(options)
    })
  }, [field])

  return <Select options={options} {...props} />
}

export default EnumSelect
