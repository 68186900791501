import { useState, useRef } from "react"
import { getDataUsers, deleteDataUser } from "api/data_users"
import { Link } from "react-router-dom"
import { setQueryParams, getInitialParams } from "utils/query"
import { AgGridDateTime } from "utils/cellRenderers"
import toast from "react-hot-toast"
import { Badge, Button } from "react-bootstrap"

import SearchInput from "components/forms/SearchInput"
import Icon from "components/Icon"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"
import Modal from "components/Modal"
import NewDataUser from "components/data_users/New"
import EditDataUser from "components/data_users/Edit"

import "./DataUsers.scss"

const DataUsersRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const [columnDefs] = useState([
    {
      field: "data_users.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      width: 100,
      hide: true,
      cellRenderer: ({ data }) => {
        return data.id || String.fromCharCode(8212)
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.first_name || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.last_name || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <span className="d-flex align-items-center justify-content-between">
          <span>{data.email}</span>
          {data.pending && (
            <Badge pill bg="info" text="dark">
              Invite Pending
            </Badge>
          )}
          {data.discarded_at && (
            <Badge bg="danger" className="deleted-badge">
              Deleted
            </Badge>
          )}
        </span>
      ),
    },
    {
      field: "data_customers.name",
      headerName: "Data Customer",
      width: 130,
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/customers/data/${data.data_customer.id}`}>
          {data.data_customer.name}
        </Link>
      ),
    },
    {
      field: "data_users.role",
      headerName: "Role",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Admin", "Analyst", "RepVue Internal"],
      },
      cellRenderer: ({ data }) => data.role,
    },
    {
      field: "data_users.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "data_users.last_active_at",
      headerName: "Last Active",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.last_active_at),
    },
    {
      field: "actions",
      headerName: "Edit",
      filter: false,
      suppressMenu: true,
      width: 120,
      headerClass: "ag-center-aligned",
      cellRenderer: ({ data }) => (
        <>
          <button className="btn" onClick={() => handleEdit(data)}>
            <Icon name="edit" />
          </button>
          <button className="btn" onClick={() => handleDelete(data)}>
            <Icon name="delete" />
          </button>
        </>
      ),
    },
  ])
  const [showNew, setShowNew] = useState(false)
  const [showEdit, setShowEdit] = useState(null)

  const handleEdit = dataUser => {
    setShowEdit(dataUser)
  }

  const handleDelete = data => {
    if (window.confirm(`Are you sure you want to delete this data user?`)) {
      deleteDataUser(data.id)
        .then(res => {
          toast.success("Delete successful")
          setUpdatedRecord(res.data)
        })
        .catch(error => console.error(error))
    }
  }

  const gridRef = useRef()

  const handleSearch = q => {
    setSearch(q)
  }

  const handleSuccess = data => {
    setUpdatedRecord(data)
    closeModal()
  }

  const closeModal = () => {
    setShowNew(false)
    setShowEdit(null)
  }

  return (
    <>
      <Header title="Data Users" icon="employers" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search data users..."
          handleSearch={handleSearch}
        />
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Invite Data User
        </Button>
      </Header>
      <div className="grid-wrapper editable-table">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getDataUsers}
          search={search}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          updatedRecord={updatedRecord}
          sizeToFit
        />
      </div>
      <Modal
        title={showNew ? "Invite Data User" : "Edit Data User"}
        show={showNew || showEdit}
        handleClose={closeModal}
      >
        {showNew && (
          <NewDataUser
            handleSuccess={handleSuccess}
            handleClose={() => setShowNew(false)}
          />
        )}
        {showEdit && (
          <EditDataUser
            handleSuccess={handleSuccess}
            dataUser={showEdit}
            handleClose={closeModal}
          />
        )}
      </Modal>
    </>
  )
}

export default DataUsersRoute
