import apiCall from "utils/api"

export const getStates = params => {
  const options = { params }
  return apiCall("get", `states?startRow=0&endRow=100`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
