import { useState, useRef } from "react"
import { getDeletedUsers } from "api/users"
import { useNavigate } from "react-router-dom"
import { setQueryParams, getInitialParams } from "utils/query"
import { AgGridHyperlink, AgGridDateTime } from "utils/cellRenderers"
import { undiscardUser } from "api/users"
import toast from "react-hot-toast"

import SearchInput from "components/forms/SearchInput"
import Header from "components/Header"
import AgGrid from "components/ag_grid/AgGrid"

const DeletedUsersRoute = () => {
  const initialParams = getInitialParams()
  const initialSearch = initialParams.q || ""

  const [search, setSearch] = useState(initialSearch)
  const [watchedValue, setWatchedValue] = useState(null)

  const handleUndelete = user => {
    if (window.confirm(`Are you sure you want to restore ${user.name}?`)) {
      undiscardUser(user.id).then(res => {
        setWatchedValue(res.data)
        toast.success("Restore successful")
      })
    }
  }

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      width: 120,
    },
    {
      field: "first_name",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.first_name || String.fromCharCode(8212),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.last_name || String.fromCharCode(8212),
    },
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
    },
    {
      field: "linkedin_url",
      headerName: "LinkedIn",
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: ({ data }) => AgGridHyperlink(data.linkedin_url, true),
      externalURL: true,
    },
    {
      field: "ratings_count",
      headerName: "Ratings Count",
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
    },
    {
      field: "country",
      headerName: "Country",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.country || String.fromCharCode(8212),
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "discarded_at",
      headerName: "Deleted",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.discarded_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      suppressMenu: true,
      headerClass: "ag-center-aligned",
      cellClass: "justify-content-center",
      width: 130,
      cellRenderer: ({ data }) => (
        <button
          className="btn btn-primary employer-util btn-sm"
          onClick={() => handleUndelete(data)}
        >
          Restore
        </button>
      ),
    },
  ])

  const navigate = useNavigate()
  const gridRef = useRef()

  const handleSearch = q => {
    setSearch(q)
  }

  const onCellClicked = e => {
    navigate(`/users/${e.data.id}`)
  }

  return (
    <>
      <Header title="Deleted Users" icon="users" className="has-search">
        <SearchInput
          value={search}
          placeholder="Search users..."
          handleSearch={handleSearch}
        />
      </Header>
      <div className="grid-wrapper">
        <AgGrid
          onCellClicked={onCellClicked}
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getDeletedUsers}
          search={search}
          setQueryParams={setQueryParams}
          watchedValues={watchedValue}
        />
      </div>
    </>
  )
}

export default DeletedUsersRoute
