import { useEffect, useState } from "react"
import { getSellingPersonas } from "api/selling_personas"

import Select from "components/forms/Select"

const SellingPersonasSelect = ({ ...props }) => {
  const [sellingPersonas, setSellingPersonas] = useState([])

  useEffect(() => {
    getSellingPersonas().then(response => {
      setSellingPersonas(response.data.selling_personas)
    })
  }, [])

  return <Select options={sellingPersonas} isMulti={true} {...props} />
}

export default SellingPersonasSelect
