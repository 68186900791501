import React, { useState } from "react"
import toast from "react-hot-toast"
import { updateFinancialMetric } from "api/companies/financial_metrics"

import Form from "./Form"

import "./Form.scss"

const EditFinancialMetric = ({
  financial_metric,
  handleSuccess,
  handleClose,
}) => {
  const [errors, setErrors] = useState({})

  const onSubmit = data => {
    return updateFinancialMetric(financial_metric.id, data)
      .then(response => {
        toast.success("Update successful")
        handleSuccess(response.data)
        handleClose()
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        financial_metric={financial_metric}
        errors={errors}
      />
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default EditFinancialMetric
