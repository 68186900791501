import { useState } from "react"
import { capitalizeString } from "utils/string"

import { Form, Button } from "react-bootstrap"
import Modal from "components/Modal"

const IgnoreModal = ({ data, show, handleClose, handleSubmit, type }) => {
  const [ignoreReason, setIgnoreReason] = useState("policy_violation") // first option is default

  const onSubmit = e => {
    e.preventDefault()
    handleSubmit({ ignore_reason: ignoreReason })
  }

  const capitalizedType = capitalizeString(type)

  const buttonText =
    ignoreReason === "policy_violation"
      ? `Ignore ${capitalizedType} & Notify User`
      : `Ignore ${capitalizedType}`

  const answerOrReply = type === "answer" || type === "reply"

  return (
    <Modal
      title={`Ignore ${capitalizedType}`}
      handleClose={handleClose}
      show={show}
    >
      <p>
        {`Are you sure you want to ignore this ${type}`} from
        {data?.author || data?.author?.name ? " user" : ""}{" "}
        <strong>{data?.author?.name || data?.author || "AI"}</strong>?
      </p>

      <Form onSubmit={onSubmit}>
        <h6>{`Select a reason for ignoring this ${type}.`}</h6>
        <Form.Group className="mb-3">
          <Form.Select
            aria-label="Select notification"
            name="ignoreReason"
            onChange={e => setIgnoreReason(e.target.value)}
          >
            <option value="policy_violation">Policy Violation</option>
            <option value="duplicate">Duplicate (Do Not Notify)</option>
            {answerOrReply && (
              <option value="not_relevant">Not Relevant (Do Not Notify)</option>
            )}
          </Form.Select>
        </Form.Group>
        <Button variant="danger" type="submit" className="w-100 mb-2">
          {buttonText}
        </Button>
        <div className="text-center">
          <Button onClick={handleClose} variant="link" className="btn-sm">
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default IgnoreModal
