import { useState, useRef } from "react"
import { getUserReferrals } from "api/users/referrals"
import { Link } from "react-router-dom"
import { AgGridDateTime } from "utils/cellRenderers"

import AgGrid from "components/ag_grid/AgGrid"

const ReferralsRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "referrals.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      hide: true,
      width: 120,
    },
    {
      field: "referrals.created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "users.first_name",
      headerName: "Referee",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <Link to={`/users/${data.referee.id}`}>{data.referee.name}</Link>
      ),
    },
    {
      field: "referrals.verified_at",
      headerName: "Verified",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.verified_at),
    },
    {
      field: "payment_amount_cents",
      headerName: "Amount",
      filter: "agNumberColumnFilter",
      cellClass: "ag-right-aligned-cell",
    },
    {
      field: "referrals.payment_requested_at",
      headerName: "Payment Requested",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.payment_requested_at),
    },
    {
      field: "referrals.paid_at",
      headerName: "Paid",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.paid_at),
    },
  ])

  return (
    <>
      <div className="tab-header">
        <h3>Referrals</h3>
      </div>
      <AgGrid
        onCellClicked={null}
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={params => getUserReferrals(id, params)}
        sizeToFit={true}
      />
    </>
  )
}

export default ReferralsRoute
