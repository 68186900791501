import { useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { createCompany } from "api/companies"
import { useNavigate } from "react-router-dom"

import Input from "components/forms/Input"

const NewCompany = ({ handleSuccess, handleClose }) => {
  const { handleSubmit, register, watch } = useForm()

  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const navigate = useNavigate()

  const onSubmit = async data => {
    setSubmitting(true)
    const payload = { company: { name: data?.name } }
    return createCompany(payload)
      .then(res => {
        toast.success("Company created successfully!")
        handleSuccess(res.data)
        handleClose()
        navigate(`/companies/${res.data.id}`)
      })
      .catch(response => {
        setErrors(response?.data)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="company" className="form-label">
            Company Name
          </label>
          <Input
            type="text"
            name="name"
            error={errors?.name?.[0]}
            register={register}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={watch("name")?.length === 0 || submitting}
        >
          Save
        </button>
      </form>
      <div className="text-center mt-2">
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default NewCompany
