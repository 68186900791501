import { useState, useRef } from "react"
import { getTags } from "api/tags"
import { deleteTag } from "api/tags"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"

import AgGrid from "components/ag_grid/AgGrid"
import Button from "react-bootstrap/Button"
import Modal from "components/Modal"
import Header from "components/Header"
import NewTag from "components/tags/New"
import EditTag from "components/tags/Edit"
import Icon from "components/Icon"

const Tags = () => {
  const gridRef = useRef()

  const [watchedValues, setWatchedValues] = useState([])
  const [showNew, setShowNew] = useState(false)
  const [showEdit, setShowEdit] = useState(null)
  const [updatedRecord, setUpdatedRecord] = useState(null)
  const [columnDefs] = useState([
    {
      field: "tags.id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => data.id,
      hide: true,
      width: 120,
    },
    {
      field: "tags.name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.name,
    },
    {
      field: "industries.name",
      headerName: "Industry",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) =>
        data?.industry?.name ? data.industry.name : String.fromCharCode(8212),
    },
    {
      field: "companies_count",
      headerName: "Assigned",
      filter: "agTextNumberFilter",
      headerClass: "ag-center-aligned",
      cellClass: "ag-right-aligned-cell",
      width: 80,
    },
    {
      field: "actions",
      headerName: "Edit",
      filter: false,
      suppressMenu: true,
      width: 120,
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned justify-content-center",
      cellRenderer: ({ data }) => (
        <>
          <button
            className="btn employer-util"
            onClick={() => handleEdit(data)}
          >
            <Icon name="edit" />
          </button>
          <button
            className="btn employer-util"
            onClick={() => handleDelete(data)}
          >
            <Icon name="delete" />
          </button>
        </>
      ),
    },
  ])

  const navigate = useNavigate()

  const closeModal = () => {
    setShowNew(false)
    setShowEdit(null)
  }

  const handleEdit = tag => {
    setShowEdit(tag)
  }

  const handleDelete = data => {
    if (window.confirm(`Are you sure you want to delete this tag?`)) {
      deleteTag(data.id)
        .then(res => {
          setWatchedValues(data.id)
          toast.success("Delete successful")
        })
        .catch(error => console.error(error))
    }
  }

  const onCellClicked = e => {
    navigate(`/tags/${e.data.id}`)
  }

  return (
    <>
      <Header title="Tags" icon="tags">
        <Button variant="primary" onClick={() => setShowNew(true)}>
          Add Tag
        </Button>
      </Header>
      <div className="grid-wrapper editable-table">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getTags}
          sizeToFit={true}
          watchedValues={watchedValues}
          updatedRecord={updatedRecord}
          onCellClicked={onCellClicked}
        />
      </div>
      <Modal
        title={showNew ? "New Tag" : "Edit Tag"}
        show={showNew || showEdit}
        handleClose={closeModal}
      >
        {showNew && (
          <NewTag handleSuccess={setWatchedValues} handleClose={closeModal} />
        )}
        {showEdit && (
          <EditTag
            tag={showEdit}
            handleClose={closeModal}
            setUpdatedRecord={setUpdatedRecord}
          />
        )}
      </Modal>
    </>
  )
}

export default Tags
