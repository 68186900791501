import { useEffect, useState } from "react"
import {
  getUserNotificationPreferences,
  updateUserNotificationPreference,
} from "api/users/notification_preferences"

import "./NotificationPreferences.scss"

const NotificationPreferencesRoute = ({ id }) => {
  const [notificationPreferences, setNotificationPreferences] = useState([])

  const handleToggle = (value, notificationId) => {
    const payload = {
      data: {
        notification_preferences: [
          {
            id: notificationId,
            active: value,
          },
        ],
      },
    }
    updateUserNotificationPreference(id, payload)
      .then(() => {})
      .catch(error => console.error(error))
  }

  useEffect(() => {
    getUserNotificationPreferences(id)
      .then(res => setNotificationPreferences(res.data))
      .catch(error => console.error(error))
  }, [id])

  return (
    <>
      <div className="tab-header">
        <h3>Notification Preferences</h3>
      </div>
      <div className="tab-container">
        <div className="notification-preferences">
          {notificationPreferences.map(preference => {
            const { id, name, description, active } = preference

            return (
              <div className="form-field w-50" key={id}>
                <div className="d-flex justify-content-between">
                  <h5 className="form-label">{name}</h5>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      defaultChecked={active}
                      onChange={e => handleToggle(e.target.checked, id)}
                    />
                  </div>
                </div>
                <small>{description}</small>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default NotificationPreferencesRoute
