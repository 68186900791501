import { useState } from "react"
import { mergeCompanies } from "api/companies"
import { useNavigate } from "react-router-dom"
import { toast } from "react-hot-toast"
import { useForm } from "react-hook-form"

import CompaniesSelect from "./Select"

import "./MergeModal.scss"

const MergeModal = ({ id, name }) => {
  const [companyMergingTo, setCompanyMergingTo] = useState(null)
  const [merging, setMerging] = useState(false)
  const [error, setError] = useState(null)

  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()

  const submitMerge = () => {
    setMerging(true)
    const mergeObject = {
      merge_to_company_id: companyMergingTo.company.id,
    }
    mergeCompanies(id, mergeObject)
      .then(res => {
        toast.success("Company merged!")
        navigate("/companies")
      })
      .catch(error => {
        setMerging(false)
        setError(error)
      })
  }

  const onSubmit = data => {
    const { company } = data
    if (company) {
      setError(null)
      setCompanyMergingTo(data)
    } else {
      setError({ noCompany: "Please select a company." })
    }
  }

  return (
    <div className="merge-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <div className="error-notification">
            {error.noCompany ? error.noCompany : "Something went wrong."}
          </div>
        )}
        <label htmlFor="company" className="form-label">
          Select a company to merge this company into.
        </label>
        <CompaniesSelect
          name="company"
          control={control}
          wholeCompany={true}
          merge
        />
        {!companyMergingTo && (
          <div className="select-wrapper">
            <button type="submit" className="btn btn-primary w-50">
              Select
            </button>
          </div>
        )}
      </form>
      {companyMergingTo && (
        <>
          <p className="merge-confirmation">{`Are you sure you want to merge ${name} into ${companyMergingTo.company.name}? This cannot be undone.`}</p>
          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary w-100"
              onClick={() => setCompanyMergingTo(null)}
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary w-100"
              onClick={submitMerge}
              disabled={merging}
            >
              Confirm Merge
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default MergeModal
