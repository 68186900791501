import apiCall from "utils/api"

export const getFinancialMetrics = companyId => {
  return apiCall("get", `companies/${companyId}/financial_metrics`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createFinancialMetric = (companyId, params) => {
  const options = { data: { financial_metric: params } }
  return apiCall("post", `companies/${companyId}/financial_metrics`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateFinancialMetric = (id, params) => {
  const options = { data: { financial_metric: params } }
  return apiCall("put", `financial_metrics/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteFinancialMetric = id => {
  return apiCall("delete", `financial_metrics/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
