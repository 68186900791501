import axios from "axios"
import { setAuth, getAuth, removeAuth } from "utils/auth"
import qs from "qs"
import toast from "react-hot-toast"
import store from "store"
import { signOut } from "api/auth"

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.patch["Content-Type"] = "application/json"
axios.defaults.headers.put["Content-Type"] = "application/json"

axios.interceptors.request.use(
  config => {
    const auth = getAuth()
    if (auth) config.headers.authorization = auth
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => {
    const authHeader = response.headers.authorization // Bearer token(jwt)

    if (authHeader) {
      setAuth(authHeader)
    }
    return response
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        if (store.getState().auth.authenticated) {
          store
            .dispatch(signOut())
            .then(() => {})
            .catch(() => {
              console.error("User not logged in")
            })
        } else {
          removeAuth()
        }
      }
      if (error.response.status === 403) {
        toast.error("Not authorized")
        console.error("403", error)
      }
      if (error.response.status === 500) {
        // something went wrong
        toast.error("Something went wrong")
        console.error("500", error)
      }
    } else if (error.message === "Network Error") {
      // something went wrong
      console.error("network", error)
    }
    return Promise.reject(error)
  }
)

const apiCall = (method, path, options = {}, responseType = "json") => {
  return axios
    .request({
      method: method,
      url: path,
      params: options.params,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: "brackets" }),
      data: JSON.stringify(options.data),
      responseType,
    })
    .then(response => {
      return response
    })
    .catch(error => {
      throw error.response
    })
}

export default apiCall
