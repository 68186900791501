import { useState, useRef } from "react"
import { useSelector } from "react-redux"
import * as dayjs from "dayjs"
import { getAdmins, deleteAdmin } from "api/admins"
import { setQueryParams, getInitialParams } from "utils/query"
import { AgGridDateTime } from "utils/cellRenderers"
import { startCase } from "lodash"
import toast from "react-hot-toast"

import { Badge, Button } from "react-bootstrap"
import AgGrid from "components/ag_grid/AgGrid"
import Modal from "components/Modal"
import Icon from "components/Icon"
import Header from "components/Header"
import InviteAdminForm from "components/admins/InviteForm"
import EditAdmin from "components/admins/EditForm"

const AdminsRoute = () => {
  const { currentAdmin } = useSelector(state => state.auth)

  const gridRef = useRef()
  const initialParams = getInitialParams()

  const [showInvite, setShowInvite] = useState(false)
  const [showEdit, setShowEdit] = useState(null)
  const [updatedRecord, setUpdatedRecord] = useState(null)

  const closeModal = () => {
    setShowInvite(false)
    setShowEdit(false)
  }

  const handleSuccess = data => {
    setUpdatedRecord(data)
    closeModal()
  }

  const handleEditSuccess = data => {
    setUpdatedRecord(data)
    closeModal()
  }

  const handleDelete = data => {
    if (window.confirm(`Are you sure you want to delete this admin?`)) {
      deleteAdmin(data.id)
        .then(res => {
          toast.success("Delete successful")
          setUpdatedRecord(res.data)
        })
        .catch(error => console.error(error))
    }
  }

  const filterParams = {
    filterOptions: [
      "empty",
      {
        displayKey: "dateTrue",
        displayName: "Yes",
        predicate: (_, cellValue) => cellValue === "true",
        numberOfInputs: 0,
      },
      {
        displayKey: "dateFalse",
        displayName: "No",
        predicate: (_, cellValue) => cellValue === "false",
        numberOfInputs: 0,
      },
    ],
    suppressAndOrCondition: true,
  }

  const [columnDefs] = useState([
    {
      field: "id",
      hide: true,
      headerName: "ID",
      filter: "agNumberColumnFilter",
      width: 100,
    },
    {
      field: "first_name",
      headerName: "First Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.first_name || String.fromCharCode(8212),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => data.last_name || String.fromCharCode(8212),
    },
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <span className="d-flex align-items-center justify-content-between gap-1">
          <span>{data.email}</span>
          {data.pending && (
            <Badge pill bg="info" text="dark">
              Invite Pending
            </Badge>
          )}
        </span>
      ),
    },
    {
      field: "current_sign_in_at",
      headerName: "Last Sign In",
      cellRenderer: ({ data }) =>
        data.current_sign_in_at
          ? dayjs(data.current_sign_in_at).format("MMM DD, YYYY")
          : String.fromCharCode(8212),
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      field: "created_at",
      headerName: "Created",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
    },
    {
      field: "access_level",
      headerName: "Access Level",
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      cellRenderer: ({ data }) => (
        <Badge pill bg={data.access_level === "full" ? "success" : "primary"}>
          {startCase(data.access_level)}
        </Badge>
      ),
      suppressMenu: true,
    },
    {
      field: "actions",
      headerName: "Edit",
      filter: false,
      sortable: false,
      suppressMenu: true,
      width: 120,
      cellClass: "ag-center-aligned-cell",
      headerClass: "ag-center-aligned",
      cellRenderer: ({ data }) => (
        <>
          <button
            className="btn employer-util"
            onClick={() => setShowEdit(data)}
          >
            <Icon name="edit" />
          </button>
          {currentAdmin.access_level === "full" && (
            <button
              className="btn employer-util"
              onClick={() => handleDelete(data)}
            >
              <Icon name="delete" />
            </button>
          )}
        </>
      ),
    },
  ])

  return (
    <>
      <div className="admins-header">
        <Header title="Admins" icon="admins">
          <Button variant="primary" onClick={() => setShowInvite(true)}>
            Invite Admin
          </Button>
        </Header>
      </div>

      <div className="grid-wrapper editable-table">
        <AgGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          getterFunction={getAdmins}
          initialParams={initialParams}
          setQueryParams={setQueryParams}
          sizeToFit={true}
          updatedRecord={updatedRecord}
        />
      </div>
      <Modal
        title={showInvite ? "Invite Admin" : "Edit Admin"}
        show={showInvite || showEdit}
        handleClose={closeModal}
      >
        {showInvite && <InviteAdminForm handleSuccess={handleSuccess} />}
        {showEdit && (
          <EditAdmin
            handleSuccess={handleEditSuccess}
            admin={showEdit}
            handleClose={() => setShowEdit(null)}
          />
        )}
      </Modal>
    </>
  )
}

export default AdminsRoute
