import apiCall from "utils/api"

export const getCompanyLeaders = companyId => {
  return apiCall("get", `companies/${companyId}/leaders`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createCompanyLeader = (companyId, params) => {
  const options = { data: { leader: params } }
  return apiCall("post", `companies/${companyId}/leaders`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateLeader = (id, params) => {
  const options = { data: { leader: params } }
  return apiCall("patch", `leaders/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const deleteLeader = id => {
  return apiCall("delete", `leaders/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
