import { useState, useRef, useCallback } from "react"
import { Link } from "react-router-dom"
import { getMetroLocationCompanies } from "api/metro_locations/companies"
import { AgGridDateTime } from "utils/cellRenderers"

import Badge from "react-bootstrap/Badge"
import AgGrid from "components/ag_grid/AgGrid"

const LocationCompaniesRoute = ({ id }) => {
  const gridRef = useRef()

  const [columnDefs] = useState([
    {
      field: "companies.name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => {
        return <Link to={`/companies/${data.id}`}>{data.name}</Link>
      },
    },
    {
      field: "industries.name",
      headerName: "Industry",
      filter: false,
      suppressMenu: true,
      sortable: false,
      cellRenderer: ({ data }) => data.industry || String.fromCharCode(8212),
      width: 120,
    },
    {
      field: "companies.ratings_count",
      headerName: "Ratings Count",
      filter: "agNumberColumnFilter",
      cellClass: "ag-right-aligned-cell",
      cellRenderer: ({ data }) => data.ratings_count,
      width: 100,
    },
    {
      field: "companies.published",
      headerName: "Published",
      filter: false,
      suppressMenu: true,
      sortable: false,
      cellRenderer: ({ data }) => {
        const variant = data.published ? "success" : "danger"
        const text = data.published ? "Yes" : "No"
        return <Badge bg={variant}>{text}</Badge>
      },
      width: 120,
    },
    {
      field: "companies.created_at",
      headerName: "Created At",
      filter: "agDateColumnFilter",
      cellRenderer: ({ data }) => AgGridDateTime(data.created_at),
      width: 150,
    },
  ])

  const getterFunction = useCallback(
    params => getMetroLocationCompanies(id, params),
    [id]
  )

  return (
    <>
      <div className="tab-header">
        <h3>Companies</h3>
      </div>
      <AgGrid
        gridRef={gridRef}
        columnDefs={columnDefs}
        getterFunction={getterFunction}
        sizeToFit={true}
      />
    </>
  )
}

export default LocationCompaniesRoute
