import Input from "components/forms/Input"
import Button from "react-bootstrap/Button"
import QuarterSelect from "./QuarterSelect"
import TypeSelect from "./TypeSelect"

import "./Form.scss"

const NewFinancialMetricForm = ({ register, errors, control }) => {
  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <tr className="headcount-row-form">
      <td>
        <Input
          type="number"
          name="year"
          label="Year"
          register={register}
          className="form-control-sm"
          error={getErrors("year")?.join(", ")}
        />
      </td>
      <td>
        <QuarterSelect
          name="fiscal_quarter"
          control={control}
          className="quarter-select"
          error={errors?.fiscal_quarter?.join(", ")}
        />
      </td>
      <td className="financial-metrics-type">
        <TypeSelect
          name="type"
          control={control}
          error={errors?.type?.join(", ")}
        />
      </td>
      <td>
        <div className="input-group input-group-sm">
          <span class="input-group-text">$</span>
          <Input
            type="number"
            name="revenue"
            label="Revenue"
            register={register}
            className="form-control-sm text-end"
            error={getErrors("revenue")?.join(", ")}
          />
        </div>
      </td>
      <td>
        <div className="input-group input-group-sm">
          <Input
            type="number"
            name="yearly_growth"
            label="Yearly Growth Percentage"
            register={register}
            className="form-control-sm text-end"
            error={getErrors("yearly_growth")?.join(", ")}
            step="0.01"
          />
          <span class="input-group-text">%</span>
        </div>
      </td>
      <td className="financial-metrics-actions">
        <Button type="submit" size="sm" className="add">
          Add
        </Button>
      </td>
    </tr>
  )
}

export default NewFinancialMetricForm
