import Badge from "react-bootstrap/Badge"

import "./StatusBadge.scss"

function TierBadge() {
  return (
    <Badge bg="dark" className="job-status-badge me-2">
      Free Tier
    </Badge>
  )
}

export default TierBadge
