import { createSlice } from "@reduxjs/toolkit"
import { removeAuth } from "utils/auth"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentAdmin: {},
    authenticated: false,
    fingerprint: {
      key: "",
      updated: true,
      keepCurrentVersion: false,
    },
  },
  reducers: {
    setCurrentAdmin: {
      reducer(state, action) {
        state.currentAdmin = action.payload
        state.authenticated = true
      },
    },
    signOutAdmin: {
      reducer(state, _) {
        removeAuth()
        state.currentAdmin = {}
        state.authenticated = false
      },
    },
    setAppVersion: {
      reducer(state, action) {
        state.fingerprint.updated =
          state.fingerprint.key === "" ||
          action.payload === state.fingerprint.key
        state.fingerprint.key = action.payload
      },
    },
    doNotUpdate: {
      reducer(state, _) {
        state.fingerprint.keepCurrentVersion = true
      },
    },
  },
})

export const { setCurrentAdmin, signOutAdmin, setAppVersion, doNotUpdate } =
  authSlice.actions

export default authSlice.reducer
