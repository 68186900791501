import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { toastOptions } from "utils/toastOptions"
import { getCurrentAdmin } from "api/auth"
import { authTokenPresent } from "utils/auth"
import apiCall from "utils/api"
import { setAppVersion, doNotUpdate } from "reducers/auth"

import AuthRoute from "routes/Auth"
import ProtectedRoute from "routes/Protected"

import AcceptInviteRoute from "routes/AcceptInvite"
import AdminsRoute from "routes/Admins"
import CompaniesRoute from "routes/Companies"
import CompanyRoute from "routes/Company"
import EmployerCustomersRoute from "routes/EmployerCustomers"
import DashboardRoute from "routes/Dashboard"
import EmployerRoute from "routes/Employer"
import EmployersRoute from "routes/Employers"
import JobApplicationsRoute from "routes/JobApplications"
import JobsRoute from "routes/Jobs"
import JobRoute from "routes/Job"
import NewJobRoute from "routes/companies/jobs/New"
import NewToSalesUsersRoute from "routes/NewToSalesUsers"
import OperationsRoute from "routes/Operations"
import QuestionsRoute from "routes/Questions"
import QuestionApprovalsRoute from "routes/approvals/Questions"
import AnswerApprovalsRoute from "routes/approvals/Answers"
import RatingsRoute from "routes/Ratings"
import RatingValuesRoute from "routes/RatingValues"
import DeletedRatingsRoute from "routes/DeletedRatings"
import RewardsRoute from "routes/Rewards"
import SigmaRoute from "routes/Sigma"
import SignInRoute from "routes/SignIn"
import TechStacksRoute from "routes/TechStacks"
import UsersRoute from "routes/Users"
import UserRoute from "routes/User"
import MetroLocationsRoute from "routes/MetroLocations"
import TagsRoute from "routes/Tags"
import DataCustomersRoute from "routes/DataCustomers"
import DataCustomerRoute from "routes/DataCustomer"
import TagRoute from "routes/Tag"
import ReviewsRoute from "routes/Reviews"
import ReviewApprovalsRoute from "routes/approvals/Reviews"
import DataUsersRoute from "routes/DataUsers"
import TechStackRoute from "routes/TechStack"
import MetroLocationRoute from "routes/MetroLocation"

import DeletedUsersRoute from "routes/DeletedUsers"
import ForgotPasswordRoute from "routes/ForgotPassword"
import IgnoredJobsRoute from "routes/IgnoredJobs"
import ReferencesRoute from "routes/References"
import ReferralsRoute from "routes/Referrals"
import ResetPasswordRoute from "routes/ResetPassword"
import CandidateProfilesRoute from "routes/CandidateProfiles"

import NewVersionNotification from "components/NewVersionNotification"

import "./App.scss"

// lazy loaded
// const DeletedUsersRoute = lazy(() => import("routes/DeletedUsers"))
// const ForgotPasswordRoute = lazy(() => import("routes/ForgotPassword"))
// const IgnoredJobsRoute = lazy(() => import("routes/IgnoredJobs"))
// const ReferencesRoute = lazy(() => import("routes/References"))
// const ReferralsRoute = lazy(() => import("routes/Referrals"))
// const ResetPasswordRoute = lazy(() => import("routes/ResetPassword"))

const App = () => {
  const [loading, setLoading] = useState(true)

  const { fingerprint } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const handleUpdateModalClose = (update = true) => {
    if (update) {
      window.location.reload()
    } else {
      dispatch(doNotUpdate())
    }
  }

  useEffect(() => {
    if (authTokenPresent()) {
      dispatch(getCurrentAdmin())
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    const updateVersionCheck = setInterval(() => {
      if (fingerprint.updated) {
        const url = `https://${window.location.host}`
        apiCall("get", url).then(response => {
          const regex = /js\/main\.(.*?)\.js/g
          const fingerprint = regex.exec(response.data)[1]
          if (fingerprint) {
            dispatch(setAppVersion(fingerprint))
          }
        })
      }
    }, 60000)
    return () => clearInterval(updateVersionCheck)
  }, [dispatch, fingerprint])

  return (
    <>
      {!loading && (
        <Router>
          <Routes>
            <Route element={<AuthRoute />}>
              <Route path="/sign_in" element={<SignInRoute />} />
              <Route
                path="/forgot_password"
                element={<ForgotPasswordRoute />}
              />
              <Route path="/reset_password" element={<ResetPasswordRoute />} />
              <Route path="/accept_invite" element={<AcceptInviteRoute />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<DashboardRoute />} />
              <Route path="/operations" element={<OperationsRoute />} />
              <Route path="/sigma" element={<SigmaRoute />} />
              <Route path="/users" element={<UsersRoute />} />
              <Route path="/users/:id" element={<UserRoute />} />
              <Route
                path="/users/new_to_sales"
                element={<NewToSalesUsersRoute />}
              />
              <Route path="/users/deleted" element={<DeletedUsersRoute />} />
              <Route path="/admins" element={<AdminsRoute />} />
              <Route path="/companies" element={<CompaniesRoute />} />
              <Route path="/companies/:id" element={<CompanyRoute />} />
              <Route path="/companies/:id/jobs/new" element={<NewJobRoute />} />
              <Route
                path="/candidate_profiles"
                element={<CandidateProfilesRoute />}
              />
              <Route
                path="/candidate_profiles/:id"
                element={<CandidateProfilesRoute />}
              />
              <Route path="/ratings" element={<RatingsRoute />} />
              <Route path="/ratings/:id" element={<RatingsRoute />} />
              <Route path="/ratings/values" element={<RatingValuesRoute />} />
              <Route
                path="/ratings/values/:id"
                element={<RatingValuesRoute />}
              />
              <Route
                path="/ratings/deleted"
                element={<DeletedRatingsRoute />}
              />
              <Route
                path="/ratings/deleted/:id"
                element={<DeletedRatingsRoute />}
              />
              <Route path="/reviews" element={<ReviewsRoute />} />
              <Route path="/reviews/:id" element={<ReviewsRoute />} />
              <Route path="/references" element={<ReferencesRoute />} />
              <Route path="/referrals" element={<ReferralsRoute />} />
              <Route path="/rewards" element={<RewardsRoute />} />
              <Route path="/questions" element={<QuestionsRoute />} />
              <Route path="/questions/:id" element={<QuestionsRoute />} />
              <Route path="/tech_stacks" element={<TechStacksRoute />} />
              <Route path="/tags" element={<TagsRoute />} />
              <Route path="/tags/:id" element={<TagRoute />} />
              <Route path="/tech_stacks/:id" element={<TechStackRoute />} />
              <Route path="/jobs" element={<JobsRoute />} />
              <Route path="/jobs/ignored" element={<IgnoredJobsRoute />} />
              <Route path="/jobs/:id" element={<JobRoute />} />
              <Route
                path="/job_applications"
                element={<JobApplicationsRoute />}
              />
              <Route
                path="/job_applications/:id"
                element={<JobApplicationsRoute />}
              />
              <Route
                path="/customers/employers"
                element={<EmployerCustomersRoute />}
              />
              <Route
                path="/customers/employers/:id"
                element={<EmployerCustomersRoute />}
              />
              <Route path="/customers/data" element={<DataCustomersRoute />} />
              <Route
                path="/customers/data/:id"
                element={<DataCustomerRoute />}
              />
              <Route path="/employers" element={<EmployersRoute />} />
              <Route path="/data_users" element={<DataUsersRoute />} />
              <Route path="/employers/:id" element={<EmployerRoute />} />
              <Route
                path="/metro_locations"
                element={<MetroLocationsRoute />}
              />
              <Route
                path="/metro_locations/:id"
                element={<MetroLocationRoute />}
              />
              <Route
                path="/approvals/reviews"
                element={<ReviewApprovalsRoute />}
              />
              <Route
                path="/approvals/reviews/:id"
                element={<ReviewApprovalsRoute />}
              />
              <Route
                path="/approvals/questions"
                element={<QuestionApprovalsRoute />}
              />
              <Route
                path="/approvals/questions/:id"
                element={<QuestionApprovalsRoute />}
              />
              <Route
                path="/approvals/answers"
                element={<AnswerApprovalsRoute />}
              />
              <Route
                path="/approvals/answers/:id"
                element={<AnswerApprovalsRoute />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/sign_in" />} />
          </Routes>
        </Router>
      )}
      <Toaster position="bottom-right" toastOptions={toastOptions} />
      <NewVersionNotification
        show={!fingerprint?.updated && !fingerprint.keepCurrentVersion}
        closeModal={handleUpdateModalClose}
      />
    </>
  )
}

export default App
