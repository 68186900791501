import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"

import MetroLocationSelect from "components/metro_locations/Select"

const LocationForm = ({ onSubmit, location, errors }) => {
  const { handleSubmit, register, control, reset } = useForm({ location })

  const defaultValues = useMemo(
    () => ({
      metro_location: location?.metro_location,
      office: location?.office,
      hq: location?.hq,
    }),
    [location]
  )

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const metro_location = location ? location.metro_location : null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Metro Location
        </label>
        <MetroLocationSelect
          name="metro_location"
          control={control}
          value={metro_location}
          type="city"
          error={getErrors("metro_location")[0]}
        />
      </div>
      <div className="mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="office"
            id="office"
            {...register("office")}
          />
          <label htmlFor="office" className="form-check-label">
            This location is an office
          </label>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="hq"
            id="hq"
            {...register("hq")}
          />
          <label htmlFor="hq" className="form-check-label">
            This location is a headquarters
          </label>
        </div>
      </div>

      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
    </form>
  )
}

export default LocationForm
