import { Link } from "react-router-dom"
import Badge from "react-bootstrap/Badge"

import "./GridRating.scss"

function GridRating({ rating }) {
  if (!rating) return String.fromCharCode(8212)

  const { id, discarded_at, status } = rating

  return (
    <div className="grid-rating">
      <Link to={`/ratings/${id}`}>{id}</Link>
      {discarded_at ? (
        <Badge bg="danger">Deleted</Badge>
      ) : status === "Unverified" ? (
        <Badge bg="warning">Unverified</Badge>
      ) : null}
    </div>
  )
}

export default GridRating
