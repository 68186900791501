import { useEffect, useState, useMemo } from "react"
import { getHeadcounts, deleteHeadcount } from "api/companies/headcounts"
import toast from "react-hot-toast"
import dayjs from "dayjs"
import { formatDateTime, formatDateMonth } from "utils/format"
import { useForm } from "react-hook-form"
import { createHeadcount } from "api/companies/headcounts"

import Modal from "components/Modal"
import Icon from "components/Icon"
import NewHeadcount from "components/companies/headcounts/New"
import EditHeadcount from "components/companies/headcounts/Edit"

import "./Headcounts.scss"

const CompanyHeadcountsRoute = ({ id }) => {
  const [headcounts, setHeadcounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [showEdit, setShowEdit] = useState(false)
  const [editing, setEditing] = useState({})
  const [errors, setErrors] = useState([])

  const loadHeadcounts = id => {
    getHeadcounts(id)
      .then(response => {
        setHeadcounts(response.data.headcounts)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    loadHeadcounts(id)
  }, [id])

  const handleEdit = (e, id) => {
    e.preventDefault()
    const headcount = headcounts.find(headcount => headcount.id === id)
    setErrors([])
    setEditing(headcount)
    setShowEdit(true)
  }

  const handleDelete = (e, id) => {
    e.preventDefault()
    setErrors([])
    const headcount = headcounts.find(headcount => headcount.id === id)
    if (
      window.confirm(
        `Are you sure you want to delete the ${formatDateMonth(
          headcount.date
        )} headcount?`
      )
    ) {
      deleteHeadcount(id).then(() => {
        setHeadcounts(headcounts.filter(headcount => headcount.id !== id))
        toast.success("Delete successful")
      })
    }
  }

  const closeModal = () => {
    setShowEdit(false)
  }

  const replaceHeadcount = headcount => {
    const newHeadcounts = [...headcounts]
    const index = newHeadcounts.findIndex(h => h.id === headcount.id)
    newHeadcounts[index] = headcount
    setHeadcounts(newHeadcounts)
  }

  const { handleSubmit, register, reset, control } = useForm()

  const DATE_FORMAT = "YYYY-MM-DD"

  const defaultValues = useMemo(
    () => ({
      date:
        headcounts.length > 0
          ? dayjs(headcounts[0].date).add(1, "month").format(DATE_FORMAT)
          : dayjs().format(DATE_FORMAT),
      total: null,
      sales: null,
      open_sales_roles: null,
      new_hires: null,
      us_remote_roles: null,
    }),
    [headcounts]
  )

  const onSubmit = headcount => {
    if (headcount.sales === 0) {
      headcount.sales = undefined
    }
    return createHeadcount(id, headcount)
      .then(() => {
        toast.success("Create successful")
        setErrors([])
        loadHeadcounts(id)
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  if (loading) return <div />

  return (
    <div className="company-headcounts">
      <div className="tab-header">
        <h3>Headcounts</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="table table-hover editable-table">
          <thead>
            <tr>
              <th>Date</th>
              <th className="text-end">Total</th>
              <th className="text-end">Sales Org</th>
              <th className="text-end">Open Sales Roles</th>
              <th className="text-end">Remote US Roles</th>
              <th className="text-end">New Hires</th>
              <th className="w-15">Last Update</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <NewHeadcount
              register={register}
              errors={errors}
              control={control}
            />
            {headcounts &&
              headcounts.map(headcount => {
                const {
                  id,
                  total,
                  sales,
                  open_sales_roles,
                  new_hires,
                  date,
                  updated_at,
                  us_remote_roles,
                } = headcount
                return (
                  <tr key={id}>
                    <td className="field-value">{formatDateMonth(date)}</td>
                    <td className="field-value text-end">
                      {total?.toLocaleString()}
                    </td>
                    <td className="field-value text-end">
                      {sales?.toLocaleString() || String.fromCharCode(8212)}
                    </td>
                    <td className="field-value text-end">
                      {open_sales_roles === null
                        ? String.fromCharCode(8212)
                        : open_sales_roles?.toLocaleString()}
                    </td>
                    <td className="field-value text-end remote-roles">
                      {us_remote_roles === null
                        ? String.fromCharCode(8212)
                        : us_remote_roles === true
                        ? "Yes"
                        : "No"}
                    </td>
                    <td className="field-value text-end">
                      {new_hires === null
                        ? String.fromCharCode(8212)
                        : new_hires?.toLocaleString()}
                    </td>
                    <td className="field-value">
                      {formatDateTime(updated_at)}
                    </td>
                    <td className="actions">
                      <button
                        className="btn me-1"
                        onClick={e => handleEdit(e, id)}
                      >
                        <Icon name="edit" />
                      </button>
                      <button
                        className="btn"
                        onClick={e => handleDelete(e, id)}
                      >
                        <Icon name="delete" />
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </form>
      <Modal title={"Edit Headcount"} show={showEdit} handleClose={closeModal}>
        {showEdit && (
          <EditHeadcount
            headcount={editing}
            handleSuccess={replaceHeadcount}
            handleClose={() => setShowEdit(false)}
          />
        )}
      </Modal>
    </div>
  )
}

export default CompanyHeadcountsRoute
