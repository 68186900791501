import { useEffect, useState } from "react"
import { getTags } from "api/tags"

import Select from "components/forms/Select"

const TagsSelect = ({ ...props }) => {
  const [tags, setTags] = useState([])

  useEffect(() => {
    // hack to get all of the tags back instead of first 50
    const params = { startRow: 0, endRow: 100 }

    getTags(params).then(response => {
      setTags(response.data.rows)
    })
  }, [])

  return <Select options={tags} isMulti={true} {...props} />
}

export default TagsSelect
